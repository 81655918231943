import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const VerifyCredentials = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
`;

export const Loading = styled.div`
  font-size: 2em;
  font-weight: bold;
  color: var(--colorFontPrimary);

  @media (max-width: 768px) {
    font-size: 1.2em;
  }

`;

export const SVG = styled.div`
  svg {
    font-size: 5rem;
    color: var(--colorFontSecondary);
    animation: spin 1s linear infinite alternate;

    @keyframes spin {
      from {
        transform: scale(1);
      }

      to {
        transform: scale(1.4);
      }
    }
  }
`;

export const ContainerSidebar = styled.div`
  width: 250px;
  height: 100vh;
  background-color: #ebebeb;
  position: fixed;
  top: 0;
  left: 0;
  font-size: 1.3em;

  @media (max-width: 768px) {
    position: static;
    width: 100%;
    height: fit-content;
    border-bottom: 3px solid var(--colorGrey);
    position: relative;
  }
`;

export const ContainerEmpty = styled.div`
  width: 250px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 10px 0;

  @media (max-width: 768px) {
    margin: 0;
    justify-content: left;
    padding: 10px;
    border-bottom: 1px solid var(--colorGrey);
  }
`;

export const Logo = styled.img`
  width: 150px;

  @media (max-width: 768px) {
    width: 110px;
  }
`;

export const Menu = styled.div`
  width: 90%;
  margin-left: 15px;
  font-weight: bold;
  margin-top: 20px;

  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 0;
  }
`;

export const MenuItem = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  margin: 10px 0;
  padding: 5px;
  color: ${(props) =>
    props.selected ? 'var(--colorFontSecondary)' : 'var(--colorGrey)'};
  cursor: pointer;
  border-radius: 10px;

  svg {
    margin-right: 10px;

    @media (max-width: 768px) {
      margin-right: 3px;
    }
  }

  @media (min-width: 768px) {
    &:hover {
      background-color: var(--colorFontPrimary);
    }
  }

  @media (max-width: 768px) {
    width: 45%;
    font-size: 0.9em;
    margin: 5px 0;
    border: 1px solid var(--colorGrey);
    justify-content: center;
  }
`;
export const MenuItemLink = styled.a`
  display: flex;
  align-items: center;
  margin: 10px 0;
  padding: 5px;
  color: var(--colorGrey);
  cursor: pointer;
  border-radius: 10px;
  text-decoration: none;

  svg {
    margin-right: 10px;

    @media (max-width: 768px) {
      margin-right: 3px;
    }
  }

  @media (min-width: 768px) {
    &:hover {
      background-color: var(--colorFontPrimary);
      color: var(--colorGrey);
    }
  }

  @media (max-width: 768px) {
    width: 45%;
    font-size: 0.9em;
    margin: 5px 0;
    border: 1px solid var(--colorGrey);
    justify-content: center;
  }
`;

export const MenuItemExit = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  margin: 10px 0;
  padding: 5px;
  color: ${(props) =>
    props.selected ? 'var(--colorFontSecondary)' : 'var(--colorGrey)'};
  cursor: pointer;
  border-radius: 10px;

  svg {
    margin-right: 10px;
  }

  @media (min-width: 768px) {
    &:hover {
      background-color: var(--colorFontPrimary);
    }
  }

  @media (max-width: 768px) {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0.8em;
  }
`;

export const Text = styled.p`
  margin: 0;
`;
