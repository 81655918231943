import React, { useEffect, useState } from 'react';

import { formatPrice } from '../../../helpers/FunctionsCalc';
import { Raffle, User } from '../../../types/Raffle';
import FormReservation from '../FormReservation';
import * as C from './styles';

type Props = {
  selectedTickets: number[];
  raffle: Raffle;
  setSelectedTickets: React.Dispatch<React.SetStateAction<number[]>>;
  user: User;
  docRaffle: string | undefined;
  updateTickets: () => void;
};

function Selectedticket (props: Props) {
  const { selectedTickets, raffle, setSelectedTickets, user, docRaffle, updateTickets } = props;

  const selectedRaffleQuantities = selectedTickets.length;
  const [showFormReservation, setShowFormReservation] = useState<boolean>(false);
  const [paymentValue, setPaymentValue] = useState<number>(0);

  useEffect(() => {
    setPaymentValue(selectedRaffleQuantities * raffle.price);
  }, [selectedRaffleQuantities, raffle.price]);
  const handleCloseFormReservation = () => setShowFormReservation(false);
  const handleShowFormReservation = () => setShowFormReservation(true);

  return (
    <C.Container visible={selectedRaffleQuantities > 0}>
      <C.ContainerValue>
        <C.TicketDetails>
          <C.TitleTicket>
            {selectedRaffleQuantities} Bilhete
            {selectedRaffleQuantities > 1 ? 's' : ''}
          </C.TitleTicket>
          <C.Value>
            {formatPrice(paymentValue)}
          </C.Value>
        </C.TicketDetails>
        <C.ContainerReserve onClick={handleShowFormReservation}>
          <C.TitleReserve>Reservar</C.TitleReserve>
        </C.ContainerReserve>
      </C.ContainerValue>
      <FormReservation
        handleClosePayment={handleCloseFormReservation}
        showFormReservation={showFormReservation}
        paymentValue={paymentValue}
        raffle={raffle}
        selectedTickets={selectedTickets}
        setSelectedTickets={setSelectedTickets}
        user={user}
        docRaffle={docRaffle}
        updateTickets={updateTickets}
      />
    </C.Container>
  );
}

export default Selectedticket;
