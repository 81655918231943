import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Button from "react-bootstrap/Button";
import Carousel from "react-bootstrap/Carousel";
import Modal from "react-bootstrap/Modal";
import { BsWhatsapp } from "react-icons/bs";
import { ImBook } from "react-icons/im";
import { IoTicketOutline } from "react-icons/io5";

import Footer from "../../components/Main/Footer";
import RaffleFinished from "../../components/RafflePage/RaffleFinished";
import Tickets from "../../components/RafflePage/Tickets";
import { getRafflesDatabase } from "../../db/api/apiServer";
import { formatPrice } from "../../helpers/FunctionsCalc";
import * as C from "./styles";
import { Raffle, User } from "../../types/Raffle";
import { useNavigate, useParams } from "react-router-dom";

const initialRaffles: Raffle = {
  id: "",
  userId: "",
  userName: "",
  userEmail: "",
  userPhone: "",
  creationDate: new Date(),
  name: "",
  description: "",
  active: true,
  paid: true,
  price: 7.5,
  images: [],
  pixMethod: "",
  pix: "",
  pixName: "",
  method: "",
  date: "",
  hour: "",
  winner: "",
  url: "",
  quantityTickets: 0,
  whatsGroup: false,
  publicationFee: 0,
  urlWhatsappGroup: "",
  dateFinish: "",
  tickets: [],
};

function Rifa() {
  const [raffle, setRaffe] = useState<Raffle>(initialRaffles);

  const navigate = useNavigate();
  const {userRaffle, docRaffle} = useParams();

  // MODAL
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const getData = async () => {
      if (userRaffle && docRaffle) {
        try {
          const data: Raffle = await getRafflesDatabase(
            userRaffle as string,
            docRaffle as string
          );
          setRaffe(data);
        } catch (error) {
          alert("Erro ao carregar os dados da rifa");
          navigate("/");
        }
      }
    };
    getData();
  }, [userRaffle, docRaffle, navigate]);

  const updateTickets = async () => {
    try {
      const data: Raffle = await getRafflesDatabase(
        userRaffle as string,
        docRaffle as string
      );
      setRaffe(data);
    } catch (error) {
      alert("Erro ao carregar os dados da rifa");
      navigate("/");
    }
  };

  const user: User = {
    userId: raffle.userId,
    email: raffle.userEmail,
    name: raffle.userName,
    phone: raffle.userPhone,
  };

  const raffleName = raffle.name;

  const raffleDescription = raffle.description
    .split("\n")
    .map((paragraph, key) => {
      if (paragraph === "") return <br key={key} />;
      return (
        <span key={key}>
          {paragraph}
          <br />
        </span>
      );
    });
  const rafflePhone = user.phone;
  const apiWhatsappSupport = `https://api.whatsapp.com/send?phone=55${rafflePhone}&text=Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20sobre%20a%20rifa%20${raffle.name}.`;
  const apiWhatsappSendProof = `https://api.whatsapp.com/send?phone=55${rafflePhone}&text=Este%20é%20o%20meu%20comprovante%20da%20rifa%20${raffle.name}.`;

  const phoneAdmin = "67999519176";

  const apiWhatsappConfirmPaid = `https://api.whatsapp.com/send?phone=55${phoneAdmin}&text=Este%20é%20o%20meu%20comprovante%20da%20taxa%20referente%20a%20rifa%20${raffle.name},%20sob%20responsabilidade%20de%20${raffle.userName}.`;

  const whatsappGroup = raffle.urlWhatsappGroup;

  return (
    <C.Container>
      <C.Header>
        <C.ContainerImage href="/" target="_blank">
          <C.Image src="/images/logo.png" alt="Logo Loja da Rifa" />
          <C.TextHeader>
            Rifas <span>online</span>
          </C.TextHeader>
        </C.ContainerImage>
      </C.Header>
      {!raffle.paid && (
        <C.ContainerRate>
          <C.Rate>RIFA BLOQUEADA</C.Rate>
          <C.Rate>Taxa {formatPrice(raffle.publicationFee)} pendente</C.Rate>
          <C.TextInfo>
            Envie um <span>pix celular</span> para <span>67999519176</span>{" "}
            <br />
            Nome da conta <span>Darlan Gomes Aguiar</span> para liberar a rifa
          </C.TextInfo>
          <C.WhatsApp href={apiWhatsappConfirmPaid} target={"_blank"}>
            <BsWhatsapp /> Enviar comprovante
          </C.WhatsApp>
        </C.ContainerRate>
      )}
      <C.RaffleName>
        {raffleName.toLocaleUpperCase()}
        <IoTicketOutline />
      </C.RaffleName>
      <C.ContainerCarrousel>
        {raffle.images && (
          <Carousel>
            {raffle.images.map((image, index) => (
              <Carousel.Item key={index}>
                <C.ImageCarousel src={image.url} alt="Foto do item" />
              </Carousel.Item>
            ))}
          </Carousel>
        )}
      </C.ContainerCarrousel>
      {raffle.active && (
        <>
          <C.ContainerDescription>
            <C.TitleDescription>
              <ImBook /> Regulamento / Descrição
            </C.TitleDescription>
            <C.RaffleDescription>{raffleDescription}</C.RaffleDescription>
            <C.RaffleDetails>
              <C.ContainerOrganizer>
                <C.RaffleDetailsTitle>Organizador:</C.RaffleDetailsTitle>
                <C.RaffleOrganizer>
                  <strong>{user.name.toLocaleUpperCase()}</strong>
                </C.RaffleOrganizer>
                <C.WhatsappSupport href={apiWhatsappSupport} target="_blank">
                  <BsWhatsapp />
                  Fale com o organizador
                </C.WhatsappSupport>
                <C.WhatsappSupport href={apiWhatsappSendProof} target="_blank">
                  <BsWhatsapp />
                  Comprou? envie o comprovante aqui
                </C.WhatsappSupport>
              </C.ContainerOrganizer>
              <C.ContainetRaffleMethod>
                <C.RaffleMethod>
                  Sorteio: <strong>{raffle.method}</strong>
                </C.RaffleMethod>
                <C.RaffleDate>
                  Data: <strong>{raffle.date}</strong>
                  {raffle.hour && (
                    <C.Text>
                      Hora: <strong>{raffle.hour}</strong>
                    </C.Text>
                  )}
                </C.RaffleDate>
                {raffle.whatsGroup && (
                  <C.WhatsappSupport href={whatsappGroup} target="_blank">
                    <BsWhatsapp />
                    Entrar no Grupo da rifa
                  </C.WhatsappSupport>
                )}
                <C.PixDetails onClick={handleShow}>
                  Ver método de pagamento
                </C.PixDetails>
                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Dados do PIX</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <C.Text>
                      <span>Tipo do PIX:</span> {raffle.pixMethod}
                    </C.Text>
                    <C.Text>
                      <span>PIX:</span> {raffle.pix}
                    </C.Text>
                    <C.Text>
                      <span>Nome:</span> {raffle.pixName}
                    </C.Text>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Sair
                    </Button>
                  </Modal.Footer>
                </Modal>
              </C.ContainetRaffleMethod>
              <C.ContainerPrice>
                <C.TextPrice>Apenas</C.TextPrice>
                <C.RafflePrice>{formatPrice(raffle.price)}</C.RafflePrice>
              </C.ContainerPrice>
            </C.RaffleDetails>
          </C.ContainerDescription>
          <Tickets
            raffle={raffle}
            user={user}
            docRaffle={docRaffle}
            updateTickets={updateTickets}
          />
        </>
      )}
      {!raffle.active && <RaffleFinished raffle={raffle} />}
      <Footer />
    </C.Container>
  );
}

export default Rifa;
