import styled from 'styled-components';

export const Container = styled.div<{ visible: boolean }>`
  position: fixed;
  left: 0;
  bottom: ${(props) => (props.visible ? '0' : '-110px')};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  display: flex;
  font-weight: bold;
  font-size: 1.3em;
  color: #fff;
  transition: all 0.3s ease-in-out;
`;

export const ContainerValue = styled.div`
  width: 500px;
  display: flex;
  justify-content: space-around;
  background-color: var(--colorFontPrimary);
  padding: 10px;
  border-radius: 20px;

  @media (max-width: 768px) {
    width: 95%;
  }
`;

export const TicketDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
`;

export const TitleTicket = styled.div``;

export const Value = styled.div`
  color: var(--colorGrey);
`;

export const ContainerReserve = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
`;

export const TitleReserve = styled.button`
  border: 0;
  border-bottom: 3px solid var(--colorGrey);
  background-color: var(--colorFontSecondary);
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  font-weight: bold;
  color: #fff;
`;
