import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { AiOutlineUserAdd } from "react-icons/ai";
import { BsGear, BsWhatsapp } from "react-icons/bs";
import InputMask from "react-input-mask";

import { saveReserveTicket } from "../../../db/api/editDatabase";
import { formatPrice } from "../../../helpers/FunctionsCalc";
import { buyerCustomer } from "../../../types/Raffle";
import { Raffle, User } from "../../../types/Raffle";
import PaymentDetails from "../PaymentDetails";
import * as C from "./styles";
import { useNavigate } from "react-router-dom";

type Props = {
  handleClosePayment: () => void;
  showFormReservation: boolean;
  paymentValue: number;
  raffle: Raffle;
  selectedTickets: number[];
  setSelectedTickets: React.Dispatch<React.SetStateAction<number[]>>;
  user: User;
  docRaffle: string | undefined;
  updateTickets: () => void;
};

function FormReservation(props: Props) {
  const {
    showFormReservation,
    handleClosePayment,
    paymentValue,
    raffle,
    selectedTickets,
    setSelectedTickets,
    user,
    docRaffle,
    updateTickets,
  } = props;

  const navigate = useNavigate();

  const clearFields = () => {
    setName("");
    setPhone("");
    setConfirmPhone("");
    setErrorText("");
    setAceptTerms(false);
    setVisibleMessageError(false);
  };

  const handleCancelReservation = () => {
    clearFields();
    handleClosePayment();
    setSuccessRecord(false);
    setSelectedTickets([]);
    setVisibleMessageError(false);
  };

  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [confirmPhone, setConfirmPhone] = useState<string>("");
  const [successRecord, setSuccessRecord] = useState<boolean>(false);
  const [visibleMessageError, setVisibleMessageError] =
    useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>("");
  const [aceptTerms, setAceptTerms] = useState<boolean>(false);
  const [loadingPayments, setLoadingPayments] = useState(false);

  const handleSaveReservation = async () => {
    const formattedPhone = phone.replace(/\D/g, "");

    if (name.trim().indexOf(" ") === -1) {
      setVisibleMessageError(true);
      setErrorText("Digite seu nome completo");
      return;
    } else if (formattedPhone.length < 11) {
      setVisibleMessageError(true);
      setErrorText("Telefone inválido");
      return;
    } else if (phone !== confirmPhone) {
      setVisibleMessageError(true);
      setErrorText("Telefone não confere");
      return;
    } else if (!aceptTerms) {
      setVisibleMessageError(true);
      setErrorText("É necessário aceitar os termos abaixo");
      return;
    }

    const client: buyerCustomer = {
      name,
      phone: formattedPhone,
      email: "",
    };
    try {
      const refDB = user.email;
      setLoadingPayments(true);
      await saveReserveTicket(selectedTickets, refDB, docRaffle, client);
      updateTickets();
      setLoadingPayments(false);
      setSuccessRecord(true);
      clearFields();
    } catch (error: any) {
      setLoadingPayments(false);
      handleCancelReservation();
      updateTickets();
      alert(error.message);
    }
  };

  return (
    <C.Container>
      <Modal show={showFormReservation} onHide={handleCancelReservation}>
        <Modal.Header closeButton></Modal.Header>
        <C.ContainerForm>
          <C.HeaderText>
            {!successRecord && (
              <C.Link>
                <C.Image src="/images/logo.png" />
              </C.Link>
            )}
            {successRecord && (
              <C.ReservCompleted>RESERVA CONCLUÍDA</C.ReservCompleted>
            )}
            {!successRecord && <C.Text>Registrar bilhetes</C.Text>}
          </C.HeaderText>
          {!successRecord && (
            <C.Form>
              <C.FormInput>
                <C.ContainerInput>
                  <C.SVGInput>
                    <AiOutlineUserAdd />
                  </C.SVGInput>
                  <C.Input
                    type="text"
                    id="name"
                    placeholder="Nome completo"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setName(e.target.value);
                    }}
                    value={name}
                  />
                </C.ContainerInput>
                <C.ContainerInput>
                  <C.SVGInput>
                    <BsWhatsapp />
                  </C.SVGInput>
                  <InputMask
                    mask="(99) 99999-9999"
                    placeholder="Telefone com DDD"
                    value={phone}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setPhone(e.target.value);
                    }}
                  />
                </C.ContainerInput>
                <C.ContainerInput>
                  <C.SVGInput>
                    <BsWhatsapp />
                  </C.SVGInput>
                  <InputMask
                    mask="(99) 99999-9999"
                    placeholder="Confirme Telefone"
                    value={confirmPhone}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setConfirmPhone(e.target.value);
                    }}
                  />
                </C.ContainerInput>
                {visibleMessageError && (
                  <C.MessageError>{errorText}</C.MessageError>
                )}
                <C.ContainerTerms>
                  <C.InputTerms
                    type={"checkbox"}
                    id="terms"
                    onChange={() => setAceptTerms(true)}
                  />
                  <C.LabelTerms htmlFor="terms">
                    Li e concordo com os{" "}
                    <C.Link onClick={() => navigate("/terms")}>
                      <strong>Termos e Condições</strong>.
                    </C.Link>
                    , tembém estou ciente que esta reserva me vincula somente à
                    esta campanha criada pelo(a) organizador(a) e NÃO à
                    plataforma Loja da Rifa.
                  </C.LabelTerms>
                </C.ContainerTerms>
                <C.ContainerTicketNumber>
                  <C.TicketTitle>Números escolhidos</C.TicketTitle>
                  <C.Ticket>
                    {selectedTickets.map((ticket) => (
                      <C.TicketNumber key={ticket}>
                        {String(ticket).padStart(3, "0")}{" "}
                      </C.TicketNumber>
                    ))}
                  </C.Ticket>
                </C.ContainerTicketNumber>
                <C.TicketValue>{formatPrice(paymentValue)}</C.TicketValue>
                {!loadingPayments && (
                  <C.ButtonRegister onClick={handleSaveReservation}>
                    Reservar
                  </C.ButtonRegister>
                )}
                {loadingPayments && (
                  <C.ContainerLoading>
                    <C.Loading1>
                      <BsGear />
                    </C.Loading1>
                    <C.LoadingText>Confirmando reservas</C.LoadingText>
                  </C.ContainerLoading>
                )}
              </C.FormInput>
            </C.Form>
          )}
          {successRecord && (
            <PaymentDetails
              raffle={raffle}
              paymentValue={paymentValue}
              user={user}
              handleClosePayment={handleCancelReservation}
              selectedTickets={selectedTickets}
            />
          )}
        </C.ContainerForm>
      </Modal>
    </C.Container>
  );
}

export default FormReservation;
