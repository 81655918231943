import { User } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { BiSearchAlt } from 'react-icons/bi';
import { FaCommentDollar } from 'react-icons/fa';
import { GrUserAdmin } from 'react-icons/gr';
import InputMask from 'react-input-mask';

import { getTicketsDB } from '../../../db/api/apiServer';
import { colorStatusTicketConfig } from '../../../helpers/FunctionsCalc';
import { checkValidity } from '../../../helpers/raffle';
import {
  Raffle,
  RaffleDbKey,
  StatusTicketDB,
  TicketType
} from '../../../types/Raffle';
import DropdownSales from '../DropdownSales';
import * as C from './styles';

import 'bootstrap/dist/css/bootstrap.min.css';
import { useInfoContext } from '../../../contexts/userInfoContext';

type Props = {
  visible: boolean;
  raffles: Raffle[];
  userFirebase: User | null;
};

function Sales (props: Props) {
  const { visible, raffles, userFirebase } = props;

  const [raffleSelected, setRaffleSelected] = useState<null | RaffleDbKey>(
    null
  );
  const [tickets, setTickets] = useState<TicketType[] | null>(null);
  const [ticketsFiltered, setTicketsFiltered] = useState<TicketType[] | null>(
    null
  );
  const [visibleTicket, setVisibleTicket] = useState<StatusTicketDB>(
    StatusTicketDB.ALL
  );
  const [phone, setPhone] = useState('');

  const { state } = useInfoContext();

  useEffect(() => {
    const getTickets = async () => {
      if (raffleSelected !== null) {
        const token = (await userFirebase?.getIdToken()) as string;
        const ticketsDB = await getTicketsDB(
          raffleSelected.user,
          raffleSelected.doc,
          token
        );

        setTickets(ticketsDB);
      } else {
        setTickets(null);
      }
    };
    getTickets();
  }, [raffleSelected, raffles, userFirebase]);

  useEffect(() => {
    if (tickets !== null) {
      if (visibleTicket === StatusTicketDB.ALL) {
        setTicketsFiltered(tickets);
      } else if (visibleTicket === StatusTicketDB.DISPONIBLE) {
        handleFilterDisponible();
      } else if (visibleTicket === StatusTicketDB.RESERVED) {
        handleFilterReserved();
      } else if (visibleTicket === StatusTicketDB.PAID) {
        handleFilterPaid();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tickets]);

  const handleTickets = (raffleId: string) => {
    const userDB = state.infoUser?.email as string;
    const raffleDoc = raffleId.split('-')[0].slice(0, 6);

    const paramsRaffle = {
      user: userDB,
      doc: raffleDoc
    };

    setRaffleSelected(paramsRaffle);
  };

  const updateTikets = async () => {
    const token = (await userFirebase?.getIdToken()) as string;
    if (raffleSelected !== null) {
      const ticketsDB = await getTicketsDB(
        raffleSelected.user,
        raffleSelected.doc,
        token
      );

      setTickets(ticketsDB);
    }
  };

  const updateTiketsPayment = async (ticketNumber: number) => {
    if (tickets !== null) {
      const ticketsUpdatedPayment: TicketType[] = tickets.map((ticket) => {
        if (ticket.number === ticketNumber) {
          ticket.status = StatusTicketDB.PAID;
        }
        return ticket;
      }
      );

      setTickets(ticketsUpdatedPayment);
    }
  };

  const updateTiketsCalcelReserve = async (ticketNumber: number) => {
    if (tickets !== null) {
      const ticketsUpdatedPayment: TicketType[] = tickets.map((ticket) => {
        if (ticket.number === ticketNumber) {
          ticket.status = StatusTicketDB.DISPONIBLE;
          ticket.buyerName = '';
          ticket.buyerPhone = '';
          ticket.buyerEmail = '';
          ticket.date = '';
        }
        return ticket;
      }
      );

      setTickets(ticketsUpdatedPayment);
    }
  };

  const handleFilterPhone = (): void => {
    const formattedPhone = phone.replace(/\D/g, '');
    if (formattedPhone.length < 11) {
      alert('Digite um número de telefone válido!\nEx: (99)99999-9999');
      return;
    }
    setVisibleTicket(StatusTicketDB.PHONE);
    if (tickets) {
      setTicketsFiltered(
        tickets.filter((ticket) => ticket.buyerPhone === formattedPhone)
      );
    }
  };

  const handleFilterVanquished = (): void => {
    if (tickets) {
      const ticketsReserved = tickets.filter((ticket) => {
        return ticket.status === StatusTicketDB.RESERVED;
      });
      setTicketsFiltered(
        ticketsReserved.filter((ticket) => checkValidity(ticket.date))
      );
    }
  };

  const handleFilterDisponible = (): void => {
    if (tickets) {
      setTicketsFiltered(
        tickets.filter((ticket) => ticket.status === StatusTicketDB.DISPONIBLE)
      );
    }
  };

  const handleFilterPaid = (): void => {
    if (tickets) {
      setTicketsFiltered(
        tickets.filter((ticket) => ticket.status === StatusTicketDB.PAID)
      );
    }
  };

  const handleFilterReserved = (): void => {
    if (tickets) {
      setTicketsFiltered(
        tickets.filter((ticket) => ticket.status === StatusTicketDB.RESERVED)
      );
    }
  };

  return (
    <C.Container visible={visible}>
      <C.Title>Vendas</C.Title>
      <C.ContainerBody>
        <C.ContainerRaffles>
          <C.SelectRaffles
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              if (!e.target.value) {
                setRaffleSelected(null);
              } else {
                handleTickets(e.target.value);
              }
            }}
          >
            <C.SelectRafflesOption value={''}>
              Selecione uma rifa
            </C.SelectRafflesOption>
            {raffles.map((raffle, index) => (
              <C.SelectRafflesOption key={index} value={raffle.id}>
                {raffle.name}
              </C.SelectRafflesOption>
            ))}
          </C.SelectRaffles>
        </C.ContainerRaffles>
        {raffleSelected && (
          <C.ContainerFilterPhone>
            <C.Filter>
              <C.TextFilter>Buscar por telefone:</C.TextFilter>
              <C.FilterPhone>
                <InputMask
                  mask="(99) 99999-9999"
                  placeholder="Telefone com DDD"
                  value={phone}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setPhone(e.target.value);
                  }}
                />
                <C.FilterButton onClick={() => handleFilterPhone()}>
                  <BiSearchAlt />
                </C.FilterButton>
              </C.FilterPhone>
            </C.Filter>
            <C.FilterVanquished
              selected={visibleTicket === StatusTicketDB.VANQUISHED}
              onClick={() => {
                setVisibleTicket(StatusTicketDB.VANQUISHED);
                handleFilterVanquished();
              }}
            >
              Vencidos
            </C.FilterVanquished>
          </C.ContainerFilterPhone>
        )}
        {raffleSelected && (
          <C.ContainerSalesFilter>
            <C.ButtonAll
              selected={visibleTicket === StatusTicketDB.ALL}
              onClick={() => {
                setVisibleTicket(StatusTicketDB.ALL);
                setTicketsFiltered(tickets);
              }}
            >
              Todos
            </C.ButtonAll>
            <C.ButtonReserved
              selected={visibleTicket === StatusTicketDB.RESERVED}
              onClick={() => {
                setVisibleTicket(StatusTicketDB.RESERVED);
                handleFilterReserved();
              }}
            >
              Reservados
            </C.ButtonReserved>
            <C.ButtonSold
              selected={visibleTicket === StatusTicketDB.PAID}
              onClick={() => {
                setVisibleTicket(StatusTicketDB.PAID);
                handleFilterPaid();
              }}
            >
              Pagos
            </C.ButtonSold>
            <C.ButtonAvailable
              selected={visibleTicket === StatusTicketDB.DISPONIBLE}
              onClick={() => {
                setVisibleTicket(StatusTicketDB.DISPONIBLE);
                handleFilterDisponible();
              }}
            >
              Disponíveis
            </C.ButtonAvailable>
          </C.ContainerSalesFilter>
        )}
        {raffleSelected && (
          <C.ContainerSales>
            {ticketsFiltered?.map((ticket, index) => (
              <C.ContainerTicket key={index}>
                <C.ContainerTicketHeader>
                  <C.TicketNumber
                    status={colorStatusTicketConfig(ticket.status)}
                  >
                    Nº: <span>{String(ticket.number).padStart(3, '0')}</span>
                  </C.TicketNumber>
                  {checkValidity(ticket.date) &&
                    ticket.status === StatusTicketDB.RESERVED && (
                      <FaCommentDollar />
                  )}
                </C.ContainerTicketHeader>
                {ticket.status !== StatusTicketDB.DISPONIBLE && (
                  <C.BuyerName>
                    <GrUserAdmin /> {ticket.buyerName}
                  </C.BuyerName>
                )}
                {ticket.status !== StatusTicketDB.DISPONIBLE && (
                  <C.ContainerOptions>
                    <DropdownSales
                      ticket={ticket}
                      userFirebase={userFirebase}
                      raffleSelected={raffleSelected}
                      updateTickets={updateTikets}
                      updateTiketsPayment={updateTiketsPayment}
                      updateTiketsCalcelReserve={updateTiketsCalcelReserve}
                    />
                  </C.ContainerOptions>
                )}
              </C.ContainerTicket>
            ))}
          </C.ContainerSales>
        )}
      </C.ContainerBody>
    </C.Container>
  );
}

export default Sales;


