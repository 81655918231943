import React from 'react';
import { GiReceiveMoney } from 'react-icons/gi';
import { IoTicketOutline } from 'react-icons/io5';
import {
  MdSettings,
  MdSupervisorAccount,
  MdSubtitlesOff,
  MdAttachMoney
} from 'react-icons/md';

import * as C from './styles';

function Benefits () {
  return (
    <C.Container>
      <C.ContainerItem>
        <C.Item>
          <C.ContainerSVG>
            <MdAttachMoney />
          </C.ContainerSVG>
          Pagamentos direto em sua conta.
        </C.Item>
        <C.Item>
          <C.ContainerSVG>
            <MdSubtitlesOff />
          </C.ContainerSVG>
          Prazos de renováveis.
        </C.Item>
        <C.Item>
          <C.ContainerSVG>
            <MdSettings />
          </C.ContainerSVG>
          Totalmente configurável.
        </C.Item>
      </C.ContainerItem>
      <C.ContainerItem>
        <C.Item>
          <C.ContainerSVG>
            <GiReceiveMoney />
          </C.ContainerSVG>
          Não cobramos taxa por venda.
        </C.Item>
        <C.Item>
          <C.ContainerSVG>
            <MdSupervisorAccount />
          </C.ContainerSVG>
          Controle total dos participantes.
        </C.Item>
        <C.Item>
          <C.ContainerSVG>
            <IoTicketOutline />
          </C.ContainerSVG>
          Quantidade de números personalizável.
        </C.Item>
      </C.ContainerItem>
    </C.Container>
  );
}

export default Benefits;
