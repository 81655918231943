type ErrorWithMessage = Error & { message: string };

export const checkFieldErrors = (
  name: string,
  email: string,
  password: string,
  phone: string,
  confirmTerms: boolean
): string => {
  if (name === '') {
    return 'Preencha o campo nome';
  } else if (email === '') {
    return 'Preencha o campo email';
  } else if (password === '') {
    return 'Preencha o campo senha';
  } else if (phone === '') {
    return 'Preencha o campo telefone';
  } else if (phone.length < 11) {
    return 'Preencha um telefone celular válido';
  } else if (!confirmTerms) {
    return 'Você precisa aceitar os termos de uso';
  }

  return '';
};

export const showErrorFirebase = (error: unknown): string => {
  const typedError = error as ErrorWithMessage;
  let errorMessage = '';
  switch (typedError.message) {
    case 'Firebase: Error (auth/internal-error).':
      errorMessage = 'Dados incompleto';
      break;
    case 'Firebase: Error (auth/missing-email).':
      errorMessage = 'Adicione o Email';
      break;
    case 'Firebase: Error (auth/invalid-email).':
      errorMessage = 'Email inválido';
      break;
    case 'Firebase: Password should be at least 6 characters (auth/weak-password).':
      errorMessage = 'A senha deve conter no mínimo 6 caracteres';
      break;
    case 'Firebase: Error (auth/email-already-in-use).':
      errorMessage = 'Email utilizado por outro usuáro';
      break;
    case 'Firebase: Error (auth/wrong-password).':
      errorMessage = 'Senha inválida';
      break;
    case 'Firebase: Error (auth/user-not-found).':
      errorMessage = 'Usuário não existe';
      break;
    case 'Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests).':
      errorMessage = 'Acesso temporariamente bloqueado, tente novamente mais tarde.';
      break;
    case 'Senhas não conferem':
      errorMessage = 'Senhas não conferem';
      break;
    default:
      errorMessage =
        'Procedimento não efetivado, confira os dados e tente novamente';
  }

  return errorMessage;
};
