import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';
import React, { useState } from 'react';
import { BsGear } from 'react-icons/bs';
import InputMask from 'react-input-mask';
import { useInfoContext } from '../../../contexts/userInfoContext';

import { editRaffleDB } from '../../../db/api/editDatabase';
import { hours, raffleMethods, pixMethods } from '../../../helpers/arrays';
import { getDocFirebase, validateFieldsEdit } from '../../../helpers/raffle';
import { RaffleEdit } from '../../../types/Raffle';
import {
  Photo,
  PixMethod,
  Raffle,
  WhatsGroupValue
} from '../../../types/Raffle';
import UploadImage from '../UploadImage';
import * as C from './styles';

type Props = {
  updateRaffles: () => Promise<void>;
  raffle: Raffle;
  handleClose: () => void;
};

function EditRaffle (props: Props) {
  const { updateRaffles, raffle, handleClose } = props;

  const { state } = useInfoContext();

  const raffleId = raffle.id;

  const [showDate, setShowDate] = useState<boolean>(false);
  const [emojiOpen, setEmojiOpen] = useState<boolean>(false);

  const [nameManager, setNameManager] = useState<string>(raffle.userName);
  const [name, setName] = useState<string>(raffle.name);
  const [raffleMethod, setRaffleMethod] = useState<string>(raffle.method);
  const [phone, setPhone] = useState<string>(raffle.userPhone);
  const [date, setDate] = useState<string>(raffle.date);
  const [hour, setHour] = useState<string>(raffle.hour);
  const [pixMethod, setPixMethod] = useState<string>(raffle.pixMethod);
  const [pix, setPix] = useState<string>(raffle.pix);
  const [pixName, setPixName] = useState<string>(raffle.pixName);
  const [description, setDescription] = useState<string>(raffle.description);
  const [arrayImages, setArrayImages] = useState<Photo[]>(raffle.images);
  const [uploadRaffle, setUploadRaffle] = useState<boolean>(false);
  const [whatsGroup, setWhatsGroup] = useState<string>(
    raffle.whatsGroup ? WhatsGroupValue.YES : WhatsGroupValue.NO
  );
  const [positionCursorTextArea, setPositionCursorTextArea] =
    useState<number>(0);
  const [urlWhatsappGroup, setUrlWhatsappGroup] = useState<string>(
    raffle.urlWhatsappGroup
  );

  const handleDate = (date: string): void => {
    const formatDate = date.split('-').reverse().join('-');
    setDate(formatDate);
  };

  const handleEditRaffle = async () => {
    const formattedPhone = phone.replace(/\D/g, '');
    const raffleDoc = getDocFirebase(raffleId);

    const incorrectFields = validateFieldsEdit(
      nameManager,
      name,
      formattedPhone,
      date,
      pixMethod,
      pix,
      pixName,
      description
    );

    if (whatsGroup === WhatsGroupValue.YES && !urlWhatsappGroup) {
      alert('Você precisa informar o link do grupo do whatsapp');
      return;
    }

    if (incorrectFields) {
      alert(incorrectFields);
      return;
    }

    const newData: RaffleEdit = {
      userName: nameManager,
      userPhone: formattedPhone,
      name,
      description,
      images: arrayImages.length === 0 ? [{ url: '', name: '' }] : arrayImages,
      pixMethod,
      pix,
      pixName,
      method: raffleMethod,
      date: date as string,
      hour,
      whatsGroup: whatsGroup === WhatsGroupValue.YES,
      urlWhatsappGroup
    };

    try {
      setUploadRaffle(true);
      const token = (await state.infoUser?.getIdToken()) as string;
      const email = state.infoUser?.email as string;

      await editRaffleDB(email, token, raffleDoc, newData);
      updateRaffles();
      handleClose();
    } catch (error) {
      alert('Problemas ao salvar a rifa \n Tenter novamente mais tarde');
    }
  };

  const handleEmojiClick = (emojiData: EmojiClickData) => {
    const text1 = description.slice(0, positionCursorTextArea);
    const text2 = description.slice(positionCursorTextArea);
    setDescription(text1 + emojiData.emoji + text2);
  };

  const handleTextArea = (text: string, positionCursor: number) => {
    setDescription(text);
    setPositionCursorTextArea(positionCursor);
  };

  return (
    <C.Container>
      <C.ContainerForm>
        <C.ContainerFormInput>
          <C.Label>Nome do administrador da rifa</C.Label>
          <C.Input
            value={nameManager}
            type="text"
            maxLength={50}
            placeholder={'Nome do ADM da rifa'}
            onChange={(e) => setNameManager(e.target.value)}
          />
        </C.ContainerFormInput>
        <C.ContainerFormInput>
          <C.Label>Nome da rifa</C.Label>
          <C.Input
            value={name}
            type="text"
            maxLength={50}
            placeholder={'Nome da sua rifa'}
            onChange={(e) => setName(e.target.value)}
          />
        </C.ContainerFormInput>
        <C.ContainerFormInput>
          <C.Label>Onde será o sorteio?</C.Label>
          <C.Select
            value={raffleMethod}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setRaffleMethod(e.target.value);
            }}
          >
            {raffleMethods.map((method) => (
              <C.Option value={method} key={method}>
                {method}
              </C.Option>
            ))}
          </C.Select>
        </C.ContainerFormInput>
        <C.ContainerFormInput>
          <C.Label>Telefone de suporte da rifa</C.Label>
          <C.ContainerInputMask>
            <InputMask
              mask="(99) 99999-9999"
              placeholder="Telefone com DDD"
              value={phone}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setPhone(e.target.value);
              }}
            />
          </C.ContainerInputMask>
        </C.ContainerFormInput>
        <C.ContainerFormInput>
          <C.Label>
            Pretende criar um Grupo no WhatsAapp para participantes?
          </C.Label>
          <C.Select
            value={whatsGroup}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setWhatsGroup(e.target.value);
            }}
          >
            {' '}
            <C.Option value={WhatsGroupValue.NO} key={WhatsGroupValue.NO}>
              NÃO
            </C.Option>
            <C.Option value={WhatsGroupValue.YES} key={WhatsGroupValue.YES}>
              SIM
            </C.Option>
          </C.Select>
        </C.ContainerFormInput>
        {whatsGroup === WhatsGroupValue.YES && (
          <C.ContainerFormInput>
            <C.Label>Cole aqui o link do grupo da rifa</C.Label>
            <C.Text>
              * <span>Dica</span>: Crie um grupo, após criado clique no{' '}
              <span>nome do grupo</span> {'>'} <span>Convidar via link</span>{' '}
              {'>'} <span>Copiar link</span> e cole aqui.
              <br />
              Respeite os caracteres maiusculos e minusculos.
            </C.Text>
            <C.Text>
              <span>Exemplo:</span>{' '}
              https://chat.whatsapp.com/BehTTeozJA3CwGbQuj7Krs
            </C.Text>
            <C.Input
              value={urlWhatsappGroup}
              type="text"
              maxLength={60}
              placeholder={'Link do grupo da rifa'}
              onChange={(e) => setUrlWhatsappGroup(e.target.value)}
            />
          </C.ContainerFormInput>
        )}
        <C.ContainerFormInput>
          <C.Label>Data prevista do sorteio</C.Label>
          <C.Text>* Escolha apenas uma opção.</C.Text>
          <C.ContainerCheckboxDate>
            <C.InputCheckbox
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  setDate('Ao encerrar os bilhetes');
                } else {
                  setDate('Informar data');
                }
              }}
            />
            <C.LabelCheckbox>Ao encerrar os bilhetes</C.LabelCheckbox>
            <C.Text>ou</C.Text>
            <C.ButtonDate onClick={() => setShowDate(!showDate)}>
              Informar data
            </C.ButtonDate>
          </C.ContainerCheckboxDate>
          {showDate && (
            <C.Input type="date" onChange={(e) => handleDate(e.target.value)} />
          )}
          <C.Text>
            Sorteio: <span>{date}</span>
          </C.Text>
        </C.ContainerFormInput>
        <C.ContainerFormInput>
          <C.Label>Hora do sorteio? *opcional</C.Label>
          <C.Select
            value={hour}
            onChange={(e) => {
              setHour(e.target.value);
            }}
          >
            <C.Option></C.Option>
            {hours.map((hour) => (
              <C.Option value={hour} key={hour}>
                {hour}
              </C.Option>
            ))}
          </C.Select>
        </C.ContainerFormInput>
        <C.ContainerPayment>
          <C.TitlePayment>Dados do seu PIX</C.TitlePayment>
          <C.ContainerFormInput>
            <C.Label>Tipo do PIX</C.Label>
            <C.Select
              value={pixMethod}
              onChange={(e) => {
                setPixMethod(e.target.value);
              }}
            >
              <C.Option></C.Option>
              {pixMethods.map((method) => (
                <C.Option value={method} key={method}>
                  {method}
                </C.Option>
              ))}
            </C.Select>
          </C.ContainerFormInput>
          {pixMethod === PixMethod.CPF_CNPJ || pixMethod === PixMethod.PHONE
            ? (
            <C.ContainerFormInput>
              <C.Label>PIX</C.Label>
              <C.Input
                value={pix}
                type="number"
                placeholder={'Digite somente os números'}
                onChange={(e) => setPix(e.target.value)}
              />
              <C.TextWarning>
                Não Digite: <span>( ) / - . , </span>
              </C.TextWarning>
            </C.ContainerFormInput>
              )
            : (
            <C.ContainerFormInput>
              <C.Label>PIX</C.Label>
              <C.Input
                value={pix}
                type="text"
                maxLength={50}
                placeholder={'Seu PIX'}
                onChange={(e) => setPix(e.target.value)}
              />
            </C.ContainerFormInput>
              )}
          <C.ContainerFormInput>
            <C.Label>Nome do PIX</C.Label>
            <C.Input
              value={pixName}
              type="text"
              maxLength={50}
              placeholder={'Nome que aparecerá no PIX'}
              onChange={(e) => setPixName(e.target.value)}
            />
          </C.ContainerFormInput>
        </C.ContainerPayment>
        <C.ContainerFormInput>
          <C.Label>Descrição da rifa</C.Label>
          <C.InputTextArea
            rows={12}
            placeholder="Descreva a sua rifa, o que será sorteado, como será o sorteio, regras ,etc... , tudo que o comprador precisa saber, sinta-se a vontade para usar os emiji abaixo."
            value={description}
            onChange={(e) =>
              handleTextArea(e.target.value, e.currentTarget.selectionStart)
            }
            onClick={(e) =>
              setPositionCursorTextArea(e.currentTarget.selectionStart)
            }
          />
    <C.EmojiButton onClick={() => setEmojiOpen(!emojiOpen)}>
      {!emojiOpen ? 'Abrir emoji' : 'Fechar Emoji'} 😀
    </C.EmojiButton>
    <C.EmojiArea visible={emojiOpen}>
        <EmojiPicker onEmojiClick={handleEmojiClick} searchDisabled />
    </C.EmojiArea>
        </C.ContainerFormInput>
        <UploadImage
          arrayImages={arrayImages}
          setArrayImages={setArrayImages}
          raffleId={raffleId}
        />
        {uploadRaffle && (
          <C.ContainerLoading>
            <C.Loading1>
              <BsGear />
            </C.Loading1>
            <C.LoadingText>Estamos criando a rifa</C.LoadingText>
            <C.LoadingText>
              Ao finalizar você será redirecionado para a rifa.
            </C.LoadingText>
            <C.LoadingText>Aguarde alguns minutos</C.LoadingText>
          </C.ContainerLoading>
        )}
        {!uploadRaffle && (
          <C.ButtonSave onClick={handleEditRaffle}>Editar rifa</C.ButtonSave>
        )}
      </C.ContainerForm>
    </C.Container>
  );
}

export default EditRaffle;
