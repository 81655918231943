import React, { useState } from 'react';
import { BsWhatsapp } from 'react-icons/bs';

import * as C from './styles';

function Questions () {
  const [answerVisible, setanswerVisible] = useState<number>(0);

  const showAnswer = (e: number) => {
    if (answerVisible === e) {
      setanswerVisible(0);
    } else {
      setanswerVisible(e);
    }
  };

  const apiWhatsappLojaDaRifa =
    'https://api.whatsapp.com/send?phone=5567999519176&text=Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20sobre%20as%20rifas%20da%20LojadaRifa';

  return (
    <C.Container>
      <C.ContainerGlobalQuestions>
        <C.Title>Perguntas frequentes?</C.Title>
        <C.ContainerQuestions>
          <C.ContainerQuestion>
            <C.Question onClick={() => showAnswer(1)}>
              Como recebo os pagamentos?
            </C.Question>
            <C.Answer1 visible={answerVisible === 1}>
              Os pagamentos são efetuados diretamente em sua conta bancária via
              PIX.
              <br />
              <br />
              Toda as transações são feitas diretamente a você, não recebemos ou
              retemos nenhum valor arrecadado nas vendas dos números de sua
              rifa.
            </C.Answer1>
          </C.ContainerQuestion>
          <C.ContainerQuestion>
            <C.Question onClick={() => showAnswer(2)}>
              Existe algum valor a pagar após o pagamento da taxa inicial?
            </C.Question>
            <C.Answer2 visible={answerVisible === 2}>
              Não, você paga apenas a taxa da publicação do site, de acordo com a
              estimativa da arrecadação que você estipulou, simples assim e sem
              pegadinhas.
              <br />
              <br />
              Não cobramos nenhuma taxa adicional nas vendas dos
              bilhetes.
              <br />
              <br />
              Você publica e efetua o sorteio quando quiser.
              <br />
              <br />
              Seu site estará disponível e on-line por um período de 6 meses, após este período nossa equipe entrará em contato para renovar por mais 6 meses mediante pagamento de uma nova taxa de publicação.
            </C.Answer2>
          </C.ContainerQuestion>
          <C.ContainerQuestion>
            <C.Question onClick={() => showAnswer(3)}>
              Como é feito os sorteios?
            </C.Question>
            <C.Answer3 visible={answerVisible === 3}>
              O sorteio é realizado da forma que você estipular, loteria
              federal, lives, aplicativos ou site de sorteios.
              <br />
              <br />
              Sempre priorize o mais confiável possível para seus clientes.
              <br />
              <br />A Loja da rifa <strong>não</strong> disponibiliza formas de
              sorteio.
              <br />
              <br />
              Dica: A Loteria Federal é o mais usado entre os métodos de
              sorteio.
            </C.Answer3>
          </C.ContainerQuestion>
          <C.ContainerQuestion>
            <C.Question onClick={() => showAnswer(4)}>
              Quantos números é possível ser gerado?
            </C.Question>
            <C.Answer4 visible={answerVisible === 4}>
              Pode ser gerado quantos numeros voce quiser, desde que não
              ultrapasse 1000 números.
              <br />
              <br />
              Os numeros vão de 000 até a quanidade descrita por você.
              <br />
              <br />
              EX: 25 números, vai de 000 até 024.
            </C.Answer4>
          </C.ContainerQuestion>
          <C.ContainerQuestion>
            <C.Question onClick={() => showAnswer(5)}>
              E se não vender todos os bilhetes?
            </C.Question>
            <C.Answer5 visible={answerVisible === 5}>
              O ideal é vender todos os bilhetes, caso aconteça de não vender
              tudo você poderá alterar a dia do sorteio e informar no site a
              nova data.
              <br />
              <br />
              Uma possibilidade seria fazer promoções e descontos na compra
              varios bilhetes para alavancar vendas.
              <br />
              <br />É importante que nas regras da sua rifa, seje informado que
              o sorteio ocorrerá após a venda de todos os bilhetes disponíveis.
            </C.Answer5>
          </C.ContainerQuestion>
          <C.ContainerQuestion>
            <C.Question onClick={() => showAnswer(6)}>
              Se o cliente não pagar?
            </C.Question>
            <C.Answer6 visible={answerVisible === 6}>
              Quando um cliente reserva um numero ele recebe um prazo
              de 3 dias para o pagamento, caso ele não pague no
              prazo, nosso sistema notifica as reservas vencidas e você decide se conversa com o cliente atravéz de um link no WhatsApp ou cancela a reserva liberando o
              numero para outra pessoa comprar.
            </C.Answer6>
          </C.ContainerQuestion>
          <C.ContainerQuestion>
            <C.Question onClick={() => showAnswer(7)}>
              Posso fazer mais de uma rifa?
            </C.Question>
            <C.Answer7 visible={answerVisible === 7}>
              Sim, você pode fazer quantas rifas quiser.
            </C.Answer7>
          </C.ContainerQuestion>
        </C.ContainerQuestions>
      </C.ContainerGlobalQuestions>
      <C.ContainerWhatsapp>
        <C.WhatsappText>
          Se ainda resta alguma dúvida não exite em perguntar, sinta-se a
          vontade para adicionar o nosso número de suporte pelo whatsapp +55
          (67) 99951-9176 ou clique no link abaixo.
        </C.WhatsappText>
        <C.Whatsapp href={apiWhatsappLojaDaRifa} target="_blank">
          <BsWhatsapp /> Nosso whatsapp
        </C.Whatsapp>
      </C.ContainerWhatsapp>
    </C.Container>
  );
}

export default Questions;
