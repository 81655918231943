import { buyerCustomer, RaffleEdit } from '../../types/Raffle';
import { headers, URL } from './apiServer';

export const saveReserveTicket = async (
  selectedTickets: number[],
  refDB: string,
  docRaffle: string | undefined,
  client: buyerCustomer
) => {
  let errorMessage = '';
  try {
    const resp = await fetch(`${URL}savereserve`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ selectedTickets, refDB, docRaffle, client })
    });
    if (!resp.ok) {
      const err = await resp.json();
      errorMessage = err.message;
      throw new Error(err.message);
    } else {
      const data = await resp.json();
      return data;
    }
  } catch (error) {
    throw new Error(errorMessage);
  }
};

export const confirmPaymentTicket = async (
  email: string,
  token: string,
  docRaffle: string,
  ticketNumber: string
) => {
  let errorMessage = '';
  try {
    const resp = await fetch(`${URL}confirmpayment`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ email, token, docRaffle, ticketNumber })
    });
    if (!resp.ok) {
      const err = await resp.json();
      errorMessage = err.message;
      throw new Error(err.message);
    } else {
      const data = await resp.json();
      return data;
    }
  } catch (error) {
    throw new Error(errorMessage);
  }
};

export const cancelReserveDB = async (
  email: string,
  token: string,
  docRaffle: string,
  ticketNumber: string
) => {
  let errorMessage = '';
  try {
    const resp = await fetch(`${URL}cancelreserve`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ email, token, docRaffle, ticketNumber })
    });
    if (!resp.ok) {
      const err = await resp.json();
      errorMessage = err.message;
      throw new Error(err.message);
    } else {
      const data = await resp.json();
      return data;
    }
  } catch (error) {
    throw new Error(errorMessage);
  }
};

export const finishRaffleDB = async (
  email: string,
  token: string,
  docRaffle: string,
  winner: string
) => {
  let errorMessage = '';
  try {
    const resp = await fetch(`${URL}finishraffle`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ email, token, docRaffle, winner })
    });
    if (!resp.ok) {
      const err = await resp.json();
      errorMessage = err.message;
      throw new Error(err.message);
    } else {
      const data = await resp.json();
      return data;
    }
  } catch (error) {
    throw new Error(errorMessage);
  }
};

export const editRaffleDB = async (
  email: string,
  token: string,
  docRaffle: string,
  newData: RaffleEdit
) => {
  let errorMessage = '';
  try {
    const resp = await fetch(`${URL}editraffle`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ email, token, docRaffle, newData })
    });
    if (!resp.ok) {
      const err = await resp.json();
      errorMessage = err.message;
      throw new Error(err.message);
    } else {
      const data = await resp.json();
      return data;
    }
  } catch (error) {
    throw new Error(errorMessage);
  }
};

export const updatePaidRaffleDB = async (
  email: string,
  token: string,
  docRaffle: string,
  userRefEmail: string
) => {
  let errorMessage = '';
  try {
    const resp = await fetch(`${URL}updatepaidraffle`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ email, token, docRaffle, userRefEmail })
    });
    if (!resp.ok) {
      const err = await resp.json();
      errorMessage = err.message;
      throw new Error(errorMessage);
    } else {
      const data = await resp.json();
      return data;
    }
  } catch (error) {
    throw new Error(errorMessage);
  }
};

export const updateUnpaidRaffleDB = async (
  email: string,
  token: string,
  docRaffle: string,
  userRefEmail: string
) => {
  let errorMessage = '';

  try {
    const resp = await fetch(`${URL}updateunpaidraffle`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ email, token, docRaffle, userRefEmail })
    });
    if (!resp.ok) {
      const err = await resp.json();
      errorMessage = err.message;
      throw new Error(errorMessage);
    } else {
      const data = await resp.json();
      return data;
    }
  } catch (error) {
    throw new Error(errorMessage);
  }
};
