import styled from 'styled-components';

export const Container = styled.div`
  background-color: var(--colorLightGrey);
  border-radius: 10px;
  padding: 10px;
  position: relative;
  width: 19%;
  height: 150px;
  margin: 2px;

  @media (max-width: 768px) {
    width: 48%;
  }
`;

export const Image = styled.img`
  max-width: 100%;
  display: block;
  margin-bottom: 10px;
  border-radius: 10px;
  max-height: 100%;
  margin: auto;
`;

export const ButtonDelete = styled.button`
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: center;
  display: block;
  margin: 0 0 0 auto;
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 5px;
  border: 1px solid var(--colorFontPrimary);
  color: var(--colorFontSecondary);
  width: 30px;
  height: 30px;
  font-size: 1.1em;
`;
