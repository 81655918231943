import React from 'react';

import { Raffle } from '../../../types/Raffle';
import * as C from './styles';

type Props = {
  raffle: Raffle;
};

function RaffleFinished (props: Props) {
  const { raffle } = props;

  const raffleWinner = raffle.winner.split('\n').map((paragraph, key) => {
    if (paragraph === '') return <br key={key} />;
    return (
      <span key={key}>
        {paragraph}
        <br />
      </span>
    );
  });

  return (
    <C.Container>
      <C.Title>Rifa Finalizada</C.Title>
      <C.Thanks>Obrigado por ter participado</C.Thanks>
      <C.ContainerWinner>
        <C.TitleWinner>Ganhador(es)</C.TitleWinner>
        <C.RaffleWinner>{raffleWinner}</C.RaffleWinner>
      </C.ContainerWinner>
    </C.Container>
  );
}

export default RaffleFinished;
