import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { BsWhatsapp } from "react-icons/bs";
import { Collapse, Button, CardBody, Card } from "reactstrap";

import { formatPrice } from "../../../helpers/FunctionsCalc";
import { Raffle } from "../../../types/Raffle";
import EditRaffle from "../EditRaffle";
import * as C from "./styles";
import copy from "copy-to-clipboard"; 

type Props = {
  raffle: Raffle;
  finishRaffle: (raffle: Raffle, textWinner: string) => void;
  updateRaffles: () => Promise<void>;
};

function RafflesActive(props: Props) {
  const { raffle, finishRaffle, updateRaffles } = props;

  const [nameWinner, setNameWinner] = useState<string>("");
  const [warningNameWinner, setWarningNameWinner] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showCopyTrasfer, setShowCopyTrasfer] = useState<boolean>(false);
  const [finishing, setFinishing] = useState(false);

  // Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const toggle = () => setIsOpen(!isOpen);

  const handleFinishRafle = () => {
    if (nameWinner === "") {
      setWarningNameWinner(true);
      return;
    }
    setFinishing(true);
    finishRaffle(raffle, nameWinner);
  };

  const phoneAdmin = "67999519176";

  const apiWhatsapp = `https://api.whatsapp.com/send?phone=55${phoneAdmin}&text=Este%20é%20o%20meu%20comprovante%20da%20taxa%20referente%20a%20rifa%20${raffle.name},%20sob%20responsabilidade%20de%20${raffle.userName}.`;

  const handleCopyURL = () => {
    setShowCopyTrasfer(true);
    copy(raffle.url);

    setTimeout(() => {
      setShowCopyTrasfer(false);
    }, 2000);
  };

  return (
    <C.Container>
      {finishing && (
        <C.FinishRaffle>
          <C.FinishText>Finalizando rifa...</C.FinishText>
        </C.FinishRaffle>
      )}
      {!raffle.paid && (
        <C.ContainerRate>
          <C.Rate>Taxa {formatPrice(raffle.publicationFee)} pendente</C.Rate>
          <C.TextInfo>
            Envie um <span>pix celular</span> para <span>67999519176</span>{" "}
            <br />
            nome da conta <span>Darlan Gomes Aguiar</span> para liberar a rifa
          </C.TextInfo>
          <C.WhatsApp href={apiWhatsapp} target={"_blank"}>
            <BsWhatsapp /> Enviar comprovante
          </C.WhatsApp>
        </C.ContainerRate>
      )}
      <C.ContainerRaffle>
        {showCopyTrasfer && (
          <C.CopyLink>Copiado para area de tranferência</C.CopyLink>
        )}
        <C.ContainerBody>
          <C.RaffleTitle>{raffle.name.toUpperCase()}</C.RaffleTitle>
          <C.ContainerInfo>
            <C.RaffleDate>Sorteio: {raffle.date}</C.RaffleDate>
            <C.RaffleValue>Valor: {formatPrice(raffle.price)}</C.RaffleValue>
          </C.ContainerInfo>
          <C.ContainerSettingsRaffle>
            <Button
              color="primary"
              onClick={() => {
                if (!raffle.paid) {
                  alert("Taxa não paga");
                  return;
                }
                toggle();
              }}
              style={{ marginBottom: "1rem" }}
            >
              Informar ganhador
            </Button>
            <Collapse isOpen={isOpen}>
              <Card>
                <CardBody>
                  <C.ContainerFinishRaffle>
                    <C.InputCloseRaffle
                      value={nameWinner}
                      placeholder="Escreva tudo o que for relevante para identificar o ganhador(es)"
                      rows={4}
                      cols={35}
                      onChange={(e) => setNameWinner(e.target.value)}
                    />
                    <C.TextWarning>
                      Só é possivel finalizar 1 vez.
                    </C.TextWarning>
                    {warningNameWinner && (
                      <C.TextWarning>
                        Escreva o nome do ganhador(es).
                      </C.TextWarning>
                    )}
                  {!finishing && <C.ButtonFinishRaffle onClick={handleFinishRafle}>
                    Informar e finalizar
                  </C.ButtonFinishRaffle>}
                  </C.ContainerFinishRaffle>
                </CardBody>
              </Card>
            </Collapse>
          </C.ContainerSettingsRaffle>
        </C.ContainerBody>
        <C.ContainerButtonsImage>
          <C.ContainerImage>
            <C.RaffleImage src={raffle.images[0].url} />
          </C.ContainerImage>

          <C.ContainerButtons>
            <C.ButtonEditRaffle onClick={handleShow}>
              Editar Rifa
            </C.ButtonEditRaffle>
            <C.ButtonSeeRaffle href={raffle.url} target={"_blank"}>
              Ver Rifa
            </C.ButtonSeeRaffle>
            <C.ButtonCopy onClick={handleCopyURL}>
              Copiar link da rifa
            </C.ButtonCopy>
          </C.ContainerButtons>
          <Modal show={show} onHide={handleClose} className={"modal-xl"}>
            <Modal.Header closeButton>
              <Modal.Title>Editar rifa</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {/* <ModalEditRaffle
              updateRaffles={updateRaffles}
              raffle={raffle}
              handleClose={handleClose}
            /> */}
              <EditRaffle
                updateRaffles={updateRaffles}
                raffle={raffle}
                handleClose={handleClose}
              />
            </Modal.Body>
          </Modal>
        </C.ContainerButtonsImage>
      </C.ContainerRaffle>
    </C.Container>
  );
}

export default RafflesActive;
