import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const Link = styled.a`
  text-decoration: none;
`;


export const CancelReserve = styled.div`
  padding-left: 18px;

  &:hover {
    cursor: pointer;
    background-color: var(--colorLightGrey);
  }
`;