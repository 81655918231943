import React from 'react';
import { BiSearchAlt } from 'react-icons/bi';
import InputMask from 'react-input-mask';

import { StatusTickets } from '../../../types/Raffle';
import * as C from './styles';

type Props = {
  phone: string;
  setPhone: React.Dispatch<React.SetStateAction<string>>;
  handleFilter: () => void;
  handleVisible: (ticketsVisibles: string) => void;
};

function Filter (props: Props) {
  const { phone, setPhone, handleFilter, handleVisible } = props;

  return (
    <C.ContainerFilter>
      <C.Title>
        <BiSearchAlt />
        Filtro
      </C.Title>
      <C.ContainerFilterPhone>
        <C.TitleDesc>Selecione abaixo para filtrar os Bilhetes</C.TitleDesc>
        <C.Filter>
          <C.TextFilter>Meus numeros:</C.TextFilter>
          <C.FilterPhone>
            <InputMask
              mask="(99) 99999-9999"
              placeholder="Telefone com DDD"
              value={phone}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setPhone(e.target.value);
              }}
            />
            <C.FilterButton onClick={() => handleFilter()}>
              <BiSearchAlt />
            </C.FilterButton>
          </C.FilterPhone>
        </C.Filter>
      </C.ContainerFilterPhone>
      <C.ContainerFilterOptions>
        <C.PaidTickets onClick={() => handleVisible(StatusTickets.PAID)}>
          Pagos
        </C.PaidTickets>
        <C.ReservedTickets
          onClick={() => handleVisible(StatusTickets.RESERVED)}
        >
          Reservados
        </C.ReservedTickets>
        <C.AvaibleTickets
          onClick={() => handleVisible(StatusTickets.DISPONIBLE)}
        >
          Disponíveis
        </C.AvaibleTickets>
        <C.AllTickets onClick={() => handleVisible(StatusTickets.ALL)}>
          Todos
        </C.AllTickets>
      </C.ContainerFilterOptions>
    </C.ContainerFilter>
  );
}

export default Filter;
