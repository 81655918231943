import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ContainerTickets = styled.div``;

export const NoTickets = styled.div`
  margin-top: 50px;
  color: var(--colorFontPrimary);
  font-size: 2em;
  font-weight: bold;

  svg {
    color: var(--colorFontSecondary);
  }

  @media (max-width: 768px) {
    margin-top: 30px;
    font-size: 1.5em;
  }
`;
