import { User } from 'firebase/auth';
import React, { useState } from 'react';
import { IoTicketOutline, IoTicketSharp } from 'react-icons/io5';
import { TbTicketOff } from 'react-icons/tb';

import { deleteRaffleDB } from '../../../db/api/deleteDatabase';
import { finishRaffleDB } from '../../../db/api/editDatabase';
import { getDocFirebase } from '../../../helpers/raffle';
import { MenuSelected, Raffle } from '../../../types/Raffle';
import RafflesActive from '../RafflesActive';
import RafflesInactive from '../RafflesInactive';
import * as C from './styles';

type Props = {
  visible: boolean;
  setSelected: React.Dispatch<React.SetStateAction<MenuSelected>>;
  rafflesClosed: Raffle[];
  rafflesRunning: Raffle[];
  userFirebase: User | null;
  updateRaffles: () => Promise<void>;
};

function Raffles (props: Props) {
  const {
    visible,
    rafflesClosed,
    rafflesRunning,
    setSelected,
    userFirebase,
    updateRaffles
  } = props;

  const [showRafflesRunning, setShowRafflesRunning] = useState<boolean>(true);
  const [showRafflesClosed, setShowRafflesClosed] = useState<boolean>(false);
  const [deleting, setDeleting] = useState(false);

  const finishRaffle = async (raffle: Raffle, textWinner: string) => {
    const email = userFirebase?.email as string;
    const token = (await userFirebase?.getIdToken()) as string;
    const raffleDoc = getDocFirebase(raffle.id);

    try {
      await finishRaffleDB(email, token, raffleDoc, textWinner);
      // acessar um link externo
      window.location.href = raffle.url;

    } catch (error) {
      alert('Erro ao informar ganhador(es), tente novamente mais tarde');
    }
  };

  const deleteRaffle = async (raffle: Raffle) => {
    const email = userFirebase?.email as string;
    const token = (await userFirebase?.getIdToken()) as string;
    const raffleDoc = getDocFirebase(raffle.id);
    const images = raffle.images;
    const raffleId = raffle.id;

    try {
      await deleteRaffleDB(email, token, raffleDoc, images, raffleId);
      setDeleting(false);
      updateRaffles();
    } catch (error) {
      alert('Erro ao deletar rifa, tente novamente mais tarde');
    }
  };

  return (
    <C.Container visible={visible}>
      <C.ContainerMyRaffles>
        <C.Title>Minhas Rifas</C.Title>
        <C.ContainerButtons>
          <C.ButtonRafflesRunning
            selected={showRafflesRunning}
            onClick={() => {
              setShowRafflesRunning(true);
              setShowRafflesClosed(false);
            }}
          >
            <IoTicketOutline /> Rifas em andamento
          </C.ButtonRafflesRunning>
          <C.ButtonRafflesClosed
            selected={showRafflesClosed}
            onClick={() => {
              setShowRafflesRunning(false);
              setShowRafflesClosed(true);
            }}
          >
            <IoTicketSharp /> Rifas encerradas
          </C.ButtonRafflesClosed>
        </C.ContainerButtons>
        <C.ContainerBody>
          {showRafflesRunning && (
            <C.ContainerRaffles>
              {rafflesRunning.length === 0 && (
                <C.ContainerNoRaffles>
                  <C.TextNoRaffles>
                    <TbTicketOff />
                  </C.TextNoRaffles>
                  <C.ButtonCreateRaffle
                    onClick={() => setSelected(MenuSelected.CREATE)}
                  >
                    Criar Rifa
                  </C.ButtonCreateRaffle>
                </C.ContainerNoRaffles>
              )}
              {rafflesRunning.map((raffle, index) => (
                <RafflesActive
                  raffle={raffle}
                  key={index}
                  finishRaffle={finishRaffle}
                  updateRaffles={updateRaffles}
                />
              ))}
            </C.ContainerRaffles>
          )}
          {showRafflesClosed && (
            <C.ContainerRaffles>
              {rafflesClosed.length === 0 && (
                <C.ContainerNoRaffles>
                  <C.TextNoRaffles>
                    <TbTicketOff />
                  </C.TextNoRaffles>
                </C.ContainerNoRaffles>
              )}
              {rafflesClosed.map((raffle, index) => (
                <RafflesInactive
                  raffle={raffle}
                  key={index}
                  finishRaffle={finishRaffle}
                  deleteRaffle={deleteRaffle}
                  deleting={deleting}
                  setDeleting={setDeleting}
                />
              ))}
            </C.ContainerRaffles>
          )}
        </C.ContainerBody>
      </C.ContainerMyRaffles>
    </C.Container>
  );
}

export default Raffles;
