import { Raffle } from '../../types/Raffle';

export const URL = '/';
// export const URL = 'http://localhost:5000/';

export const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*'
};

export const createRaffleDatabase = async (
  email: string,
  token: string,
  raffle: Raffle,
  amountOfTickets: number,
  raffleDoc: string
) => {
  let errorMessage = '';

  try {
    const resp = await fetch(`${URL}createraffle`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ email, token, raffle, amountOfTickets, raffleDoc })
    });
    if (!resp.ok) {
      const err = await resp.json();
      errorMessage = err.message;
      throw new Error(err.message);
    } else {
      await resp.json();
      return true;
    }
  } catch (error) {
    throw new Error(errorMessage);
  }
};

export const getRafflesDatabase = async (email: string, document: string) => {
  let errorMessage = '';

  try {
    const resp = await fetch(`${URL}getraffle`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ email, document })
    });
    if (!resp.ok) {
      const err = await resp.json();
      errorMessage = err.message;
      throw new Error(err.message);
    } else {
      const data = await resp.json();
      return data;
    }
  } catch (error) {
    throw new Error(errorMessage);
  }
};

export const getRafflesAdmin = async (email: string, token: string) => {
  let errorMessage = '';

  try {
    const resp = await fetch(`${URL}getraffleadmin`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ email, token })
    });
    if (!resp.ok) {
      const err = await resp.json();
      errorMessage = err.message;
      throw new Error(err.message);
    } else {
      const data = await resp.json();
      return data;
    }
  } catch (error) {
    throw new Error(errorMessage);
  }
};

export const getTicketsDB = async (email: string, doc: string, token: string) => {
  let errorMessage = '';

  try {
    const resp = await fetch(`${URL}gettickets`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ email, document: doc, token })
    });
    if (!resp.ok) {
      const err = await resp.json();
      errorMessage = err.message;
      throw new Error(err.message);
    } else {
      const data = await resp.json();
      return data;
    }
  } catch (error) {
    throw new Error(errorMessage);
  }
};

export const getUsersDB = async (email: string, token: string) => {
  let errorMessage = '';

  try {
    const resp = await fetch(`${URL}getallusers`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ email, token })
    });
    if (!resp.ok) {
      const err = await resp.json();
      errorMessage = err.message;
      throw new Error(err.message);
    } else {
      const data = await resp.json();
      return data;
    }
  } catch (error) {
    throw new Error(errorMessage);
  }
};

export const getRafflesUserManagerDB = async (email: string, token: string, userRefEmail: string) => {
  let errorMessage = '';

  try {
    const resp = await fetch(`${URL}getraffleuserref`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ email, token, userRefEmail })
    });
    if (!resp.ok) {
      const err = await resp.json();
      errorMessage = err.message;
      throw new Error(err.message);
    } else {
      const data = await resp.json();
      return data;
    }
  } catch (error) {
    throw new Error(errorMessage);
  }
};
