import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  background-color: var(--colorGrey);
  width: 100%;

  @media (max-width: 768px) {
    height: 80px;
  }
`;

export const Advertising = styled.a`
  color: var(--colorFontPrimary);
  text-decoration: none;
  margin-bottom: 10px;
  cursor: pointer;
`;

export const LinkSoftware = styled.a`
  font-size: 1.2em;
  color: var(--colorFontPrimary);
  font-weight: bold;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: var(--colorFontPrimary);
  }

  span {
    color: var(--colorFontSecondary);
  }

  svg {
    font-size: 1.6em;
    margin: 0 10px;
    color: white;
  }

  @media (max-width: 768px) {
    font-size: 0.8em;

    svg {
      font-size: 2em;
    }
  }
`;
