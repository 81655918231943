import styled from 'styled-components';

export const Container = styled.div``;
export const Title = styled.h1`
  color: var(--colorFontSecondary);
  font-size: 4.5rem;
  margin: 0;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;
export const Thanks = styled.p`
    text-align: center;
`;

export const ContainerWinner = styled.div`
  border: 5px solid var(--colorFontPrimary);
  border-radius: 10px;
  padding: 10px;

`;

export const TitleWinner = styled.h2`
    text-align: center;
    color: var(--colorFontSecondary);

`;
export const RaffleWinner = styled.div`
  text-align: center;
  font-size: 1.5rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;
