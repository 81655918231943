import { LocalValues } from '../types/Raffle';

export const calculateRate = (value: number): number => {
  if (value < 101) {
    return 15;
  } else if (value < 501) {
    return 40;
  } else if (value < 1001) {
    return 80;
  } else if (value < 2001) {
    return 100;
  } else if (value < 5001) {
    return 150;
  } else if (value < 10001) {
    return 200;
  } else if (value < 20001) {
    return 300;
  } else if (value < 50001) {
    return 400;
  } else if (value < 70001) {
    return 500;
  } else if (value < 99001) {
    return 700;
  } else {
    return 1000;
  }
};

export const formatPrice = (value: number): string => {
  return value.toLocaleString(LocalValues.LOCATION_BRAZIL, {
    style: LocalValues.CURRENCY,
    currency: LocalValues.BRAZILIAN_CURRENCY
  });
};

export const colorStatusTicket = (status: string): string => {
  const backgroundColorOrange = '#d8461f';
  const backgroundColorGreen = '#1ed488';
  const backgroundColorWhite = '#FFFFFF';
  switch (status) {
    case 'disponible':
      return backgroundColorWhite;
    case 'reserved':
      return backgroundColorOrange;
    case 'paid':
      return backgroundColorGreen;
    default:
      return backgroundColorWhite;
  }
};

export const formatPhone = (phone: string): string => {
  const ddd = phone.substring(0, 2);
  const firstPart = phone.substring(2, 7);
  const secondPart = phone.substring(7, 11);
  return `(${ddd}) ${firstPart}-${secondPart}`;
};

export const getExpirationDay = (): string => {
  const date = new Date();
  date.setDate(date.getDate() + 3);
  return date.toLocaleDateString('pt-BR');
};

export const colorStatusTicketConfig = (status: string): string => {
  const backgroundColorOrange = '#d8461f';
  const backgroundColorGreen = '#1ed488';
  const backgroundColorGrey = 'var(--colorGrey)';
  switch (status) {
    case 'disponible':
      return backgroundColorGrey;
    case 'reserved':
      return backgroundColorOrange;
    case 'paid':
      return backgroundColorGreen;
    default:
      return backgroundColorGrey;
  }
};
