import styled from 'styled-components';

export const Container = styled.a`
  position: fixed;
  bottom: 60px;
  right: 10px;
  z-index: 10;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 5px solid green;
  font-size: 1.8em;
  color: var(--colorFontSecondary);
  padding: 5px 15px;
  border-radius: 50px;
  background-color: var(--colorWhite);
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  svg:nth-child(1) {
    margin-right: 10px;
    color: green;

    @media (max-width: 768px) {
      margin-right: 0;
    }
  }

  svg:nth-child(2) {
    color: var(--colorFontPrimary);
  }

  &:hover {
    transform: scale(1.1);
    color: var(--colorFontSecondary);
  }

  @media (max-width: 768px) {
    padding: 5px;
    bottom: 50px;

    p {
      display: none;
    }
  }
`;

export const TextContact = styled.p`
  margin: 0;
  .loja {
    color: var(--colorFontPrimary);
  }
`;

export const DarkGreen = styled.span`
  color: var(--colorFontThird);
`;
