import styled from 'styled-components';

export const Container = styled.header`
  width: 300px;
  background-color: var(--colorFontSecondary);
  color: white;
  padding: 10px;
  font-size: inherit;
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: 768px) {
    width: 250px;
    font-size: 25px;
  }
`;
