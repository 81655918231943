import styled from 'styled-components';

export const Container = styled.div<{ visible: boolean }>`
  display: ${(props) => (props.visible ? 'block' : 'none')};
  width: calc(100% - 250px);
  padding: 30px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 5px;
  }
`;

export const Title = styled.h1`
  color: var(--colorFontPrimary);
  font-size: 2.5em;

  @media (max-width: 768px) {
    font-size: 1.5em;
    padding: 10px;
  }
`;

export const ContainerForm = styled.div`
  width: 500px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ContainerFormInput = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const Label = styled.label`
  font-weight: bold;
`;

export const Input = styled.input`
  width: 100%;
  padding: 5px;
  border: 3px solid var(--colorFontPrimary);
  border-radius: 10px;
`;

export const ContainerInfo = styled.div``;

export const Info = styled.p`
  margin: 0;

  span {
    font-weight: bold;
  }
`;

export const Select = styled.select`
  width: 100%;
  padding: 5px;
  border: 3px solid var(--colorFontPrimary);
  border-radius: 10px;
`;

export const Gift = styled.img`
  width: 200px;
  margin: 5px auto;
`;

export const Option = styled.option``;

export const ContainerInputMask = styled.div`
  input {
    width: 500px;
    padding: 5px;
    border: 3px solid var(--colorFontPrimary);
    border-radius: 10px;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const InputCheckbox = styled.input`
  width: 20px;
`;

export const ContainerCheckboxDate = styled.div``;

export const LabelCheckbox = styled.label`
  margin: 5px;
`;

export const ButtonDate = styled.button`
  width: 170px;
  display: block;
  margin: 5px;
  padding: 5px 10px;
  border-bottom: 3px solid var(--colorGrey);
  color: #fff;
  border-radius: 10px;
  background-color: var(--colorFontPrimary);
  cursor: pointer;
  font-weight: bold;
`;

export const Text = styled.p`
  margin: 0;
  font-size: 0.8em;
  span {
    font-weight: bold;
  }
`;

export const ContainerPayment = styled.div`
  width: 500px;
  border: 3px solid var(--colorFontPrimary);
  border-radius: 10px;
  padding: 10px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const TitlePayment = styled.p`
  font-weight: bold;
  color: var(--colorFontPrimary);
`;

export const TextWarning = styled.p`
  color: red;
  font-size: 0.8em;

  span {
    font-weight: bold;
  }
`;

export const InputTextArea = styled.textarea`
  width: 600px;
  padding: 10px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const EmojiArea = styled.div<{ visible: boolean }>`
  transition: 0.5s all ease;
  overflow: hidden;
  height: ${(props) => (props.visible ? '460px' : '0px')};
`;

export const EmojiButton = styled.button`
  width: 200px;
  padding: 5px 10px;
  margin: 5px 0 0 0;
  cursor: pointer;
  background-color: var(--colorFontPrimary);
  font-weight: bold;
  color: #fff;
  border-radius: 10px;
  border-bottom: 3px solid var(--colorGrey);
`;

export const ContainerLoading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;


`;

export const BodyLoading = styled.div`
  padding: 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--colorFontSecondary);
`;

export const Loading1 = styled.div`
  svg {
    font-size: 3rem;
    color: #fff;
    animation: spin 3s linear infinite;
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;

export const LoadingText = styled.div`
  font-weight: bold;
  color: #fff;

  @media (max-width: 768px) {
    font-size: 0.8em;
  }
`;

export const ButtonSave = styled.button`
  width: 200px;
  padding: 5px;
  cursor: pointer;
  background-color: var(--colorFontSecondary);
  font-weight: bold;
  color: #fff;
  border-radius: 10px;
  border-bottom: 3px solid var(--colorGrey);
  font-size: 1.3em;
  margin: 20px;
`;
