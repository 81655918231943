export type TicketType = {
  number: number;
  status: StatusTicketDB;
  buyerName: string;
  buyerEmail: string;
  buyerPhone: string;
  date: string;
};

export type Raffle = {
  id: string;
  userId: string;
  userName: string;
  userPhone: string;
  userEmail: string;
  creationDate: Date;
  name: string;
  description: string;
  active: boolean;
  paid: boolean;
  price: number;
  quantityTickets: number;
  images: Photo[];
  pixMethod: string;
  pix: string;
  pixName: string;
  method: string;
  date: string;
  hour: string;
  winner: string;
  url: string;
  publicationFee: number;
  whatsGroup: boolean;
  urlWhatsappGroup: string;
  dateFinish: string;
  tickets: TicketType[];
};

export type User = {
  userId: string;
  name: string;
  email: string;
  phone: string;
};

export type buyerCustomer = {
  name: string;
  email: string;
  phone: string;
}

export type RaffleEdit = {
  userName: string;
  userPhone: string;
  name: string;
  description: string;
  images: Photo[];
  pixMethod: string;
  pix: string;
  pixName: string;
  method: string;
  date: string;
  hour: string;
  whatsGroup: boolean;
  urlWhatsappGroup: string;
};

export type Photo = {
  name: string;
  url: string;
}

export type RaffleDbKey = {
  user: string;
  doc: string;
}

export enum StatusTickets {
  ALL = 'all',
  PAID = 'paid',
  RESERVED = 'reserved',
  DISPONIBLE = 'disponible',

}

export const enum StatusTicketDB {
  ALL = 'all',
  DISPONIBLE = 'disponible',
  RESERVED = 'reserved',
  PAID = 'paid',
  VANQUISHED = 'vanquished',
  PHONE = 'phone',
}

export enum LocalValues {
  LOCATION_BRAZIL = 'pt-BR',
  BRAZILIAN_CURRENCY = 'BRL',
  CURRENCY = 'currency',
}

export enum PixMethod {
  CPF_CNPJ = 'CPF/CNPJ',
  PHONE = 'Telefone',
  EMAIL = 'Email',
  RANDOM_KEY = 'Chave Aleatória',
}

export enum RaffleMethods {
  FEDERAL_LOTTERY = 'Loteria Federal',
  APP_SITE = 'App/Site de sorteio',
  FACEBOOK_LIVE = 'Live Facebook',
  INSTAGRAM_LIVE = 'Live Instagram',
  YOUTUBE_LIVE = 'Live YouTube',
  OTHERS = 'Outros',
  IN_DESCRIPTION = 'Na descrição',
}

export enum MenuSelected {
  RAFFLES = 'Raffles',
  SALES = 'Sales',
  CONFIG = 'Config',
  CREATE = 'Create',
}

export enum WhatsGroupValue {
  YES = 'SIM',
  NO = 'NÃO',
}
