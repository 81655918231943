import styled from "styled-components";

export const Container = styled.div``;

export const ContainerForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
  }
`;

export const Image = styled.img`
  width: 200px;
`;

export const Text = styled.p`
  font-size: 1.2em;
  color: var(--colorGrey);
  margin: 0;

  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

export const ReservCompleted = styled.p`
  margin: 0;
  font-size: 1.8em;
  color: var(--colorFontSecondary);
  font-weight: bold;
`;

export const Form = styled.div`
  width: 500px;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const FormInput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
  border: 3px solid var(--colorFontPrimary);
  border-radius: 5px;
  width: 90%;

  input {
    border: 0;
    padding: 5px;
    font-size: 1.1em;
    width: 100%;
    border-left: 1px solid var(--colorFontPrimary);
  }

  @media (max-width: 768px) {
    margin: 3px 0;
  }
`;

export const SVGInput = styled.div`
  font-size: 1.2em;
  padding: 0 5px;
  color: var(--colorFontSecondary);
  display: flex;
  align-items: center;
`;

export const Input = styled.input``;

export const MessageError = styled.p`
  margin: 0;
  border-radius: 5px;
  padding: 5px 10px;
  color: #fff;
  background: var(--colorError);
`;

export const ContainerTerms = styled.div`
  display: flex;
  width: 90%;
`;

export const InputTerms = styled.input`
  margin-right: 5px;
  width: 30px;
  height: 30px;
`;

export const LabelTerms = styled.label`
  font-size: 0.7em;
  line-height: 12px;
`;

export const TicketValue = styled.div`
  font-size: 1.3em;
  font-weight: bold;
  color: var(--colorFontPrimary);
`;

export const ContainerTicketNumber = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
export const TicketTitle = styled.div`
  margin: 0;
`;

export const Ticket = styled.div`
  width: fit-content;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
`;

export const TicketNumber = styled.div`
  margin: 1px 1px;
  border: 2px solid var(--colorFontPrimary);
  border-radius: 5px;
  padding: 1px 3px 0 3px;
  font-size: 0.7em;
`;

export const ContainerLoading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0 0 0;
`;

export const Loading1 = styled.div`
  svg {
    font-size: 3rem;
    color: var(--colorFontSecondary);
    animation: spin 3s linear infinite;

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }
  }
`;

export const LoadingText = styled.div`
  font-weight: bold;
  color: var(--colorFontSecondary);
`;

export const ButtonRegister = styled.button`
  width: 300px;
  background-color: var(--colorFontSecondary);
  border: none;
  margin: 20px 0 20px 0;
  padding: 10px;
  border-radius: 5px;
  font-size: 1.5em;
  color: white;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: 768px) {
    width: 50%;
    padding: 5px;

    &:hover {
      transform: scale(1);
    }
  }
`;

export const Link = styled.a``;
