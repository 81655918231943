import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 20px;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const FinishRaffle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--colorGreenTransparent);
  border: 3px solid var(--colorGrey);
  border-radius: 10px;
  z-index: 1;
`;
export const FinishText = styled.p`
  margin: 0;
  padding: 30px;
  text-align: center;
  font-weight: bold;
`;

export const ContainerRate = styled.div`
  width: 500px;
  margin: 5px 5px -15px 5px;
  padding: 10px;
  border-top: 3px solid var(--colorFontSecondary);
  border-left: 3px solid var(--colorFontSecondary);
  border-right: 3px solid var(--colorFontSecondary);
  border-radius: 10px 10px 0 0;
  background-color: #d81f1f27;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0 0 -15px 0;
  }
`;
export const Rate = styled.div`
  font-weight: bold;
  color: var(--colorFontSecondary);

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;
export const TextInfo = styled.div`
  color: var(--colorGrey);
  span {
    font-weight: bold;
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;
export const WhatsApp = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin 2px auto;
    color: #fff;
    background-color: green;
    padding: 2px 10px;
    border-radius: 5px;
    text-decoration: none;

    &:hover {
      color: #fff;
    }

    svg {
      margin-right: 5px;
    }
  `;

export const ContainerRaffle = styled.div`
  background-color: var(--colorLightGrey);
  border: 3px solid var(--colorFontPrimary);
  margin: 5px;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 500px;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
    margin: 10px 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const CopyLink = styled.div`
  position: absolute;
  background-color: var(--colorGreenTransparent);
  padding: 6px 10px;
  border-radius: 10px;
  border: 3px solid var(--colorGrey);
  z-index: 1;
`;

export const RaffleTitle = styled.h2`
  font-size: 1.2em;
  margin-bottom: 8px;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const ContainerBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-right: 10px;

  @media (max-width: 768px) {
    margin: 0;
    width: 100%;
  }
`;

export const RaffleDate = styled.div``;

export const RaffleValue = styled.div``;

export const ContainerInfo = styled.div`
  @media (max-width: 768px) {
    display: flex;
    width: 100%;
    justify-content: space-around;
    font-size: 0.8rem;
  }
`;

export const ContainerSettingsRaffle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button:first-child {
    font-weight: bold;
    background: var(--colorFontPrimary);
  }
`;

export const ButtonCloseRaffle = styled.button`
  background-color: var(--colorFontPrimary);
  color: #fff;
  padding: 7px;
  border-radius: 10px;
  cursor: pointer;
  font-weight: bold;
  margin: 5px;

  @media (max-width: 768px) {
    padding: 5px;
  }
`;

export const ContainerFinishRaffle = styled.div``;

export const InputCloseRaffle = styled.textarea`
  padding: 5px;
  border: 3px solid var(--colorFontPrimary);
  width: 100%;
  border-radius: 10px;
  font-size: 0.8em;
`;

export const TextWarning = styled.p`
  font-size: 0.9em;
  color: red;
  margin: 0;
`;

export const ButtonFinishRaffle = styled.button`
  background-color: red;
  color: #fff;
  padding: 7px;
  border-radius: 10px;
  cursor: pointer;
  font-weight: bold;
  margin: 5px;

  @media (max-width: 768px) {
    padding: 5px;
  }
`;

export const ContainerButtonsImage = styled.div``;

export const ContainerButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
`;

export const ButtonEditRaffle = styled.button`
  background-color: var(--colorFontSecondary);
  border: 1px solid var(--colorGrey);
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  font-weight: bold;
  padding: 3px 0;
  width: 47%;

  @media (max-width: 768px) {
    padding: 5px;
  }
`;

export const ButtonSeeRaffle = styled.a`
  text-decoration: none;
  border: 1px solid var(--colorGrey);
  background-color: var(--colorFontSecondary);
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  font-weight: bold;
  padding: 3px 0;
  width: 47%;
  text-align: center;

  &:hover {
    color: #fff;
  }

  @media (max-width: 768px) {
    padding: 5px;
  }
`;

export const ButtonCopy = styled.button`
  margin-top: 5px;
  background-color: var(--colorFontSecondary);
  border: 1px solid var(--colorGrey);
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  font-weight: bold;
  padding: 3px 5px;

  @media (max-width: 768px) {
    padding: 5px;
  }
`;

export const ContainerImage = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
`;

export const RaffleImage = styled.img`
  width: 200px;
  border-radius: 10px;

  @media (max-width: 768px) {
    width: 100px;
  }
`;
