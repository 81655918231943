import React, { useState } from 'react';

import { formatPrice } from '../../../helpers/FunctionsCalc';
import { Raffle } from '../../../types/Raffle';
import * as C from './styles';

type Props = {
  raffle: Raffle;
  finishRaffle: (raffle: Raffle, textWinner: string) => void;
  deleteRaffle: (raffle: Raffle) => void;
  deleting: boolean;
  setDeleting: React.Dispatch<React.SetStateAction<boolean>>;
};

function RafflesInactive (props: Props) {
  const { raffle, finishRaffle, deleteRaffle, deleting, setDeleting } = props;

  const [showInputWinner, setShowInputWinner] = useState<boolean>(false);
  const [nameWinner, setNameWinner] = useState<string>('');
  const [warningNameWinner, setWarningNameWinner] = useState<boolean>(false);
  const [textInfo, setTextInfo] = useState('');

  const handleUpdateWinner = () => {
    if (nameWinner === '') {
      setWarningNameWinner(true);
      return;
    }

    finishRaffle(raffle, nameWinner);
  };

  return (
    <C.Container>
      {deleting && (
        <C.Deleting>
          <C.DeletingText>{textInfo}</C.DeletingText>
        </C.Deleting>
      )}
      <C.ContainerRaffle>
        <C.ContainerBody>
          <C.RaffleTitle>{raffle.name}</C.RaffleTitle>
          <C.ContainerInfo>
            <C.RaffleDate>Rifa encerrada</C.RaffleDate>
            <C.RaffleValue>Valor: {formatPrice(raffle.price)}</C.RaffleValue>
          </C.ContainerInfo>
          <C.ContainerCloseRaffle>
            <C.ContainerButtons>
              <C.ButtonCloseRaffle
                onClick={() => setShowInputWinner(!showInputWinner)}
              >
                Alterar ganhador
              </C.ButtonCloseRaffle>
              <C.ButtonDelete
                onClick={() => {
                  setDeleting(true);
                  setTextInfo('Deletando rifa...');
                  deleteRaffle(raffle);
                }}
              >
                Apagar Rifa
              </C.ButtonDelete>
              <C.ButtonSeeRaffle href={raffle.url} target={'_blank'}>
                Ver Rifa
              </C.ButtonSeeRaffle>
            </C.ContainerButtons>
            {showInputWinner && (
              <C.ContainerFinishRaffle>
                <C.InputCloseRaffle
                  value={nameWinner}
                  placeholder="Escreva tudo o que for relevante para identificar o ganhador(es)"
                  rows={4}
                  cols={35}
                  onChange={(e) => setNameWinner(e.target.value)}
                />
                {warningNameWinner && (
                  <C.TextWarning>Digite o novo nome(es)</C.TextWarning>
                )}
                <C.ButtonChangeName
                  onClick={() => {
                    setDeleting(true);
                    setTextInfo('Alterando nome do ganhador...');
                    handleUpdateWinner();
                  }}
                >
                  Alterar
                </C.ButtonChangeName>
              </C.ContainerFinishRaffle>
            )}
          </C.ContainerCloseRaffle>
        </C.ContainerBody>
        <C.ContainerImage>
          <C.RaffleImage src={raffle.images[0].url} />
        </C.ContainerImage>
      </C.ContainerRaffle>
    </C.Container>
  );
}

export default RafflesInactive;
