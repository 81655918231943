import React from 'react';
import { BsWhatsapp } from 'react-icons/bs';

import { formatPhone, formatPrice, getExpirationDay } from '../../../helpers/FunctionsCalc';
import { Raffle, User } from '../../../types/Raffle';
import * as C from './styles';

type Props = {
  raffle: Raffle;
  paymentValue: number;
  user: User;
  handleClosePayment: () => void;
  selectedTickets: number[];
};

function PaymentDetails (props: Props) {
  const { raffle, paymentValue, user, handleClosePayment, selectedTickets } = props;

  const numbers = selectedTickets.map((item) => {
    return item.toString().padStart(3, '0');
  });

  const numbersSelectedWhatsApp = numbers.join('%20');

  const apiWhatsapp = `https://api.whatsapp.com/send?phone=55${user.phone}&text=Este%20é%20o%20meu%20comprovante%20da%20rifa%20${raffle.name}.%20Meus%20números%20são:%20${numbersSelectedWhatsApp}`;

  return (
    <C.Container>
      <C.ContainerSuccess>
        <C.SuccessText>
          Voce tem até o dia <span>{getExpirationDay()}</span> para efetuar o
          pagamento via PIX e enviar comprovante para{' '}
          <span>
            <BsWhatsapp /> {formatPhone(user.phone)}
          </span>
          .
        </C.SuccessText>
        <C.ContainerTicketNumber>
                  <C.TicketTitle>Números escolhidos</C.TicketTitle>
                  <C.Ticket>
                    {selectedTickets.map((ticket) => (
                      <C.TicketNumber key={ticket}>{String(ticket).padStart(3, '0')} </C.TicketNumber>
                    ))}
                  </C.Ticket>
                </C.ContainerTicketNumber>
        <C.PixDetails>
          <C.TitlePixDetails>Dados do PIX</C.TitlePixDetails>
          <C.PixValue>
            Valor:{' '}
            <span>
              {formatPrice(paymentValue)}
            </span>
          </C.PixValue>
          <C.PixKey>
            Pix tipo: <span>{raffle.pixMethod}</span>{' '}
          </C.PixKey>
          <C.Pix>
            Pix: <span>{raffle.pix}</span>
          </C.Pix>
          <C.pixName>
            Nome da conta: <span>{raffle.pixName}</span>
          </C.pixName>
        </C.PixDetails>
        <C.WhatsappSupport href={apiWhatsapp} target="_blank">
          <BsWhatsapp />
          Clique para enviar o comprovante
        </C.WhatsappSupport>
        <C.SendLater onClick={handleClosePayment}>
          Enviar comprovante depois
        </C.SendLater>

      </C.ContainerSuccess>
    </C.Container>
  );
}

export default PaymentDetails;
