import styled from 'styled-components';

export const Container = styled.div``;

export const DescriptionMain = styled.div`
  margin: 200px 10vw 50px 10vw;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    margin: 140px 2vw 0 2vw;
  }
`;
export const ContainerButtonCreateRifa = styled.div`
  font-size: 2em;
  font-weight: bold;
`;

export const SpanRed = styled.span`
  color: var(--colorFontSecondary);
  font-weight: bold;
`;

export const SpanGreen = styled.span`
  color: var(--colorFontPrimary);
  font-weight: bold;
`;

export const DarkGreen = styled.span`
  color: var(--colorFontThird);
`;

export const Text = styled.div`
  font-size: 70px;
  font-weight: bold;

  span {
    font-size: 80px;
  }

  @media (max-width: 768px) {
    font-size: 40px;

    span {
      font-size: 40px;
    }
  }
`;

export const TextInfo = styled.div`
  margin: 30px;
  font-size: 1.2em;
  line-height: 40px;
  color: #444;

  span {
    font-weight: bold;
  }

  @media (max-width: 768px) {
    font-size: 1em;
    line-height: 20px;
    margin: 20px 2vw;
  }
`;

/// //////////Page 404 ///////////

export const ContainerNotFound = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const NotFoundBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const NotFoundImage = styled.img`
  width: 300px;
`;
export const NotFoundText = styled.div`
  font-size: 1.2em;
  margin: 10px 0;
`;
export const NotFoundLinkHome = styled.a`
  cursor: pointer;
`;
