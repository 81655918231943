import React, { FormEvent, useEffect, useState } from 'react';

import {
  deletePhoto,
  getAll,
  insert
} from '../../../helpers/servicesImages/photos';
import { Photo } from '../../../types/Raffle';
import PhotoItem from '../PhotoItem';
import * as C from './styles';

type Props = {
  arrayImages: Photo[];
  setArrayImages: React.Dispatch<React.SetStateAction<Photo[]>>;
  raffleId: string;
};

function UploadImage (props: Props) {
  const { arrayImages, setArrayImages, raffleId } = props;

  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);

  const folder = raffleId;

  useEffect(() => {
    const getPhotos = async () => {
      setLoading(true);
      const photos = await getAll(folder);
      setArrayImages(photos);

      setLoading(false);
    };

    getPhotos();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);

    const file = formData.get('image') as File;

    if (file && file.size > 0) {
      setUploading(true);

      const result = await insert(file, folder);
      setUploading(false);

      if (result instanceof Error) {
        alert(`${result.name} - ${result.message}`);
      } else {
        const newPhotoList = [...arrayImages];
        newPhotoList.push(result);
        setArrayImages(newPhotoList);
      }
    }
  };

  const deleteImage = async (img: string) => {
    const statusDeleted = await deletePhoto(img, folder);
    if (statusDeleted.deleted) {
      const newPhotos: Photo[] = [];

      arrayImages.forEach((photos) => {
        if (photos.name !== img) {
          newPhotos.push(photos);
        }
      });
      setArrayImages(newPhotos);
    } else {
      alert('Houve um ERRO, \nNão fo possivel efetuar a remoção.');
    }
  };

  return (
    <C.Container>
      <C.Area>
        <C.Header>Galeria de Fotos</C.Header>
        <C.HeaderText>
          * Escolha um arquivo e clique em <span>enviar imagem</span>
        </C.HeaderText>
        <C.HeaderText>* Envie até 5 imagens</C.HeaderText>
        <C.Body>
          {arrayImages.length < 5 && (
            <C.UploadForm method={'POST'} onSubmit={handleFormSubmit} onChange={handleFormSubmit}>
              <C.InputImage
                type={'file'}
                name="image"
              />
              {/* <C.InputSend
                type={'submit'}
                name="Enviar"
                value={'Enviar imagem'}
              /> */}
              {uploading && 'Enviando...'}
            </C.UploadForm>
          )}

          {loading && (
            <C.ScreenWarning>
              <C.ScreenWarningEmoji className="emoji">📲</C.ScreenWarningEmoji>
              <C.ScreenWarningText>Carregando...</C.ScreenWarningText>
            </C.ScreenWarning>
          )}

          {!loading && arrayImages.length > 0 && (
            <C.PhotoList>
              {arrayImages.map((item, index) => (
                <PhotoItem
                  key={index}
                  url={item.url}
                  name={item.name}
                  deleteImage={deleteImage}
                />
              ))}
            </C.PhotoList>
          )}

          {!loading && arrayImages.length === 0 && (
            <C.ScreenWarning>
              <div className="emoji">😕</div>
              <C.Text>Não há fotos cadastradas</C.Text>
            </C.ScreenWarning>
          )}
        </C.Body>
      </C.Area>
    </C.Container>
  );
}

export default UploadImage;
