import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

export const Image = styled.img`
  width: 200px;
`;

export const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
  }
`;

export const Text = styled.p`
  font-size: 1.2em;
  color: var(--colorGrey);

  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

export const Form = styled.div`
  margin-top: 10px;
  width: 500px;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const FormInput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
  border: 3px solid var(--colorFontPrimary);
  border-radius: 5px;
  width: 100%;

  @media (max-width: 768px) {
    margin: 5px 0;
  }
`;

export const SVGInput = styled.div`
  font-size: 1.2em;
  padding: 0 5px;
  color: var(--colorFontSecondary);
`;

export const Input = styled.input`
  border: 0;
  padding: 5px;
  font-size: 1.1em;
  width: 100%;
  border-left: 1px solid var(--colorFontPrimary);
`;

export const ErrorMsg = styled.p`
  border-radius: 5px;
  padding: 5px 10px;
  color: #fff;
  background: var(--colorError);
`;

export const MsgSendEmail = styled.p`
  border-radius: 5px;
  padding: 5px 10px;
  color: #fff;
  background: var(--colorFontPrimary);
`;

export const ButtonRegister = styled.button`
  width: 300px;
  background-color: var(--colorFontSecondary);
  border: none;
  margin: 20px 0 20px 0;
  padding: 10px;
  border-radius: 5px;
  font-size: 1.5em;
  color: white;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
`;

export const Link = styled.a``;
export const LinkRegister = styled.p`
  cursor: pointer;

  span {
    font-weight: bold;
  }
`;

export const NoPassword = styled.p`
  cursor: pointer;

  margin-top: 10px;
  span {
    font-weight: bold;
  }
`;
