import React from 'react';

import { colorStatusTicket } from '../../../helpers/FunctionsCalc';
import { StatusTicketDB, TicketType } from '../../../types/Raffle';
import * as C from './styles';

type Props = {
  tickets: TicketType[];
  setSelectedTickets: React.Dispatch<React.SetStateAction<number[]>>;
  selectedTickets: number[];
  allTickets: TicketType[];
  paid: boolean;
};

function Ticket (props: Props) {
  const { tickets, setSelectedTickets, selectedTickets, paid } = props;

  const handleSelectedTicket = (ticket: TicketType): void => {
    if (ticket.status !== StatusTicketDB.DISPONIBLE) return;
    if (!paid) {
      alert('É preciso pagar a taxa de publicação para acessar os Bilhetes!');
      return;
    }
    if (selectedTickets.includes(ticket.number)) {
      setSelectedTickets(selectedTickets.filter((ticketnumber) => ticketnumber !== ticket.number));
    } else {
      setSelectedTickets([...selectedTickets, ticket.number]);
    }
  };

  return (
    <C.Container>
      {tickets.map((ticket, index) => (
        <C.Ticket
        background={colorStatusTicket(ticket.status)}
        selected={selectedTickets.includes(ticket.number)}
          key={index}
          onClick={() => handleSelectedTicket(ticket)}
        >
          <C.TicketNumber>
            {String(ticket.number).padStart(3, '0')}
          </C.TicketNumber>
        </C.Ticket>
      ))}
    </C.Container>
  );
}

export default Ticket;
