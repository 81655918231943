import styled from 'styled-components';

export const Container = styled.div`
  width: 800px;

  @media (max-width: 768px) {
    width: 100%;
  }

`;

export const Area = styled.div``;

export const Body = styled.div`
  background-color: var(--colorGrey);
  padding: 10px;
  border-radius: 10px;
`;

export const Header = styled.div`
  font-weight: bold;
`;

export const HeaderText = styled.div`

span {
  font-weight: bold;
}
`;

export const UploadForm = styled.form`
  background-color: var(--colorLightGrey);
  width: fit-content;
  margin: 0 auto 5px;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const InputImage = styled.input`
  
`;

export const InputSend = styled.input`
  background-color: var(--colorFontPrimary);
  border-radius: 5px ;
  padding: 0 5px;
  margin-left: 10px;
  max-width: 135px;

  @media (max-width: 768px) {
    padding: 0;
    margin: 5px 0 0;
  }
`;

export const ScreenWarning = styled.div`
  text-align: center;

  .emoji {
    font-size: 40px;
  
  }
`;

export const ScreenWarningEmoji = styled.div``;

export const ScreenWarningText = styled.div``;

export const PhotoList = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

export const Text = styled.div`
  font-weight: bold;
  color: #fff;
`;
