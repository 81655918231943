import { User } from 'firebase/auth';
import React, { useState } from 'react';
import { BsWhatsapp } from 'react-icons/bs';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

import {
  cancelReserveDB,
  confirmPaymentTicket
} from '../../../db/api/editDatabase';
import { RaffleDbKey, StatusTicketDB, TicketType } from '../../../types/Raffle';
import * as C from './styles';

type Props = {
  ticket: TicketType;
  userFirebase: User | null;
  raffleSelected: RaffleDbKey | null;
  updateTickets: () => void;
  updateTiketsPayment: (ticketNumber: number) => Promise<void>;
  updateTiketsCalcelReserve: (ticketNumber: number) => Promise<void>;
};

function DropdownSales (props: Props) {
  const { ticket, userFirebase, raffleSelected, updateTiketsPayment, updateTiketsCalcelReserve } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showCancelReserve, setShowCancelReserve] = useState(false);

  const buyerName = ticket.buyerName.split(' ')[0];

  const apiWhatsappClient = `https://api.whatsapp.com/send?phone=55${ticket.buyerPhone}&text=Ol%C3%A1%2C%20${buyerName}%20tudo%20bem?`;

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const confirmPayment = async () => {
    const token = (await userFirebase?.getIdToken()) as string;
    const email = userFirebase?.email as string;
    const docRaffle = raffleSelected?.doc as string;
    const ticketNumber = String(ticket.number);

    try {
      await confirmPaymentTicket(email, token, docRaffle, ticketNumber);
      // updateTickets();
      updateTiketsPayment(Number(ticketNumber));
    } catch (error) {
      alert('Erro ao confirmar pagamento');
    }
  };

  const cancelReserve = async () => {
    const token = (await userFirebase?.getIdToken()) as string;
    const email = userFirebase?.email as string;
    const docRaffle = raffleSelected?.doc as string;
    const ticketNumber = String(ticket.number);

    try {
      await cancelReserveDB(email, token, docRaffle, ticketNumber);
      updateTiketsCalcelReserve(Number(ticketNumber));
      // updateTickets();
    } catch (error) {
      alert(
        `Erro ao cancelar ${
          ticket.status === StatusTicketDB.RESERVED ? 'reserva' : 'venda'
        }`
      );
    }
  };

  return (
    <C.Container>
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret size="sm"></DropdownToggle>
        <DropdownMenu>
          <C.Link href={apiWhatsappClient} target="_blank">
            <DropdownItem>
              <BsWhatsapp /> Falar com comprador
            </DropdownItem>
          </C.Link>
          {ticket.status === StatusTicketDB.RESERVED && (
            <DropdownItem
              onClick={() => {
                confirmPayment();
              }}
            >
              Confirmar pagamento
            </DropdownItem>
          )}
          <C.CancelReserve onClick={() => setShowCancelReserve(!showCancelReserve)}>
            Desfazer{' '}
            {ticket.status === StatusTicketDB.RESERVED
              ? 'reserva'
              : 'pagamento'}
          </C.CancelReserve>
          {showCancelReserve && <DropdownItem onClick={() => cancelReserve()}>
            Confirmar exclusão
          </DropdownItem>}
        </DropdownMenu>
      </Dropdown>
    </C.Container>
  );
}

export default DropdownSales;
