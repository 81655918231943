import { useNavigate } from 'react-router-dom';
import React from 'react';

import * as C from './styles';

function Index () {

  const navigate = useNavigate();
  return <C.Container onClick={() => navigate('/login')} >Criar Rifa</C.Container>;
}

export default Index;
