import styled from 'styled-components';

export const Container = styled.div`
  border: 3px solid var(--colorFontSecondary);
  border-radius: 5px;
  padding: 40px;
  max-width: 1000px;
  margin: 20px auto;

  @media (max-width: 768px) {
    margin: 5px;
  padding: 10px;

  }
`;
export const Title = styled.h1`
  color: var(--colorFontPrimary);
  text-align: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const Update = styled.p`
  text-align: right;
  margin-bottom: 20px;
`;

export const SubTitle = styled.h2`
  margin: 20px 0;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

export const Paragraph = styled.p`
  margin: 15px 0;
  text-align: justify;

  span {
    font-weight: bold;
  }

  @media (max-width: 768px) {
    font-size: 0.8em;
  }
`;

export const Item = styled.p`
  text-indent: 40px;
  margin: 15px 0 15px 40px;
  text-align: justify;
`;
