/* eslint-disable no-unused-expressions */
import { StatusTicketDB, TicketType } from '../types/Raffle';

export const createTickets = (raffleId: string): TicketType[] => {
  const tickets: TicketType[] = [
    {
      number: 0,
      status: StatusTicketDB.DISPONIBLE,
      buyerName: '',
      buyerEmail: '',
      buyerPhone: '',
      date: ''
    }
  ];

  return tickets;
};

export const validateFields = (
  nameManager: string,
  emailManager: string,
  name: string,
  numberOfTickets: undefined | number,
  valueField: undefined | number,
  phone: string,
  date: string,
  pixMethod: string,
  pix: string,
  pixName: string,
  description: string
): string => {
  if (nameManager.trim() === '') return 'Digite o nome do administrador da rifa!';
  if (emailManager.trim() === '') return 'Digite um email de contato!';
  if (name === '') return 'Digite o nome da rifa!';
  if (numberOfTickets === undefined || numberOfTickets <= 1) { return 'Digite a quantidade de bilhetes!'; }
  if (numberOfTickets > 1000) { return 'Digite a quantidade de bilhetes menor ou igual a 1000!'; }
  if (valueField === undefined || valueField === 0) { return 'Digite o valor dos bilhetes!'; }
  if (phone === '' || phone.length < 11) { return 'Digite um telefone celular válido!'; }
  if (date === '') { return "Selecione uma data para o sorteio! \n ou marque a opção 'Ao encerrar os bilhetes'!"; }
  if (pixMethod === '') return 'Selecione um tipo de pix!';
  if (pix === '') return 'Digite um pix válido';
  if (pixName === '') return 'Digite o nome que aparecerá no pix';
  if (description === '') return 'Digite uma descrição para o sorteio';
  return '';
};

export const validateFieldsEdit = (
  nameManager: string,
  name: string,
  phone: string,
  date: string,
  pixMethod: string,
  pix: string,  
  pixName: string,
  description: string
): string => {
  if (nameManager.trim() === '') return 'Digite o nome do administrador da rifa!';
  if (name === '') return 'Digite o nome da rifa!';
  if (phone === '' || phone.length < 11) { return 'Digite um telefone celular válido!'; }
  if (date === '') { return "Selecione uma data para o sorteio! \n ou marque a opção 'Ao encerrar os bilhetes'!"; }
  if (pixMethod === '') return 'Selecione um tipo de pix!';
  if (pix === '') return 'Digite um pix válido';
  if (pixName === '') return 'Digite o nome que aparecerá no pix';
  if (description === '') return 'Digite uma descrição para o sorteio';
  return '';
};

export const checkValidity = (date: string): boolean => {
  if (!date) return false;
  const threDays = 259200000;
  const today = new Date().getTime();

  return today - threDays > Number(date);
};

export const getDocFirebase = (raffleId: string): string => {
  const document = raffleId.split('-')[0].slice(0, 6);

  return document;
};

export const formatDate = (data: Date | number): string => {
  const date = new Date(data);

  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

export const checkValidityCreate = (data: Date): boolean => {
  const date = new Date(data).getTime();
  if (!date) return false;
  const fourDays = 345600000;
  const today = new Date().getTime();

  return today - fourDays > Number(date);
};

export const checkValidityFinish = (date: string): boolean => {
  if (!date) return false;
  const thirtyDays = 2592000000;
  const today = new Date().getTime();

  return today - thirtyDays > Number(date);
};
