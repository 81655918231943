import { onAuthStateChanged } from "firebase/auth";
import { useEffect } from "react";
import { IoTicketOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

import Benefits from "../components/Main/Benefits";
import ButtonCreateRifa from "../components/Main/ButtonCreateRifa";
import Footer from "../components/Main/Footer";
import Header from "../components/Main/Header";
import PlatformInfo from "../components/Main/PlatformInfo";
import Questions from "../components/Main/Questions";
import WhatsappContact from "../components/Main/WhatsappContact";
import { FormActions, useInfoContext } from "../contexts/userInfoContext";
import { auth } from "../db/firebase";
import * as C from "./styles";

const Home = () => {
  const { state, dispatch } = useInfoContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (state.infoUser === null) {
      onAuthStateChanged(auth, async (usuarioFirebase) => {
        if (usuarioFirebase !== null) {
          dispatch({ type: FormActions.setInfoUser, payload: usuarioFirebase });
          navigate("/admin");
        } 
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <C.Container>
      <Header />
      <C.DescriptionMain>
        <C.Text>
          A maneira mais <C.SpanGreen>rápida</C.SpanGreen> e{" "}
          <C.SpanRed>segura</C.SpanRed> de criar uma rifa.{" "}
        </C.Text>
        <C.TextInfo>
          Crie uma rifa em <span>MINUTOS</span>, nossa plataforma é rápida,
          completa, simples e segura para você e seus clientes, aqui{" "}
          <span>você</span> dita as regras, abandone de uma vez a rifa de papel
          e venha ganhar dinheiro de forma rápida com a{" "}
          <C.SpanGreen>Loja</C.SpanGreen>
          <C.DarkGreen>da</C.DarkGreen>
          <C.SpanRed>Rifa</C.SpanRed>
          <C.SpanGreen>
            <IoTicketOutline />
          </C.SpanGreen>
          .
        </C.TextInfo>
        <C.ContainerButtonCreateRifa>
          <ButtonCreateRifa />
        </C.ContainerButtonCreateRifa>
      </C.DescriptionMain>
      <Benefits />
      <PlatformInfo />
      <Questions />
      <WhatsappContact />
      <Footer />
    </C.Container>
  );
};

export default Home;
