import { User } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { BsWhatsapp } from "react-icons/bs";

import { getRafflesUserManagerDB, getUsersDB } from "../../db/api/apiServer";
import { deleteRaffleDBManager } from "../../db/api/deleteDatabase";
import {
  updatePaidRaffleDB,
  updateUnpaidRaffleDB,
} from "../../db/api/editDatabase";
import { formatPrice } from "../../helpers/FunctionsCalc";
import {
  checkValidityCreate,
  checkValidityFinish,
  formatDate,
  getDocFirebase,
} from "../../helpers/raffle";
import * as C from "./styles";
import { Raffle } from "../../types/Raffle";
import { useInfoContext } from "../../contexts/userInfoContext";

function Manager() {
  const administrativeUser = "darlangaguiar@gmail.com";

  const [rafflesUserSelected, setRafflesUserSelected] = useState<Raffle[]>([]);
  const [usersEmails, setUsersEmails] = useState<string[]>([]);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [showButtonDelete, setShowButtonDelete] = useState<string>("");

  const { state } = useInfoContext();

  useEffect(() => {
    const getUsers = async () => {
      if (state.infoUser?.email) {
        setCurrentUser(state.infoUser);
        const token = await state.infoUser.getIdToken();
        const email = state.infoUser.email;
        try {
          const userResult = await getUsersDB(email, token);
          setUsersEmails(userResult);
        } catch (error) {
          alert("voce nao tem permissao para acessar essa pagina");

        }
      }
    };
    getUsers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetRaffleUserSelected = async (userEmailSelected: string) => {
    const token = (await currentUser?.getIdToken()) as string;
    const email = currentUser?.email as string;

    try {
      const result = await getRafflesUserManagerDB(
        email,
        token,
        userEmailSelected
      );
      setRafflesUserSelected(result);
    } catch (error) {
      alert(
        "você nao tem permissao para acessar essa pagina / problema no servidor"
      );
    }
  };

  const handlePaidRaffle = async (raffle: Raffle) => {
    const token = (await currentUser?.getIdToken()) as string;
    const email = currentUser?.email as string;
    const docRaffle = getDocFirebase(raffle.id);
    const userRefEmail = raffle.userEmail;

    try {
      await updatePaidRaffleDB(email, token, docRaffle, userRefEmail);
      await handleGetRaffleUserSelected(raffle.userEmail);
    } catch (error) {}
  };

  const handleUnpaidRaffle = async (raffle: Raffle) => {
    const token = (await currentUser?.getIdToken()) as string;
    const email = currentUser?.email as string;
    const docRaffle = getDocFirebase(raffle.id);
    const userRefEmail = raffle.userEmail;

    try {
      await updateUnpaidRaffleDB(email, token, docRaffle, userRefEmail);
      await handleGetRaffleUserSelected(raffle.userEmail);
    } catch (error) {}
  };

  const handleDeleteRaffle = async (raffle: Raffle) => {
    const token = (await currentUser?.getIdToken()) as string;
    const email = currentUser?.email as string;
    const docRaffle = getDocFirebase(raffle.id);
    const userRefEmail = raffle.userEmail;
    const images = raffle.images;
    const raffleId = raffle.id;

    try {
      await deleteRaffleDBManager(
        email,
        token,
        docRaffle,
        userRefEmail,
        images,
        raffleId
      );
      await handleGetRaffleUserSelected(raffle.userEmail);
    } catch (error) {}
  };

  return (
    <C.Container>
      {currentUser?.email === administrativeUser && (
        <C.ContainerBody>
          <C.ContainerUsers>
            <C.UsersTitle>Email dos usuários</C.UsersTitle>
            <C.ContainerUsersEmail>
              {usersEmails.map((user) => (
                <C.UserEmail
                  key={user}
                  onClick={(e) => {
                    handleGetRaffleUserSelected(e.currentTarget.innerText);
                  }}
                >
                  {user}
                </C.UserEmail>
              ))}
            </C.ContainerUsersEmail>
          </C.ContainerUsers>
          {rafflesUserSelected.length > 0 && (
            <C.ContainerRaffles>
              <C.RafflesTitle>Rifas do usuário</C.RafflesTitle>
              {rafflesUserSelected.map((raffle) => (
                <C.ContainerRaffle key={raffle.id}>
                  <C.ContainerInfoRaffle>
                    <C.RaffleUser>
                      <span>Email: </span>
                      {raffle.userEmail}
                    </C.RaffleUser>
                    <C.RaffleUser>
                      <span>Nome: </span>
                      {raffle.userName}
                    </C.RaffleUser>
                    <C.RafflePhone>
                      <span>Fone: </span>
                      {raffle.userPhone}
                    </C.RafflePhone>
                    <C.RaffleName>
                      <span>Titulo: </span>
                      {raffle.name}
                    </C.RaffleName>
                    <C.RafflePrice>
                      <span>Preço: </span> {formatPrice(raffle.price)}{" "}
                      <span>QTD: </span>
                      {raffle.quantityTickets}
                    </C.RafflePrice>
                    <C.RaffleDate
                      vanquished={
                        checkValidityCreate(raffle.creationDate) && !raffle.paid
                      }
                    >
                      <span>Criação: </span>
                      {formatDate(raffle.creationDate)}
                    </C.RaffleDate>
                    <C.RaffleFinished>
                      <span>Finalizada: </span>
                      {raffle.active ? "Não" : "Sim"}
                    </C.RaffleFinished>
                    {raffle.dateFinish && (
                      <C.RaffleFinishedDate
                        vanquished={checkValidityFinish(raffle.dateFinish)}
                      >
                        Data Finalização:{" "}
                        {formatDate(Number(raffle.dateFinish))}
                      </C.RaffleFinishedDate>
                    )}
                    <C.RafflePublication paid={raffle.paid}>
                      <span>Taxa: </span>
                      {formatPrice(raffle.publicationFee)}{" "}
                      <span>{raffle.paid ? "Pago" : "Pendente"}</span>
                    </C.RafflePublication>
                    <hr />
                  </C.ContainerInfoRaffle>
                  <C.ContainerButtons>
                    <C.ButtonWhats
                      href={`https://api.whatsapp.com/send?phone=55${raffle.userPhone}&text=Olá%20${raffle.userName}.`}
                    >
                      <BsWhatsapp /> usuário
                    </C.ButtonWhats>
                    <C.ButtonSeeRaffle href={raffle.url} target={"__blank"}>
                      Ver Rifa
                    </C.ButtonSeeRaffle>
                    <C.ButtonPaid onClick={() => handlePaidRaffle(raffle)}>
                      Marcar pago
                    </C.ButtonPaid>
                    <C.ButtonNoPaid onClick={() => handleUnpaidRaffle(raffle)}>
                      Marcar Pendente
                    </C.ButtonNoPaid>
                    <C.ButtonShowDelete
                      onClick={() => {
                        if (showButtonDelete) setShowButtonDelete("");
                        else setShowButtonDelete(raffle.id);
                      }}
                    >
                      Deletar
                    </C.ButtonShowDelete>
                    {showButtonDelete === raffle.id && (
                      <C.ButtonDelete
                        onClick={() => handleDeleteRaffle(raffle)}
                      >
                        Confirmar remoção
                      </C.ButtonDelete>
                    )}
                  </C.ContainerButtons>
                </C.ContainerRaffle>
              ))}
            </C.ContainerRaffles>
          )}
        </C.ContainerBody>
      )}
    </C.Container>
  );
}

export default Manager;
