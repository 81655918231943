import styled from 'styled-components';

export const Container = styled.div<{ visible: boolean }>`
  display: ${(props) => (props.visible ? 'block' : 'none')};
`;

export const ContainerMyRaffles = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 5px;
  }
`;
export const Title = styled.h1`
  color: var(--colorFontPrimary);
  font-size: 2.5em;

  @media (max-width: 768px) {
    font-size: 1.5em;
    padding: 10px;
  }
`;
export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-around;
    margin: 0 0 10px 0;
    font-size: 0.8em;
  }
`;
export const ButtonRafflesRunning = styled.button<{ selected: boolean }>`
  width: 200px;
  padding: 10px;
  margin: 20px;
  cursor: pointer;
  background-color: ${(props) =>
    props.selected ? 'var(--colorFontPrimary)' : '#fff'};
  font-weight: bold;
  color: var(--colorGrey);
  border-radius: 10px;
  border-bottom: 3px solid var(--colorGrey);

  &:hover {
    border-bottom: 1px solid var(--colorGrey);
  }

  @media (max-width: 768px) {
    width: 45%;
    margin: 0;
    padding: 5px;
  }
`;

export const ButtonRafflesClosed = styled.button<{ selected: boolean }>`
  width: 200px;
  padding: 10px;
  margin: 20px;
  cursor: pointer;
  background-color: ${(props) =>
    props.selected ? 'var(--colorFontPrimary)' : '#fff'};
  font-weight: bold;
  color: var(--colorGrey);
  border-radius: 10px;
  border-bottom: 3px solid var(--colorGrey);

  &:hover {
    border-bottom: 1px solid var(--colorGrey);
  }

  @media (max-width: 768px) {
    width: 45%;
    margin: 0;
    padding: 5px;
  }
`;
export const ContainerBody = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ContainerNoRaffles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const TextNoRaffles = styled.p`;
  svg {
    margin-top: 30px;
    font-size: 3em;
    color: var(--colorFontSecondary);

    @media (max-width: 768px) {
      font-size: 2em;
    }
  }
`;

export const ButtonCreateRaffle = styled.button`
  font-size: 1.5em;
  font-weight: bold;
  padding: 0 40px;
  margin: 20px 0;
  border-radius: 10px;
  background: var(--colorFontSecondary);
  color: #fff;
`;

export const ContainerRaffles = styled.div`
  width: 100%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
