import styled from 'styled-components';

export const Header = styled.div`
  background-color: rgba(255, 255, 255, 0.93);
  width: 100%;
  height: 80px;
  border-bottom: 4px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  position: fixed;
  top: 0;
  left: 0;
  font-size: 50px;

  @media (max-width: 768px) {
    height: 100px;
    flex-direction: column;
    justify-content: space-around;
    font-size: 35px;
  }
`;

export const ContainerLogo = styled.div`
  font-weight: bold;
  color: var(--colorFontPrimary);

  .da {
    color: var(--colorFontThird);
  }
  .rifa {
    color: var(--colorFontSecondary);
  }
`;

export const ContainerMenu = styled.div`
  @media (max-width: 768px) {
    display: flex;
    width: 100vw;
    justify-content: space-around;
  }
`;

export const Login = styled.a`
  border: 0;
  background: transparent;
  font-size: 30px;
  padding: 10px 20px;
  color: var(--colorFontPrimary);
  font-weight: bold;
  border-radius: 10px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background: var(--colorFontPrimary);
    color: var(--colorWhite);
  }

  @media (max-width: 768px) {
    padding: 10px;
    font-size: 17px;
    border: 1px solid var(--colorFontPrimary);
  }
`;

export const Register = styled.a`
  border: 0;
  background: transparent;
  font-size: 30px;
  padding: 10px 20px;
  color: var(--colorFontPrimary);
  font-weight: bold;
  border-radius: 10px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  text-decoration: none;

  span {
    color: var(--colorFontSecondary);
  }

  &:hover {
    background: var(--colorFontPrimary);
    color: var(--colorWhite);
  }

  @media (max-width: 768px) {
    padding: 10px;
    font-size: 17px;
    border: 1px solid var(--colorFontPrimary);
  }
`;

export const Sidebar = styled.div``;
