import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ContainerFilter = styled.div`
  width: 80vw;
  border: 3px solid var(--colorFontPrimary);
  border-radius: 10px;

  @media (max-width: 768px) {
    width: 100vw;
  }

`;

export const Title = styled.div`
  margin: -2px -2px;
  padding: 10px 20px;
  color: #fff;
  background-color: var(--colorFontPrimary);
  border-radius: 5px 5px 0 0;
  font-size: 1.5rem;
  font-weight: bold;
`;

export const ContainerFilterPhone = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const TitleDesc = styled.p`
  text-align: center;
  font-weight: bold;
  color: var(--colorGrey);
  margin: 0;
`;

export const Filter = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const FilterPhone = styled.div`
  border: 3px solid var(--colorFontPrimary);
  display: flex;
  border-radius: 10px;
  padding: 3px;

  input {
    border: none;
  }
`;

export const TextFilter = styled.p`
  margin: 0;
  font-weight: bold;
  color: var(--colorGrey);
  margin-right: 5px;

  @media (max-width: 768px) {
    margin: 5px 0 -5px 0;
  }
`;

export const FilterButton = styled.button`
  border: 0;
  background-color: transparent;
  border-left: 1px solid var(--colorFontPrimary);

  svg {
    color: var(--colorFontPrimary);
    font-size: 1.4em;
    margin-left: 10px;
  }
`;

export const ContainerFilterOptions = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
`;

export const PaidTickets = styled.div`
  border-radius: 10px;
  background-color: var(--colorFontPrimary);
  padding: 10px 15px;
  width: 20%;
  margin: 5px;
  font-size: 1.2em;
  font-weight: bold;
  color: var(--colorGrey);
  border-bottom: 3px solid var(--colorGrey);
  cursor: pointer;

  @media (max-width: 768px) {
    width: 45%;
    font-size: 1.1em;
  }
`;

export const ReservedTickets = styled.div`
  border-radius: 10px;
  background-color: var(--colorFontSecondary);
  padding: 10px 15px;
  width: 20%;
  margin: 5px;
  font-size: 1.2em;
  font-weight: bold;
  color: var(--colorGrey);
  border-bottom: 3px solid var(--colorGrey);
  cursor: pointer;

  @media (max-width: 768px) {
    width: 45%;
    font-size: 1.1em;
  }
`;

export const AvaibleTickets = styled.div`
  border-radius: 10px;
  background-color: #fff;
  padding: 10px 15px;
  width: 20%;
  margin: 5px;
  font-size: 1.2em;
  font-weight: bold;
  color: var(--colorGrey);
  border: 1px solid var(--colorGrey);
  border-bottom: 3px solid var(--colorGrey);
  cursor: pointer;

  @media (max-width: 768px) {
    width: 45%;
    font-size: 1.1em;
  }
`;

export const AllTickets = styled.div`
  border-radius: 10px;
  background-color: var(--colorGrey);
  padding: 10px 15px;
  width: 20%;
  margin: 5px;
  font-size: 1.2em;
  font-weight: bold;
  color: #fff;
  border: 1px solid #000;
  border-bottom: 3px solid #000;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 45%;
    font-size: 1.1em;
  }
`;

export const ContainerTickets = styled.div``;

export const NoTickets = styled.div`
  margin-top: 50px;
  color: var(--colorFontPrimary);
  font-size: 2em;
  font-weight: bold;

  svg {
    color: var(--colorFontSecondary);
  }

  @media (max-width: 768px) {
    margin-top: 30px;
    font-size: 1.5em;
  }
`;
