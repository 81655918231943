import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./db/firebase";

import Home from "./pages/Home";
import Login from "./pages/Login";
import { useInfoContext, FormActions } from "./contexts/userInfoContext";
import Admin from "./pages/Admin";
import Register from "./pages/Register";
import Terms from "./pages/Terms";
import Rifa from "./pages/Rifa";
import Manager from "./pages/Manager";

function Router() {
  const { state, dispatch } = useInfoContext();

  type Props = {
    children: JSX.Element;
  };

  const Private = ({ children }: Props) => {
    const navigate = useNavigate();

    if (state.infoUser === null) {
      onAuthStateChanged(auth, async (usuarioFirebase) => {
        if (usuarioFirebase !== null) {
          dispatch({ type: FormActions.setInfoUser, payload: usuarioFirebase });
        } else {
          navigate("/login");
        }
      });
    }

    return state.infoUser?.email ? children : <></>;
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/rifa/:userRaffle/:docRaffle" element={<Rifa />} />
        <Route
          path="/admin"
          element={
            <Private>
              <Admin />
            </Private>
          }
        />
        <Route
          path="/manager"
          element={
            <Private>
              <Manager />
            </Private>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
