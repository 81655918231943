import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 70px;
  border-bottom: 3px solid var(--colorFontPrimary);
  padding: 5px;

  @media (max-width: 768px) {
    height: 60px;
  }
`;

export const ContainerImage = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
`;

export const TextHeader = styled.p`
  margin: -4px 0 0 0;
  font-size: 0.8rem;
  color: var(--colorFontPrimary);

  span {
    color: var(--colorFontSecondary);
  }
`;

export const Image = styled.img`
  margin-left: 20px;
  width: 150px;

  @media (max-width: 768px) {
    margin-left: 5px;
    width: 100px;
  }
`;

export const ContainerRate = styled.div`
  width: 800px;
  margin: 5px 5px -15px 5px;
  padding: 10px;
  border: 3px solid var(--colorFontSecondary);
  border-radius: 10px;
  background-color: #d81f1f27;
  display:flex ;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  @media (max-width: 768px) {
    width: 100%;
    margin: 0 0 -15px 0;
  }
`;
export const Rate = styled.div`
  font-weight: bold;
  color: var(--colorFontSecondary);

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;
export const TextInfo = styled.div`
  color: var(--colorGrey);
  span {
    font-weight: bold;
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;
export const WhatsApp = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin 2px auto;
  color: #fff;
  background-color: green;
  padding: 2px 10px;
  border-radius: 5px;
  text-decoration: none;

  &:hover {
    color: #fff;
  }

  svg {
    margin-right: 5px;
  }
`;

export const RaffleName = styled.h1`
  margin: 40px 0 0 0;
  color: var(--colorFontPrimary);
  font-weight: bold;

  @media (max-width: 768px) {
    margin: 18px 0 0 0;
  }
`;

export const ContainerCarrousel = styled.div`
  max-width: 500px;
  height: 350px;
  overflow: hidden;

  @media (max-width: 768px) {
    max-width: 100vw;
    height: 250px;
  }
`;

export const ImageCarousel = styled.img`
  max-width: 500px;
  max-height: 350px;
  border-radius: 10px;

  @media (max-width: 768px) {
    max-width: 100vw;
    max-height: 250px;
  }
`;

export const ContainerDescription = styled.div`
  width: 80vw;
  border: 3px solid var(--colorFontPrimary);
  border-radius: 10px;
  margin-top: 10px;

  @media (max-width: 768px) {
    width: 100vw;
  }
`;

export const TitleDescription = styled.div`
  margin: -2px -2px;
  padding: 10px 20px;
  color: #fff;
  background-color: var(--colorFontPrimary);
  border-radius: 10px 10px 0 0;
  font-size: 1.5rem;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

export const RaffleDescription = styled.div`
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  font-weight: bold;

  span {
    text-align: center;
  }
`;

export const ParagraphDescription = styled.p`
  margin: 0;
`;

export const RaffleDetails = styled.div`
  border-top: 1px solid var(--colorFontPrimary);
  padding: 10px;
  display: flex;
  justify-content: space-around;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ContainerOrganizer = styled.div``;

export const RaffleDetailsTitle = styled.div``;

export const RaffleOrganizer = styled.div``;

export const WhatsappSupport = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  text-decoration: none;
  color: #fff;
  background-color: green;
  border-radius: 7px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  margin-top: 5px;

  SVG {
    margin-right: 5px;
  }

  &:hover {
    color: #fff;
    transform: scale(1.1);

    @media (max-width: 768px) {
      transform: scale(1);
    }
  }
`;

export const ContainetRaffleMethod = styled.div`
  @media (max-width: 768px) {
    margin-top: 5px;
  }
`;

export const RaffleMethod = styled.div``;

export const RaffleDate = styled.div``;

export const Text = styled.p`
  margin: 0;

  span {
    font-weight: bold;
  }
`;

export const PixDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  text-decoration: none;
  color: var(--colorGrey);
  background-color: var(--colorFontPrimary);;
  border-radius: 7px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  margin-top: 5px;
  font-weight: bold;

  &:hover {
    color: #fff;
    transform: scale(1.1);

    @media (max-width: 768px) {
      transform: scale(1);
    }
  }
`;

export const ContainerPrice = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  background-color: var(--colorFontPrimary);
  font-weight: bold;
  color: white;
  border-radius: 20px;
  min-width: 250px;

  @media (max-width: 768px) {
    height: 60px;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 10px;
  }
`;

export const TextPrice = styled.div``;

export const RafflePrice = styled.div`
  font-size: 2rem;

  @media (max-width: 768px) {
    font-size: 1.6rem;
  }
`;
