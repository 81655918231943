import React from 'react';

import * as C from './styles';

function PlatformInfo () {
  return (
    <C.Container>
      <C.Title>Como funciona a plataforma.</C.Title>
      <C.ContainerText>
        <C.Text>
          Após clicar em <strong>criar rifa</strong>, você preencherá um
          formulário que servirá como configuração da sua rifa, neste formulário
          irá escolher o nome da rifa, regras de sorteio, data do sorteio
          (opcional), quantidade de bilhetes, fotos do item sorteado,
          dados do seu PIX, e número do seu whatsapp (para dúvidas, suporte e
          envio de comprovante dos clientes).
        </C.Text>
        <C.Text>
          Enviando o formulário seu site de vendas já estará disponível e online
          para visualização, sempre será possível fazer edições posteriores ({' '}
          <strong>CUIDADO</strong> não será possível editar o valor e a
          quantidade dos bilhetes). Após a publicação do site, você poderá
          compartilhar o link com seus clientes e amigos, e assim começar as
          vendas.
        </C.Text>
        <C.Text>
          Nossa plataforma cobra uma pequena taxa para deixar o sistema
          disponível para o inicio das vendas, esta taxa é váriavel e depende do
          montante arrecadado pelos bilhetes, ou seja, a soma do valor de todos
          os bilhetes.
        </C.Text>
        <C.SubTitle>Confira a tabela de preços:</C.SubTitle>
        <C.TablePrice>
          <thead>
            <tr>
              <th>Total arrecadado</th>
              <th>Valor único a pagar</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>de R$ 0,00 até R$100,00</td>
              <td>R$ 15,00</td>
            </tr>
            <tr>
              <td> de R$ 101,00 até R$ 500,00</td>
              <td>R$ 40,00</td>
            </tr>
            <tr>
              <td> de R$ 501,00 até R$ 1.000,00</td>
              <td>R$ 80,00</td>
            </tr>
            <tr>
              <td>de R$ 1001,00 até R$ 2.000,00</td>
              <td>R$ 100,00</td>
            </tr>
            <tr>
              <td>de R$ 2001,00 até R$ 5.000,00</td>
              <td>R$ 150,00</td>
            </tr>
            <tr>
              <td>de R$ 5001,00 até R$ 10.000,00</td>
              <td>R$ 200,00</td>
            </tr>
            <tr>
              <td>de R$ 10.001,00 até R$ 20.000,00</td>
              <td>R$ 300,00</td>
            </tr>
            <tr>
              <td>de R$ 20.001,00 até R$ 50.000,00</td>
              <td>R$ 400,00</td>
            </tr>
            <tr>
              <td>de R$ 50.001,00 até R$ 70.000,00</td>
              <td>R$ 500,00</td>
            </tr>
            <tr>
              <td>de R$ 70.001,00 até R$ 99.000,00</td>
              <td>R$ 700,00</td>
            </tr>
            <tr>
              <td>acima R$ 100.000,00</td>
              <td>R$ 1.000,00</td>
            </tr>
          </tbody>
        </C.TablePrice>
        <C.Text>
          Com o seu site pronto você terá o prazo de 3 dias para o pagamento da
          taxa através do PIX que será informado no momento do envio do
          formulário, caso precise de ajuda ou tenha alguma dúvida, está
          disponível na plataforma o whatsapp da nossa equipe de suporte,
          sinta-se a vontade para usá-lo.
        </C.Text>
      </C.ContainerText>
    </C.Container>
  );
}

export default PlatformInfo;
