/* eslint-disable no-unused-vars */
// Context , Reducer, Provider , Hook

import { User } from 'firebase/auth';
import { createContext, ReactNode, useContext, useReducer } from "react";

// criando types
export type State = {
  infoUser: User | null;
};

export enum FormActions {
  setInfoUser,
}

type Action = {
  type: FormActions;
  payload: unknown;
};

type ContextType = {
  state: State;
  dispatch: (action: Action) => void;
};

// type para children
type ProviderChildrem = {
  children: ReactNode;
};

// informções que inicia o reducer
const initialData: State = {
  infoUser: null
};

// criando um Reducer

const formReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case FormActions.setInfoUser:
      return { ...state, infoUser: action.payload as User };
    default:
      return state;
  }
};

// criando o context
const FormContext = createContext<ContextType | undefined>(undefined);

// provider

export const FormProvider = ({ children }: ProviderChildrem) => {
  // iniciando o reducer(primeiro informo o reducer, segundo como ele deve ser inicializado)
  const [state, dispatch] = useReducer(formReducer, initialData);
  const value = { state, dispatch };

  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
};

// hook
export const useInfoContext = () => {
  const context = useContext(FormContext);

  if (context === undefined) {
    throw new Error('Precisa ser usado dentro de um FormProvider');
  }

  return context;
};
