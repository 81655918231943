import { Photo } from "../../types/Raffle";
import { headers } from "./apiServer";
import { URL } from "./apiServer";

export const deleteRaffleDB = async (
  email: string,
  token: string,
  docRaffle: string,
  images: Photo[],
  raffleId: string
) => {
  let errorMessage = "";
  try {
    const resp = await fetch(`${URL}deleteraffle`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ email, token, docRaffle, images, raffleId }),
    });
    if (!resp.ok) {
      const err = await resp.json();
      errorMessage = err.message;
      throw new Error(err.message);
    } else {
      const data = await resp.json();
      return data;
    }
  } catch (error) {
    throw new Error(errorMessage);
  }
};

export const deleteRaffleDBManager = async (
  email: string,
  token: string,
  docRaffle: string,
  userRefEmail: string,
  images: Photo[],
  raffleId: string
) => {
  let errorMessage = "";

  try {
    const resp = await fetch(`${URL}deleterafflemanager`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        email,
        token,
        docRaffle,
        userRefEmail,
        images,
        raffleId,
      }),
    });
    if (!resp.ok) {
      const err = await resp.json();
      errorMessage = err.message;
      throw new Error(errorMessage);
    } else {
      const data = await resp.json();
      return data;
    }
  } catch (error) {
    throw new Error(errorMessage);
  }
};
