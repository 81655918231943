import styled from 'styled-components';

export const Container = styled.div<{ visible: boolean }>`
  display: ${(props) => (props.visible ? 'block' : 'none')};
  width: calc(100% - 250px);
  padding: 30px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 5px;
  }
`;

export const Title = styled.h1`
  color: var(--colorFontPrimary);
  font-size: 2.5em;

  @media (max-width: 768px) {
    font-size: 1.5em;
    padding: 10px;
  }
`;

export const ContainerBody = styled.div``;
export const ContainerRaffles = styled.div``;
export const SelectRaffles = styled.select`
  border-radius: 10px;
  border: 3px solid var(--colorFontPrimary);
  padding: 10px;
`;
export const SelectRafflesOption = styled.option``;

export const ContainerFilterPhone = styled.div`
  display: flex;
  justify-content: center;
  align-items: end;
  padding: 10px 0;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Filter = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const FilterVanquished = styled.button<{ selected: boolean }>`
  background-color: ${(props) =>
    props.selected ? 'var(--colorFontPrimary)' : 'var(--colorLightGrey)'};
  border-radius: 5px;
  padding: 5px 20px;
  margin-left: 10px;
`;

export const FilterPhone = styled.div`
  border: 3px solid var(--colorFontPrimary);
  display: flex;
  border-radius: 10px;
  padding: 3px;
  width: 200px;

  input {
    border: none;
    width: 100%;
    @media (max-width: 768px) {
    }
  }
`;

export const TextFilter = styled.p`
  margin: 0;
  font-weight: bold;
  color: var(--colorGrey);
  margin-right: 5px;

  @media (max-width: 768px) {
    margin: 5px 0 -5px 0;
  }
`;

export const FilterButton = styled.button`
  border: 0;
  background-color: transparent;
  border-left: 1px solid var(--colorFontPrimary);

  svg {
    color: var(--colorFontPrimary);
    font-size: 1.4em;
    margin-left: 10px;
  }
`;

export const ContainerSalesFilter = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;

  @media (max-width: 768px) {
    margin: 10px 0;
    padding: 0;
    display: flex;
    justify-content: space-around;
  }
`;

export const ButtonAll = styled.button<{ selected: boolean }>`
  background-color: ${(props) =>
    props.selected ? 'var(--colorFontPrimary)' : 'var(--colorLightGrey)'};
  margin: 0 5px;
  padding: 5px;
  width: 100px;
  border-radius: 5px;

  @media (max-width: 768px) {
    margin: 0 2px;
    width: fit-content;
    padding: 5px 14px;
  }
`;
export const ButtonReserved = styled.button<{ selected: boolean }>`
  background-color: ${(props) =>
    props.selected ? 'var(--colorFontPrimary)' : 'var(--colorLightGrey)'};
  margin: 0 5px;
  padding: 5px;
  width: 100px;
  border-radius: 5px;

  @media (max-width: 768px) {
    margin: 0 2px;
    width: fit-content;
  }
`;
export const ButtonSold = styled.button<{ selected: boolean }>`
  background-color: ${(props) =>
    props.selected ? 'var(--colorFontPrimary)' : 'var(--colorLightGrey)'};
  margin: 0 5px;
  padding: 5px;
  width: 100px;
  border-radius: 5px;

  @media (max-width: 768px) {
    margin: 0 2px;
    width: fit-content;
    padding: 5px 14px;
  }
`;
export const ButtonAvailable = styled.button<{ selected: boolean }>`
  background-color: ${(props) =>
    props.selected ? 'var(--colorFontPrimary)' : 'var(--colorLightGrey)'};
  margin: 0 5px;
  padding: 5px;
  width: 100px;
  border-radius: 5px;

  @media (max-width: 768px) {
    margin: 0 2px;
    width: fit-content;
  }
`;

export const ContainerSales = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

export const ContainerTicket = styled.div`
  border-radius: 5px;
  border: 3px solid var(--colorGrey);
  color: var(--colorGrey);
  width: 250px;
  padding: 5px;
  margin: 5px;
  position: relative;

  @media (max-width: 768px) {
    width: 49%;
    margin: 3px 0;
  }
`;

export const ContainerTicketHeader = styled.div`
  display: flex;

  svg {
    color: red;
    margin: -3px 10px;
    font-size: 1.5em;
  }
`;

export const TicketNumber = styled.p<{ status: string }>`
  margin: 0;
  font-size: 0.8em;
  span {
    font-size: 1.2em;
    border: 1px solid var(--colorGrey);
    padding: 3px 8px;
    border-radius: 5px;
    background-color: ${(props) => props.status};
    color: #fff;
    font-weight: bold;
  }
`;

export const BuyerName = styled.p`
  margin: 0;

  @media (max-width: 768px) {
    font-size: 0.63em;
  }
`;

export const ContainerOptions = styled.div``;
