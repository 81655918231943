import styled from 'styled-components';

export const Container = styled.div`

`;

export const ContainerGlobalQuestions = styled.div`
  background: var(--colorGrey);
  padding: 40px 10vw;

  @media (max-width: 768px) {
    padding: 20px 0.7vw;
  }
`;

export const Title = styled.h1`
  text-align: center;
  color: var(--colorFontSecondary);
  
  @media (max-width: 768px) {
    font-size: 1.5em;
  }
`;

export const ContainerQuestions = styled.div``;

export const ContainerQuestion = styled.div``;

export const Question = styled.div`
  background-color: var(--colorFontSecondary);
  padding: 10px;
  font-size: 1.3em;
  border-radius: 10px;
  border: 1px solid;
  margin: 10px 0 0 0;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 1.1em;
  }
`;

export const Answer1 = styled.p<{ visible: boolean }>`
  height: ${(props) => (props.visible ? 'fit-content' : '0')};
  background-color: var(--colorWhite);
  padding: ${(props) => (props.visible ? '15px' : '0')};;
  border-radius: 10px;
  transition: all 0.6s ease-in-out;
  overflow: hidden;
`;

export const Answer2 = styled.p<{ visible: boolean }>`
  height: ${(props) => (props.visible ? 'fit-content' : '0')};
  background-color: var(--colorWhite);
  padding: ${(props) => (props.visible ? '15px' : '0')};;
  border-radius: 10px;
  transition: all 0.6s ease-in-out;
  overflow: hidden;
`;

export const Answer3 = styled.p<{ visible: boolean }>`
  height: ${(props) => (props.visible ? 'fit-content' : '0')};
  background-color: var(--colorWhite);
  padding: ${(props) => (props.visible ? '15px' : '0')};;
  border-radius: 10px;
  transition: all 0.6s ease-in-out;
  overflow: hidden;
`;

export const Answer4 = styled.p<{ visible: boolean }>`
  height: ${(props) => (props.visible ? 'fit-content' : '0')};
  background-color: var(--colorWhite);
  padding: ${(props) => (props.visible ? '15px' : '0')};;
  border-radius: 10px;
  transition: all 0.6s ease-in-out;
  overflow: hidden;
`;

export const Answer5 = styled.p<{ visible: boolean }>`
  height: ${(props) => (props.visible ? 'fit-content' : '0')};
  background-color: var(--colorWhite);
  padding: ${(props) => (props.visible ? '15px' : '0')};;
  border-radius: 10px;
  transition: all 0.6s ease-in-out;
  overflow: hidden;
`;

export const Answer6 = styled.p<{ visible: boolean }>`
  height: ${(props) => (props.visible ? 'fit-content' : '0')};
  background-color: var(--colorWhite);
  padding: ${(props) => (props.visible ? '15px' : '0')};;
  border-radius: 10px;
  transition: all 0.6s ease-in-out;
  overflow: hidden;
`;

export const Answer7 = styled.p<{ visible: boolean }>`
  height: ${(props) => (props.visible ? 'fit-content' : '0')};
  background-color: var(--colorWhite);
  padding: ${(props) => (props.visible ? '15px' : '0')};;
  border-radius: 10px;
  transition: all 0.6s ease-in-out;
  overflow: hidden;
`;

export const ContainerWhatsapp = styled.div`
  padding: 40px 10vw;
  background: var(--colorWhite);
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    padding: 20px 2vw;
  }

`;

export const WhatsappText = styled.p`
  text-align: center;
  font-size: 1.5em;
  color: var(--colorGrey);

  @media (max-width: 768px) {
    font-size: 1.1em;
  }
`;

export const Whatsapp = styled.a`
  display: flex;
  align-items: center;
  margin: 50px;
  font-size: 3em;
  font-weight: bold;
  color:green;
  background-color: rgba(128, 255, 0, 0.44);;
  padding: 15px;
  border-radius: 60px;

  @media (max-width: 768px) {
    font-size: 1.2em;
  }

`;
