import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 30px 10vw;
  font-size: 1.3em;
  justify-content: space-around;
  background: var(--colorGrey);

  @media (max-width: 768px) {
    margin: 20px 0 0 0;
    flex-direction: column;
    padding: 20px 0.5vw;
  }
`;

export const ContainerItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
`;

export const ContainerSVG = styled.div`
  @media (max-width: 768px) {
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  color: var(--colorFontPrimary);
  font-weight: bold;

  svg {
    margin-right: 10px;
    font-size: 1.5em;
    color: var(--colorFontSecondary);
  }

  @media (max-width: 768px) {
    font-size: 0.8em;

    svg {
    }
  }
`;
