import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

import React, { useEffect, useState } from "react";
import { BiMoviePlay } from "react-icons/bi";
import { BsWhatsapp } from "react-icons/bs";
import { CgSearchLoading } from "react-icons/cg";
import { FaMoneyBillWave } from "react-icons/fa";
import { ImExit } from "react-icons/im";
import { IoTicketOutline, IoTicket } from "react-icons/io5";

import Config from "../../components/admin/Config";
import Create from "../../components/admin/Create";
import Raffles from "../../components/admin/Raffles";
import Sales from "../../components/admin/Sales";
import { getRafflesAdmin } from "../../db/api/apiServer";
import { auth } from "../../db/firebase";
import * as C from "./styles";
import { MenuSelected, Raffle } from "../../types/Raffle";
import { FormActions, useInfoContext } from "../../contexts/userInfoContext";

function Admin() {
  const navigate = useNavigate();

  const { state, dispatch } = useInfoContext();

  const [selected, setSelected] = useState(MenuSelected.RAFFLES);
  const [loading, setLoading] = useState(true);
  const [raffles, setRaffles] = useState<Raffle[]>([]);

  const [rafflesClosed, setRafflesClosed] = useState<Raffle[]>([]);
  const [rafflesRunning, setRafflesRunning] = useState<Raffle[]>([]);

  const urlLojaDaRifa =
    "https://www.youtube.com/channel/UCIQV1P7GquZGi3seQjPjaUw";
  const phoneAdmin = "67999519176";
  const apiWhatsapp = `https://api.whatsapp.com/send?phone=55${phoneAdmin}&text=Olá,%20gostaria%20de%20mais%20informações%20da%20plataforma%20Loja%20da%20Rifa.`;

  useEffect(() => {
    const getRaffle = async () => {
      if (state.infoUser) {
        const token = (await state.infoUser?.getIdToken()) as string;
        const email: string = state.infoUser?.email as string;
        console.log("dif null admin", state.infoUser);

        try {
          const rafflesResult = await getRafflesAdmin(email, token);
          setRaffles(rafflesResult);
        } catch (error) {
          alert("Erro ao carregar as rifas, tente novamente mais tarde");
        }
        setLoading(false);
      } else {
        console.log("igual null admin", state.infoUser);

        navigate("/");
      }
    };
    getRaffle();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateRaffles = async (): Promise<void> => {
    const token = (await state.infoUser?.getIdToken()) as string;
    const email: string = state.infoUser?.email as string;
    try {
      const rafflesResult = await getRafflesAdmin(email, token);
      setRaffles(rafflesResult);
    } catch (error) {
      alert("Erro ao carregar as rifas, tente novamente mais tarde");
    }
  };

  useEffect(() => {
    setRafflesRunning(raffles.filter((raffle) => raffle.active === true));
    setRafflesClosed(raffles.filter((raffle) => raffle.active === false));
  }, [raffles]);

  const handleLogout = async () => {
    console.log("logout");

    await signOut(auth);
    dispatch({ type: FormActions.setInfoUser, payload: null });
    navigate("/");
    console.log("logout ultimo");
  };

  if (loading) {
    return (
      <C.VerifyCredentials>
        <C.Loading>Verificando Credenciais...</C.Loading>
        <C.SVG>
          <CgSearchLoading />
        </C.SVG>
      </C.VerifyCredentials>
    );
  }


  return (
    <C.Container>
      <C.ContainerSidebar>
        <C.Header>
          <C.Logo src="/images/logo.png" />
        </C.Header>
        <C.Menu>
          <C.MenuItem
            onClick={() => setSelected(MenuSelected.RAFFLES)}
            selected={selected === MenuSelected.RAFFLES}
          >
            <IoTicketOutline />
            <C.Text>Minhas Rifas</C.Text>
          </C.MenuItem>
          <C.MenuItem
            onClick={() => setSelected(MenuSelected.SALES)}
            selected={selected === MenuSelected.SALES}
          >
            <FaMoneyBillWave />
            <C.Text>Vendas</C.Text>
          </C.MenuItem>
          {/* <C.MenuItem
            onClick={() => setSelected(MenuSelected.CONFIG)}
            selected={selected === MenuSelected.CONFIG}
          >
            <BsGearFill />
            <C.Text>Configurações</C.Text>
          </C.MenuItem> */}
          <C.MenuItem
            onClick={() => setSelected(MenuSelected.CREATE)}
            selected={selected === MenuSelected.CREATE}
          >
            <IoTicket />
            <C.Text>Criar rifa</C.Text>
          </C.MenuItem>
          <C.MenuItemLink href={urlLojaDaRifa} target="_blank">
            <BiMoviePlay />
            <C.Text>Tutoriais</C.Text>
          </C.MenuItemLink>
          <C.MenuItemLink href={apiWhatsapp} target="_blank">
            <BsWhatsapp />
            <C.Text>Suporte</C.Text>
          </C.MenuItemLink>
          <C.MenuItemExit selected={false} onClick={handleLogout}>
            <ImExit />
            <C.Text>Sair</C.Text>
          </C.MenuItemExit>
        </C.Menu>
      </C.ContainerSidebar>
      <C.ContainerEmpty></C.ContainerEmpty>
      <Raffles
        visible={selected === MenuSelected.RAFFLES}
        rafflesClosed={rafflesClosed}
        rafflesRunning={rafflesRunning}
        setSelected={setSelected}
        userFirebase={state.infoUser}
        updateRaffles={updateRaffles}
      />
      <Sales
        visible={selected === MenuSelected.SALES}
        raffles={rafflesRunning}
        userFirebase={state.infoUser}
      />
      <Config visible={selected === MenuSelected.CONFIG} />
      <Create
        visible={selected === MenuSelected.CREATE}
        userFirebase={state.infoUser}
        setSelected={setSelected}
        updateRaffles={updateRaffles}
        // host={host}
      />
    </C.Container>
  );
}

export default Admin;
