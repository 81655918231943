import React from 'react';

import * as C from './styles';

function Terms () {
  return (
    <C.Container>
      <C.Title>TERMOS E CONDIÇÕES DE USO</C.Title>
      <C.Update>Atualizado em 01/12/2022</C.Update>
      <C.Paragraph>
        Serão pactuados, entre o USUÁRIO, pessoa física de direito privado, e a
        pessoa física DARLAN GOMES AGUIAR, com CPF de nº 012.704.091-90, com
        endereço na Rua Edsom Bezerra, nº 20, Bairro JD Girasol, Itaporã/MS, CEP
        79.890-000, no presente instrumento, os termos e condições de uso
        necessários à utilização dos serviços oferecidos na Plataforma Loja da
        Rifa.
      </C.Paragraph>
      <C.SubTitle>CONSIDERAÇÕES INICIAIS</C.SubTitle>
      <C.Item>
        I- Considera-se “Usuário do perfil Tipo 1”, aquele indivíduo que figura
        como criador/organizador da campanha. Responsável pela escolha do
        título, categoria, local do sorteio, prêmio e todas as outras
        configurações que envolverem a criação, organização e arrecadação da
        campanha.
      </C.Item>
      <C.Item>
        II- Considera-se “Usuário do perfil Tipo 2”, aquele indivíduo que figura
        como comprador da campanha. Responsável pela compra de um ou mais
        números da campanha, É quem se identifica com a causa e está disposto a
        participar do financiamento coletivo/ projeto/ ação.
      </C.Item>
      <C.Item>
        III- Considera-se “Diretoria ou Direção”, os representantes da
        plataforma Loja da Rifa , seus funcionários e pessoal, envolvidos no
        desenvolvimento e suporte da Plataforma Loja da Rifa.
      </C.Item>
      <C.Item>
        IV- Considera-se “Plataforma Loja da Rifa”, o site e aplicativo, que
        permite a criação, organização e arrecadação de campanhas de rifas, com
        a finalidade de arrecadar fundos para projetos, ações e causas sociais.
      </C.Item>
      <C.Item>
        V- Considera-se “Campanha”, a criação de uma rifa, com a finalidade de
        arrecadar fundos para projetos, ações e causas sociais.
      </C.Item>
      <C.Item>
        VI- Considera-se “Rifa”, a forma de arrecadação de fundos para projetos,
        ações e causas sociais, através da venda de números, com a finalidade de
        arrecadar fundos para projetos, ações e causas sociais.
      </C.Item>
      <C.Item>
        VII- Considera-se “Número”, o número da rifa, que será sorteado no final
        da campanha.
      </C.Item>
      <C.Item>
        VIII- Considera-se “Prêmio”, o produto ou serviço que será sorteado no
        final da campanha.
      </C.Item>
      <C.Item>
        IX- Considera-se “Sorteio”, o ato de escolher o número vencedor da rifa.
      </C.Item>
      <C.SubTitle>CLÁUSULA GERAL</C.SubTitle>
      <C.Paragraph>
        Os Termos aqui expostos contêm as regras gerais e específicas aplicáveis
        aos usuários, assim como as explicações necessárias para as operações
        que estes poderão realizar no âmbito da Plataforma. Dessa forma, através
        do aceite destes Termos, o usuário (de perfis Tipo 1 e Tipo 2) concorda
        com todas as regras e procedimentos aqui descritos.
      </C.Paragraph>
      <C.SubTitle>CLÁUSULA PRIMEIRA: DO OBJETO</C.SubTitle>
      <C.Paragraph>
        A Plataforma “Loja da Rifa” busca oferecer soluções tecnológicas que
        viabilizem a criação, execução e acompanhamento de campanhas
        colaborativas, crowdfunding, na modalidade ONLINE. Disposta a auxiliar o
        compartilhamento e gerenciamento das campanhas, de maneira que facilita
        o uso e melhora o alcance de usuários, através da sistemática
        inteligente e das funcionalidades automatizadas.
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Primeiro</span>: A direção da Plataforma Loja da Rifa,
        reserva a si, o direito de alterar as condições destes Termos a qualquer
        tempo, comprometendo-se a disponibilizar suas novas versões neste link
        “Termos e Condições” publicado no site oficial da plataforma, bem como,
        aviso de notícias nas redes sociais vinculadas à Plataforma, que
        entrarão em vigor na data de sua publicação.
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Segundo</span>: Na remota hipótese de um usuário,
        independentemente do seu perfil de uso, enxergar ofensa aos seus
        direitos subjetivos na redação dos presentes termos, este poderá
        comunicar-se através do Portal de Suporte, informar suas razões e
        compor, junto à Direção da Plataforma, uma solução justa.
      </C.Paragraph>
      <C.SubTitle>CLÁUSULA SEGUNDA - DOS SERVIÇOS</C.SubTitle>
      <C.Paragraph>
        Através desta Plataforma, a Direção da “Loja da Rifa” oferece sistema de
        gerenciamento de campanhas e ações, que atende o cadastramento de
        produtos, valores.
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Primeiro</span>: Não estão incluídos nos serviços
        ofertados pela plataforma, tampouco pela Direção que a desenvolve, a
        responsabilidade sobre os valores, aportes, contribuições ou quaisquer
        recursos aplicados pelos Usuários de perfil Tipo 2, destinados às contas
        sinalizadas pelos Usuários Tipo 1. Assim, a atuação da Direção se resume
        à disponibilização da referida plataforma, para que os seus usuários
        idealizem as campanhas ou participem delas, oferecendo suporte nos
        limites dos termos aqui expostos.{' '}
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Segundo</span>: O serviço de atendimento, suporte
        técnico e operacional, será realizado pela Direção e seus prestadores de
        serviço, EXCLUSIVAMENTE através dos canais de atendimento expostos no
        Site “https://lojadarifa.com.br/”, sem direito a utilização de sistema
        gratuito de telefonia.
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Terceiro</span>: O usuário, independentemente do perfil
        de uso (Tipo 1 ou Tipo 2) não é autorizado a pronunciar-se em nome da
        Plataforma Rifa Digital, tampouco em nome da Direção. Ainda que se trate
        de usuário provecto, de modo que é terminantemente proibido, pessoa não
        autorizada colocar-se à disposição para sanar questionamentos, auxiliar
        no suporte ou outras necessidades de comunicação em relação ao Serviço
        prestado pela diretoria da Plataforma Loja da Rifa.
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Quarto</span>: O Usuário Tipo 1, apenas poderá
        colocar-se à disposição dos colaboradores (Usuários de perfil Tipo 2)
        que aderirem sua campanha, para tratar somente da campanha. De modo que
        seu auxílio se limita às questões que envolvem o evento por ele criado.
        Todas as demais dúvidas, no tocante ao funcionamento da Plataforma,
        deverão ser encaminhadas aos canais de suporte, dispostos no Site
        Oficial da Plataforma.
      </C.Paragraph>
      <C.SubTitle>CLÁUSULA TERCEIRA - DOS USUÁRIOS</C.SubTitle>
      <C.Paragraph>
        A criação de campanhas pelo Usuário Tipo 1, depende da realização de um
        pré-cadastro no Site. O cadastro inicial exige: (i) E-mail; (ii) senha;
        (iii) nome; (iv) WhatsApp/Telefone.
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Primeiro</span>: Nossas orientações acerca da validade
        do pré-cadastro são:
      </C.Paragraph>
      <C.Item>
        a) As pessoas físicas devem possuir no mínimo 18 anos (ou estar
        legalmente representadas, quando menores de 18 anos, na forma da lei),
        possuindo número de inscrição ativo sob o CPF.
      </C.Item>
      <C.Item>
        b) As Pessoas Jurídicas devem estar estabelecidas no Brasil, possuírem
        número de inscrição ativo sob o CNPJ e ser representada por
        representante legal, que informará seu contato, estando devidamente
        autorizado e com poderes estabelecidos.{' '}
      </C.Item>
      <C.Paragraph>
        <span>Parágrafo Segundo</span>: O pré-cadastro realizado pelos usuários
        incluirá dados que o vinculam às campanhas das quais participe.
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Terceiro</span>: Em caso de informações incorretas ou
        inverídicas, fraudes, inércia do USUÁRIO no envio dos documentos ou
        informações eventualmente solicitadas, caberá à Direção bloquear o
        acesso do usuário a sua Plataforma, sem sofrer prejuízo de outras
        medidas como previstas nestes Termos, além de não assistir ao usuário
        qualquer tipo de indenização ou ressarcimento.{' '}
      </C.Paragraph>
      <C.SubTitle>
        CLÁUSULA QUARTA: DA UTILIZAÇÃO DA PLATAFORMA RIFA DIGITAL
      </C.SubTitle>
      <C.Paragraph>
        A utilização da Plataforma Loja da Rifa, pelos Usuários, permite o
        registro da realização de Aportes para campanhas colaborativas, reserva
        com tempo pré estabelecido de cotas e arrecadação direcionada à conta
        indicada pelo Organizador/Donatário, não havendo qualquer tipo de
        retenção financeira por parte da plataforma.
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Primeiro</span>: Ao criar uma rifa como donatário de
        campanha, o organizador indica ciência de todos os termos aqui
        previstos, além de apresentar previamente as informações que a empresa
        tomar como relevantes para aceitar a promoção da campanha que será
        realizada.
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Segundo</span>: Realizando o login no site da Plataforma
        Loja da Rifa, os Usuários Tipo 1 poderão visualizar as campanhas em
        andamento, terão acesso ao seu faturamento, o histórico das vendas, menu
        de configurações e link de acesso direto ao Suporte da Plataforma.
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Terceiro</span>: Após a criação da campanha, é possível
        alterar dados e informações como: o título, descrição, fotos e ainda a
        data de encerramento da campanha (podendo ser prorrogada ou antecipada).
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Quarto</span>: Ao criar uma campanha o organizador
        declara reconhecer e aceitar a obrigação de realizar a entrega dos
        produtos e/ou serviços na quantidade, modelo e dentro do prazo
        estabelecido nos termos de criação do projeto, assumindo ainda, toda
        responsabilidade pela entrega ou não, do bem, valor ou serviço fora dos
        parâmetros acordados.
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Quinto</span>: O organizador se compromete a promover
        campanhas apenas com produtos ou serviços que tenham origem e natureza
        lícitas, não violem quaisquer direito de terceiros, incluindo direitos
        de propriedade intelectual, direitos de qualquer pessoa ou entidade,
        direitos de publicidade ou privacidade, direitos da criança e do
        adolescente, não desrespeitem os direitos dos consumidores, nos termos
        da lei aplicável, não apresentam caráter difamatório, e que sua
        comercialização seja permitida e esteja em conformidade com a legislação
        brasileira vigente.
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Sexto</span>: O organizador da campanha se
        responsabiliza pela realização do sorteio, independente de qual seja o
        método, comprometendo-se a informar na página da campanha quando o
        sorteio for realizado, bem como informar o(s) ganhador(es).
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Sétimo</span>: Quando o organizador da campanha opta por
        realizar o sorteio pela forma “Loteria federal”, os ganhadores serão
        definidos com base no resultado da extração da Loteria Federal.
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Oitavo</span>: Na hipótese em que a campanha alcance a
        venda de toda as cotas ou o seu sorteio tenha sido realizado, o painel
        de edição das informações da campanha ficará bloqueado.
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Nono</span>: A direção da Plataforma poderá suspender,
        reverter, ou cancelar campanhas do painel e da conta do organizador nos
        casos em que esse preste informações falsas ou não entregue eventuais
        prêmios prometidos na quantidade, modelo e dentro prazo convencionados.
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Décimo</span>: Havendo solicitação, justo motivo,
        verificação de fraude ou quaisquer situações correlatas, a diretoria
        poderá realizar o cancelamento da campanha.
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Décimo Primeiro</span>: Aos Usuários de perfil Tipo 2, o
        acesso à campanha pretendida se dará por meio de link compartilhado pelo
        Usuário Tipo 1, organizador do evento. Ao passo em que, somente com os
        links e caminhos de acesso compartilhados pelos criadores, será possível
        participar do financiamento coletivo.
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Décimo Segundo</span>: Os usuários da Plataforma Loja da
        Rifa, não ficam restritos ao manuseio da plataforma usufruindo apenas de
        um tipo de perfil. Significa dizer que, o mesmo indivíduo pode figurar
        como criador de campanhas e também colaborador delas e das que mais
        desejar.
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Décimo Terceiro</span>: Os Usuários Tipo 1,
        organizadores/donatários das campanhas decidem quantidade de números que
        deseja vincular aos sorteios e a contraprestação pelo uso e suporte da
        Plataforma Loja da Rifa, há uma taxa cujo valor é tabelado tendo como
        referência o valor pretendido na campanha.
      </C.Paragraph>
      <C.SubTitle>
        CLÁUSULA TERCEIRA: DAS RESPONSABILIDADES DOS USUÁRIOS
      </C.SubTitle>
      <C.Paragraph>
        <span>Parágrafo Primeiro</span>: Os Usuários se comprometem a utilizar
        as ferramentas dispostas na Plataforma Loja da Rifa, apenas para a
        criação e organização das Campanhas, de maneira lícita e justa.
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Segundo</span>: Na hipótese de uso inadequado da
        Plataforma, caberá ao Usuário as consequências legais, cíveis e
        criminais que o caso ensejar. Sendo assim, caberá à Diretoria suspender
        ou cancelar de maneira imediata qualquer publicação de campanha que
        viole a legislação vigente, sendo inoportuno e descabido qualquer pedido
        de reembolso ou reparação junto à Diretoria da Plataforma.
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Terceiro</span>: No caso do descumprimento de qualquer
        uma das cláusulas constantes nesse documento e, havendo denúncia, apenas
        o usuário será responsabilizado, não restando nenhum tipo de danos ou
        perdas à Diretoria e seu pessoal.
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Quarto</span>: Em caso de cancelamento ou suspensão da
        campanha, todos os usuários declaram-se cientes de que apenas o usuário
        Organizador fica obrigado a devolver os valores arrecadados pela
        respectiva campanha, aos seus respectivos doadores. Isto porque, os
        valores levantados através das vendas das cotas são destinados à conta
        bancária informada pelo Usuário Organizador.
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Quinto</span>: O Usuário Tipo 1 se responsabiliza pela
        adequação das imagens e vídeos para visualização pública e utilização na
        plataforma.
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Sexto</span>: A utilização de imagens ou vídeos que
        exponham nudez, violência, ou imagens ofensivas, contrárias a qualquer
        dispositivo legal, vigente em nosso território, são expressamente
        proibidas pela plataforma. Sob pena de ter a campanha cancelada sem
        danos caracterizados além da devolução dos valores arrecadados aos
        respectivos doadores, com a cobrança da taxa de utilização da
        plataforma, sem prejuízos quanto as perdas e danos sofridos pela
        Direção, oportunizando o direito de ação de regresso em caso de ato
        lesivo, causador de quaisquer danos.
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Sétimo</span>: O Usuário Tipo 1, se compromete em estar
        ciente e manter-se regular com as suas obrigações fiscais, isentando a
        Direção de qualquer responsabilidade fiscal sobre as transações
        realizadas na Plataforma Loja da Rifa.
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Oitavo</span>: O Usuário Tipo 1 se compromete a manter
        constantemente atualizado seus dados cadastrais no perfil da Plataforma.
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Nono</span>: O Usuário fica responsável por possuir uma
        conta bancária apta em instituição financeira nacional adequada e por
        informá-la corretamente no momento de cadastro, para receber os recursos
        oriundos das campanhas organizadas através da Plataforma.
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Décimo</span>: Os usuários estão cientes de que, a conta
        informada para recebimento dos valores é de sua inteira
        responsabilidade, bem como a conferência dos dados cadastrados, de modo
        que, não caberá qualquer responsabilização à Direção da Plataforma Loja
        da Rifa, nos casos de informação incorreta.
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Décimo Primeiro</span>: Os Usuários declaram-se cientes
        de que, na hipótese de o Usuário Tipo 2 desistir da compra de uma cota
        ou da participação em uma campanha deverá levar seu pedido diretamente
        ao organizador da campanha. Não havendo qualquer possibilidade de
        requerer junto à Direção da Plataforma, uma vez que a gerência das
        campanhas e o recebimento dos valores são limitados ao Usuário Tipo 1
        (organizador).
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Décimo Segundo</span>: O Usuário Tipo 1, que estiver
        como organizador de campanha ativa, fica ciente de que os pedidos de
        cancelamento de compra e/ou participação em campanhas, formulados pelos
        Usuários Tipo 2, são de sua inteira responsabilidade, cabendo a estes a
        devolução do valor e cancelamento da cota. Fica ciente também, de que a
        Plataforma Loja da Rifa, oferece funcionalidade de cancelamento manual
        da cota e por isso, não há necessidade de qualquer intervenção por parte
        da Diretoria da Plataforma.
      </C.Paragraph>
      <C.SubTitle>CLÁUSULA QUINTA: DOS PAGAMENTOS</C.SubTitle>
      <C.Paragraph>
        Os usuários poderão utilizar quaisquer Meios de Pagamento aceitos pela
        Plataforma para a realização de compras em suas campanhas e também
        poderão depositar diretamente na conta bancária do organizador da
        campanha pretendida, observadas as regras específicas contidas no Site e
        nestes Termos.
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Primeiro</span>: Estes termos poderão ser rescindidos a
        qualquer tempo, por qualquer uma das Partes, sem ônus, mediante
        utilização dos mecanismos disponibilizados na Plataforma Loja da Rifa.
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Segundo</span>: Estes Termos serão rescindidos em caso
        de descumprimento de quaisquer das obrigações aqui estabelecidas, bem
        como pela decretação de falência, recuperação judicial, extrajudicial ou
        insolvência civil de qualquer das partes ou ainda pela ocorrência de
        caso fortuito ou força maior que impossibilite a prestação dos Serviços,
        total ou parcialmente, por mais de 10 (dez) dias consecutivos.
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Terceiro</span>: A rescisão destes Termos, por qualquer
        motivo, não prejudicará o direito da Direção da Plataforma Loja da Rifa,
        de reivindicar as quantias porventura devidas pelos organizadores das
        campanhas, em razão da prestação dos Serviços, nem a indenização
        porventura devida.
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Quarto</span>: O usuário poderá encerrar sua Conta junto
        à Plataforma Rifa Digital a qualquer momento, observadas as regras de
        encerramento de cadastro e seguindo as instruções contidas na
        plataforma, devendo ser solicitada através do e-mail:
        darlangaguiar@gmail.com.
      </C.Paragraph>
      <C.SubTitle>
        CLÁUSULA SEXTA: DO ENCERRAMENTO DA CAMPANHA POR PARTE DOS USUÁRIOS
      </C.SubTitle>
      <C.Paragraph>
        O usuário Tipo 1 (organizador), por opção própria, poderá encerrar uma
        campanha a qualquer momento, independentemente do valor arrecadado até o
        momento do pedido junto à Plataforma Loja da Rifa, devendo devolver, por
        sua conta, todos os valores arrecadados, caso não tenha chegado a
        quantidade definida pelo organizador e comprovar todas as devoluções. E
        na hipótese da não devolução dos valores devidos, o usuário que sentir
        violado o seu direito de reembolso, deverá buscar do Organizador, não
        cabendo à Direção da Plataforma Loja da Rifa qualquer cobrança ou
        responsabilidade.
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Primeir</span>o: As campanhas serão encerradas quando o
        Organizador da Campanha sinalizar o colaborador premiado ou a realização
        do sorteio e a finalização da campanha;
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Segundo</span>: Uma campanha poderá ser excluída pela
        Direção da Plataforma Loja da Rifa, sem a necessidade de pedido por
        parte do usuário, se for percebida qualquer violação das disposições
        destes Termos.
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Terceiro</span>: Na hipótese de encerramento da
        campanha, os dados a ela vinculados bem como a publicação da campanha
        ficarão disponíveis dentro do prazo de 30 (trinta) dias corridos,
        podendo ser solicitados pelo organizador. Após o prazo, os dados e a
        publicação serão excluídos permanentemente da plataforma, não podendo
        ser resgatados.
      </C.Paragraph>
      <C.SubTitle>CLÁUSULA SÉTIMA: DAS DISPOSIÇÕES GERAIS </C.SubTitle>
      <C.Paragraph>
        <span>Parágrafo Primeiro</span>: Os usuários expressamente aceitam que a
        Direção da Plataforma e/ou qualquer de seus parceiros poderão enviar
        mensagens de e-mail de caráter informativo, referentes a comunicações
        específicas referentes aos Serviços, bem como outras mensagens de e-mail
        de natureza comercial, tais como ofertas dos parceiros e novidades na
        Plataforma Loja da Rifa. Caso o USUÁRIO não deseje mais receber
        referidas mensagens, este poderá solicitar o cancelamento do seu envio,
        dando total aceite para o uso dos dados ali captados, dentro do que
        preconiza a LGPD (Lei 13709/2018), em seus artigos, o Marco Civil da
        Internet (Lei 12965/2014). A empresa organizadora, aqui denominada
        Direção/Diretoria, se amolda de acordo com a legislação vigente,
        preconizando pela transparência quanto às suas atividades, tratamento de
        dados, assim como a utilização dos captados.
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Segundo</span>: As disposições destes Termos, assim como
        as de seus anexos, consideradas partes integrantes e complementares
        deste instrumento, refletem a integridade dos entendimentos e acordos
        entre as Partes.
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Terceiro</span>: Estes Termos são celebrados em caráter
        irrevogável, e suas obrigações são legais, válidas e vinculativas para
        as Partes e seus sucessores e herdeiros a qualquer título e exequível de
        acordo com seus respectivos termos.
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Quarto</span>: No caso de qualquer Cláusula, termo ou
        disposição destes Termos ser considerada nula ou não aplicável, tal
        nulidade ou inexequibilidade por lei posterior, não afetará quaisquer
        outras Cláusulas, termos ou disposições aqui contidas, que permanecerão
        em pleno vigor e efeito.
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Quinto</span>: O não exercício de quaisquer direitos ou
        a concordância com o não cumprimento de quaisquer termos ou condições
        sob estes Termos não configurará renúncia de quaisquer direitos sob
        estes Termos nem impedirá a referida Parte de executar ou exercer
        quaisquer destes direitos a qualquer tempo.
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Sexto</span>: Nenhuma tolerância ou atraso de qualquer
        das Partes em fazer cumprir ou exigir o cumprimento dos direitos e
        obrigações convencionados nestes Termos, constituirá novação nem
        precedente de qualquer natureza. Tal tolerância não prejudicará ou
        restringirá o exercício dos mesmos direitos e obrigações em situações
        futuras semelhantes, bem como não isentará, em nenhum caso, qualquer das
        Partes do integral cumprimento de suas obrigações de acordo com o aqui
        convencionado e previsto.
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Sétimo</span>: As Partes declaram estar atuando de forma
        independente. A relação contratual aqui acordada não será interpretada
        como uma joint venture, associação, sociedade, agência, representação
        comercial ou parceria, sendo que cada uma das Partes manterá
        independência e autonomia totais na administração e gerenciamento de
        seus respectivos negócios/campanhas, assim como permanecem irretocáveis
        os direitos sob a égide intelectual, seja da construção, condução e
        alteração da plataforma e marca, sendo a única proprietária, a pessoa
        DARLAN GOMES AGUIAR (Direção). Nenhuma das Partes ou seus respectivos
        representantes e empregados serão, em qualquer hipótese, considerados
        prepostos ou representantes da outra Parte, e nenhum deles poderá firmar
        contratos ou avenças em nome da outra Parte, ou obrigar a outra Parte
        perante terceiros.
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Oitavo</span>: Estes Termos não constituem qualquer
        vínculo de natureza trabalhista e/ou previdenciária entre as Partes ou
        entre uma Parte e os empregados, prepostos, consultores e eventuais
        subcontratados, mesmo utilizando-se de outras alcunhas, não sendo
        taxativas, tais como “afiliados”, “parceiros” e outras hipóteses,
        atrelados a outra Parte.
      </C.Paragraph>
      <C.Paragraph>
        <span>Parágrafo Nono</span>: Estes Termos serão regidos pelas leis da
        República Federativa do Brasil, na ausência dela a condição mais
        favorável para os pactuantes do presente instrumento, sem intenção de
        prejudicar quaisquer envolvidos.
      </C.Paragraph>
      <C.SubTitle>
        CLÁUSULA OITAVA: DO FORO DE ELEIÇÃO E ACEITE AOS TERMOS EXPLICITADOS
      </C.SubTitle>
      <C.Paragraph>
        <span>
          As Partes elegem o Foro da Comarca de Itaporã/MS para dirimir qualquer
          dúvida ou controvérsia relativa aos presentes Termos, com renúncia
          expressa de qualquer outro, por mais privilegiado que seja ou que
          venha a ser. Por estarem ajustadas as partes, realizam o aceite,
          aquiescendo com todas as cláusulas aqui previstas, assim como política
          de privacidade e os seguintes termos à disposição no site da
          Plataforma Loja da Rifa (https://lojadarifa.com.br).{' '}
        </span>
      </C.Paragraph>
    </C.Container>
  );
}

export default Terms;
