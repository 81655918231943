import styled from 'styled-components';

export const Container = styled.div`
  padding: 40px 10vw;

  @media (max-width: 768px) {
    padding: 20px 0.5vw;
  }
`;

export const Title = styled.h1`
  font-size: 40px;
  margin-bottom: 20px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 30px;
  }
`;

export const ContainerText = styled.div``;

export const Text = styled.p`
  text-indent: 4em;
  margin: 30px;
  font-size: 1.2em;
  line-height: 35px;
  color: #444;

  @media (max-width: 768px) {
    font-size: 1em;
    line-height: 20px;
    text-indent: 0em;
    text-align: center;
  }
`;

export const SubTitle = styled.h2`
  font-size: 25px;
  text-align: center;
  padding-bottom: 10px;
`;

export const TablePrice = styled.table`
  border-radius: 20px;
  margin: auto;
  padding: 20px;
  background: var(--colorGrey);
  text-align: center;

  th {
    padding: 10px 0;
    color: var(--colorFontSecondary);
    font-size: 1.2em;
  }

  td {
    padding: 3px 10px;
    border-bottom: 1px solid;
    color: var(--colorFontPrimary);
  }

  td:nth-child(2) {
    border-left: 1px solid;
    font-weight: bold;
  }

  tr:last-child {
    border-bottom: 0;
  }

  @media (max-width: 768px) {
    font-size: 0.8em;
    padding: 5px;
  }
`;
