import React, { useEffect, useState } from 'react';
import { GoThumbsdown } from 'react-icons/go';

import Selectedticket from '../ SelectedTicket ';
import {
  TicketType,
  StatusTickets,
  User,
  Raffle,
  StatusTicketDB
} from '../../../types/Raffle';
import Filter from '../Filter';
import Ticket from '../Ticket';
import * as C from './styles';

type Props = {
  raffle: Raffle;
  user: User;
  docRaffle: string | undefined;
  updateTickets: () => void;
};

function Tickets (props: Props) {
  const { raffle, user, docRaffle, updateTickets } = props;

  const [visible, setVisible] = useState<string>(StatusTickets.ALL);
  const [ticketsFiltered, setTicketsFiltered] = useState<TicketType[]>(
    raffle.tickets.sort((a, b) => a.number - b.number)
  );
  const [phone, setPhone] = useState('');
  const [selectedTickets, setSelectedTickets] = useState<number[]>([]);

  const AllTickets: TicketType[] = raffle.tickets;

  useEffect(() => {
    if (visible === StatusTickets.ALL) {
      setTicketsFiltered(raffle.tickets);
    } else if (visible === StatusTickets.RESERVED) {
      setTicketsFiltered(
        raffle.tickets.filter(
          (ticket) => ticket.status === StatusTicketDB.RESERVED
        )
      );
    } else if (visible === StatusTickets.PAID) {
      setTicketsFiltered(
        raffle.tickets.filter((ticket) => ticket.status === StatusTicketDB.PAID)
      );
    } else if (visible === StatusTickets.DISPONIBLE) {
      setTicketsFiltered(
        raffle.tickets.filter(
          (ticket) => ticket.status === StatusTicketDB.DISPONIBLE
        )
      );
    }
  }, [raffle.tickets, visible]);

  const handleFilter = (): void => {
    const formattedPhone = phone.replace(/\D/g, '');
    if (formattedPhone.length < 11) {
      alert('Digite um número de telefone válido!\nEx: (99)99999-9999');
      return;
    }
    setTicketsFiltered(
      raffle.tickets.filter((ticket) => ticket.buyerPhone === formattedPhone)
    );
  };

  const handleVisible = async (ticketsVisibles: string): Promise<void> => {
    if (visible === ticketsVisibles) await setVisible('');
    setVisible(ticketsVisibles);
  };

  return (
    <C.Container>
      <Filter
        phone={phone}
        setPhone={setPhone}
        handleFilter={handleFilter}
        handleVisible={handleVisible}
      />
      <C.ContainerTickets>
        <Ticket
          paid={raffle.paid}
          tickets={ticketsFiltered}
          allTickets={AllTickets}
          setSelectedTickets={setSelectedTickets}
          selectedTickets={selectedTickets}
        />
      </C.ContainerTickets>
      {ticketsFiltered.length === 0 && (
        <C.NoTickets>
          <GoThumbsdown /> Não há bilhetes
        </C.NoTickets>
      )}
      <Selectedticket
        docRaffle={docRaffle}
        raffle={raffle}
        selectedTickets={selectedTickets.sort((a, b) => a - b)}
        setSelectedTickets={setSelectedTickets}
        user={user}
        updateTickets={updateTickets}
      />
    </C.Container>
  );
}

export default Tickets;
