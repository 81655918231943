import React from 'react';
import { CgClose } from 'react-icons/cg';

import * as C from './styles';

type Props = {
  url: string;
  name: string;
  deleteImage: (name: string)=> void
}

function PhotoItem (props: Props) {
  const { name, url, deleteImage } = props;

  return (
    <C.Container>
      <C.Image src={url} alt={name} />
      <C.ButtonDelete onClick={() => deleteImage(name) } >
        <CgClose />
      </C.ButtonDelete>

    </C.Container>

  );
}

export default PhotoItem;
