import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2px;

  @media (max-width: 768px) {
    width: 100vw;
  }
`;

export const ContainerBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
  }
`;

export const ContainerUsers = styled.div`
  width: 400px;
  border: 3px solid var(--colorFontPrimary);
  padding: 10px;
  border-radius: 10px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const UsersTitle = styled.h1`
  background: var(--colorFontPrimary);
  padding 5px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  text-align: center;
`;

export const ContainerUsersEmail = styled.div`
  height: 150px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 9px;
  }
  &::-webkit-scrollbar-track {
    background: #fff;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--colorFontPrimary);
    border: 2px solid #fff;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const UserEmail = styled.div`
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #e0e0e0;
  }

  @media (max-width: 768px) {
    &:nth-child(odd) {
      background-color: #e0e0e0;
    }
  }
`;

export const ContainerRaffles = styled.div`
  border: 3px solid var(--colorFontSecondary);
  padding: 10px;
  margin-top: 10px;
  border-radius: 10px;
  width: 900px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const RafflesTitle = styled.h1`
   background: var(--colorFontSecondary);
  padding 5px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  text-align: center;
`;

export const ContainerRaffle = styled.div`
  border: 3px solid var(--colorFontPrimary);
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
`;
export const ContainerInfoRaffle = styled.div`
  @media (max-width: 768px) {
    font-size: 0.8em;
  }
`;

export const RaffleUser = styled.div`
  span {
    font-weight: bold;
  }
`;

export const RaffleEmail = styled.div`
  span {
    font-weight: bold;
  }
`;

export const RafflePhone = styled.div`
  span {
    font-weight: bold;
  }
`;

export const RaffleName = styled.div`
  span {
    font-weight: bold;
  }
`;
export const RafflePrice = styled.div`
  span {
    font-weight: bold;
  }
`;

export const RaffleDate = styled.div<{vanquished: boolean}>`
  color: ${props => props.vanquished ? 'red' : '#000'};

  span {
    font-weight: bold;
  }
`;

export const RaffleFinished = styled.div`
  span {
    font-weight: bold;
  }
`;

export const RaffleFinishedDate = styled.div<{vanquished: boolean}>`
  color: ${props => props.vanquished ? 'red' : '#000'};
  span {
    font-weight: bold;
  }
`;

export const RaffleValue = styled.div`
  span {
    font-weight: bold;
  }
`;

export const RafflePublication = styled.div<{paid: boolean}>`
color: ${props => props.paid ? '#000' : 'red'};
  span {
    font-weight: bold;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ButtonWhats = styled.a`
  margin: 3px;
  border: 2px solid var(--colorGrey);
  display: flex;
  align-items: center;
  color: #fff;
  background-color: green;
  border-radius: 5px;
  padding: 2px 5px;
  font-weight: bold;


  svg {
    margin-right: 3px;
  }
`;
export const ButtonSeeRaffle = styled.a`
  border: 2px solid var(--colorGrey);
  margin: 3px;
  background-color: var(--colorFontPrimary);
  border-radius: 5px;
  padding: 2px 5px;
  font-weight: bold;

`;

export const ButtonPaid = styled.button`
  border: 2px solid var(--colorGrey);

  margin: 3px;
  background-color: var(--colorFontPrimary);
  border-radius: 5px;
  padding: 2px 5px;
  font-weight: bold;

`;

export const ButtonNoPaid = styled.button`
  margin: 3px;
  border: 2px solid var(--colorGrey);
  background-color: var(--colorFontSecondary);
  border-radius: 5px;
  padding: 2px 5px;
  font-weight: bold;

`;
export const ButtonShowDelete = styled.button`
  margin: 3px;
  border: 2px solid var(--colorGrey);
  background-color: red;
  border-radius: 5px;
  padding: 2px 5px;
  color:#fff;
  font-weight: bold;

`;
export const ButtonDelete = styled.button`
  margin: 3px;
  border: 2px solid var(--colorGrey);
  font-weight: bold;


  background-color: Red;
  color: #fff;
  border-radius: 5px;
  padding: 2px 5px;
`;
