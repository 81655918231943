import styled from 'styled-components';

export const Container = styled.div`
  color: var(--colorGrey);
`;

export const ContainerSuccess = styled.div`
  padding: 0 10px 10px 10px;
`;

export const SuccessText = styled.p`
  margin: 0;
  span {
    font-weight: bold;
  }

  svg {
    color: green;
    margin: 0 2px;
  }
`;

export const ContainerTicketNumber = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
export const TicketTitle = styled.div``;

export const Ticket = styled.div`
  width: fit-content;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
`;

export const TicketNumber = styled.div`
  margin: 1px 1px;
  border: 2px solid var(--colorFontPrimary);
  border-radius: 5px;
  padding: 1px 3px 0 3px;
  font-size: 0.7em;
`;

export const PixDetails = styled.div`
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 3px solid var(--colorFontPrimary);
  border-radius: 5px;
`;

export const TitlePixDetails = styled.div`
  align-self: left;
  background: var(--colorFontPrimary);
  width: 101%;
  padding-left: 10px;
  font-weight: bold;
  margin-top: -2px;
  border-radius: 5px;
  color: #fff;
`;

export const PixValue = styled.p`
  margin: 0;

  span {
    font-weight: bold;
  }
`;

export const PixKey = styled.p`
  margin: 0;

  span {
    font-weight: bold;
  }
`;

export const Pix = styled.p`
  margin: 0;

  span {
    font-weight: bold;
  }
`;

export const pixName = styled.p`
  margin: 0;

  span {
    font-weight: bold;
  }
`;

export const WhatsappSupport = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  margin-top: 10px;
  text-decoration: none;
  color: #fff;
  background-color: green;
  border-radius: 7px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  SVG {
    margin-right: 5px;
  }

  &:hover {
    color: #fff;
    transform: scale(1.1);

    @media (max-width: 768px) {
      transform: scale(1);
    }
  }
`;

export const SendLater = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  margin-top: 10px;
  text-decoration: none;
  color: #fff;
  background-color: var(--colorFontPrimary);
  border-radius: 7px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  font-weight: bold;

  @media (max-width: 768px) {
    transform: scale(1);
  }
`;
