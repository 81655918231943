import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const Ticket = styled.button<{background: string, selected: boolean}>`
  border: 1px solid var(--colorGrey);
  color: ${props => props.selected ? 'var(--colorFontSecondary)' : 'var(--colorGrey)'};
  text-decoration: ${props => props.selected ? 'underline' : 'none'};
  background-color: ${props => props.background};
  border-radius: 5px;
  min-width: 60px;
  margin: 4px;
  padding: 3px;
  text-align: center;
  cursor: pointer;
  box-shadow: ${props => props.selected ? '2px 2px 2px 1px var(--colorFontSecondary)' : '2px 2px 2px 1px rgba(0, 0, 0, 0.2)'};
  font-weight: bold;
  transition: all 0.3s ease-in-out;
  
  @media (min-width: 768px) {
    &:hover {
      background-color: var(--colorGrey);
      color: #fff;
    }
  }
`;

export const TicketNumber = styled.div``;
