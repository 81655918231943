import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const Deleting = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--colorGreenTransparent);
  border: 3px solid var(--colorGrey);
  border-radius: 10px;
`;
export const DeletingText = styled.p`
  margin: 0;
  padding: 10px;
  text-align: center;
  font-weight: bold;
`;

export const ContainerRaffle = styled.div`
  border: 3px solid var(--colorFontPrimary);
  margin: 5px;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 500px;

  @media (max-width: 768px) {
    margin: 10px 0;
    width: 100%;
    flex-direction: column;
  }
`;

export const ContainerBody = styled.div`
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const RaffleTitle = styled.h2`
  font-size: 1.2em;
  margin-bottom: 8px;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const ContainerInfo = styled.div`
  @media (max-width: 768px) {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
`;

export const RaffleDate = styled.div``;

export const RaffleValue = styled.div``;

export const ContainerCloseRaffle = styled.div``;

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: 5px 0;
  flex-wrap: wrap;
`;

export const ButtonCloseRaffle = styled.button`
  background-color: var(--colorFontPrimary);
  color: #fff;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  font-weight: bold;
`;

export const ContainerFinishRaffle = styled.div`
  padding-right: 5px;
`;

export const ButtonDelete = styled.button`
  background-color: var(--colorFontSecondary);
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  font-weight: bold;
  padding: 5px;
  margin-left: 5px;
`;

export const ButtonSeeRaffle = styled.a`
  background-color: var(--colorFontSecondary);
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  font-weight: bold;
  padding: 5px;
  margin-left: 5px;
  margin-top: 5px;
  text-decoration: none;
  border: 2px solid var(--colorGrey);

  &:hover {
    color: #fff;
  }

  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

export const InputCloseRaffle = styled.textarea`
  padding: 5px;
  border: 3px solid var(--colorFontPrimary);
  width: 100%;
  border-radius: 10px;
`;

export const TextWarning = styled.p`
  font-size: 0.9em;
  color: red;
`;

export const ButtonChangeName = styled.button`
  background-color: var(--colorFontPrimary);
  color: #fff;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  font-weight: bold;
  margin: 5px 0;
`;

export const ContainerImage = styled.div``;

export const RaffleImage = styled.img`
  width: 200px;
  border-radius: 10px;
`;
