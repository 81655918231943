import { updateProfile, User } from "firebase/auth";
import React, { useState } from "react";
import {
  AiOutlineUserAdd,
  AiOutlineMail,
  AiOutlineWhatsApp,
} from "react-icons/ai";
import { RiLockPasswordLine } from "react-icons/ri";
import InputMask from "react-input-mask";
import { FormActions, useInfoContext } from "../../contexts/userInfoContext";

import { auth, createUser } from "../../db/firebase";
import { checkFieldErrors, showErrorFirebase } from "../../helpers/login";
import * as C from "./styles";
import { useNavigate } from "react-router-dom";

function Register() {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [confirmTerms, setConfirmTerms] = useState<boolean>(false);

  const formattedPhone = phone.replace(/\D/g, "");

  const { dispatch } = useInfoContext();
  const navigate = useNavigate();

  const handleRegister = async () => {
    setErrorMessage("");

    const error = checkFieldErrors(
      name,
      email,
      password,
      formattedPhone,
      confirmTerms
    );

    if (error) {
      setErrorMessage(error);
      return;
    }

    try {
      // cria o usuário no firebase
      const userCredential = await createUser(auth, email, password);
      await updateProfile(userCredential.user, {
        displayName: name,
      });

      const user: User = userCredential.user;

      // (TODO) Darlan - criar a função para enviar o email de verificação
      // await sendEmailVerification(userCredential.user);

      dispatch({ type: FormActions.setInfoUser, payload: user });

      navigate("/admin");
    } catch (error) {
      setErrorMessage(showErrorFirebase(error));
    }
  };

  return (
    <C.Container>
      <C.HeaderText>
        <C.Link onClick={() => navigate("/")}>
          <C.Image src="/images/logo.png" />
        </C.Link>

        <C.Text>Digite seus dados para criar uma nova conta</C.Text>
      </C.HeaderText>
      <C.Form>
        <C.FormInput>
          <C.ContainerInput>
            <C.SVGInput>
              <AiOutlineUserAdd />
            </C.SVGInput>
            <C.Input
              type="text"
              id="name"
              placeholder="Nome completo"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setName(e.target.value);
              }}
              value={name}
            />
          </C.ContainerInput>
          <C.ContainerInput>
            <C.SVGInput>
              <AiOutlineMail />
            </C.SVGInput>
            <C.Input
              type="email"
              id="email"
              placeholder="Digite seu e-mail"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(e.target.value);
              }}
              value={email}
            />
          </C.ContainerInput>
          <C.ContainerInput>
            <C.SVGInput>
              <RiLockPasswordLine />
            </C.SVGInput>
            <C.Input
              type="password"
              id="password"
              placeholder="Senha"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setPassword(e.target.value);
              }}
              value={password}
            />
          </C.ContainerInput>
          <C.ContainerInput>
            <C.SVGInput>
              <AiOutlineWhatsApp />
            </C.SVGInput>
            <InputMask
              mask="(99) 99999-9999"
              placeholder="Telefone com DDD"
              value={phone}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setPhone(e.target.value);
              }}
            />
          </C.ContainerInput>
          <C.ContainerInputCheckbox>
            <C.Input
              type="checkbox"
              id="checkbox"
              onChange={(e) => setConfirmTerms(e.target.checked)}
            />
            <C.Label htmlFor="checkbox">
              Ao se registrar, você aceita nossos{" "}
              <C.Link onClick={() => navigate("/terms")}>
                <strong>Termos de Uso e a nossa Política de Privacidade</strong>
                .
              </C.Link>
            </C.Label>
          </C.ContainerInputCheckbox>
          {errorMessage && <C.ErrorMsg>{errorMessage}</C.ErrorMsg>}
          <C.ButtonRegister onClick={handleRegister}>
            Registrar
          </C.ButtonRegister>
          <C.Text>
            Já possui conta?{" "}
            <C.Link onClick={() => navigate("/login")}>
              <strong>Clique aqui</strong>.
            </C.Link>
          </C.Text>
        </C.FormInput>
      </C.Form>
    </C.Container>
  );
}

export default Register;
