import {
  ref,
  listAll,
  getDownloadURL,
  uploadBytes,
  deleteObject
} from 'firebase/storage';
import { v4 as createId } from 'uuid';

import { storage } from '../../db/firebase';
import { Photo } from '../../types/Raffle';

export const getAll = async (folder: string) => {
  const list: Photo[] = [];
  // referência a meu storage na pasta images
  const imagesFolder = ref(storage, folder);

  const imagesList = await listAll(imagesFolder);
  // a resposta com minhas imagens vai estar dentro do .items
  for (const i in imagesList.items) {
    // buscando a URL da foto
    const fotoURL = await getDownloadURL(imagesList.items[i]);

    list.push({
      // pegando o nome do arquivo
      name: imagesList.items[i].name,
      url: fotoURL
    });
  }

  return list;
};

export const insert = async (file: File, folder: string) => {
  if (['image/jpg', 'image/jpeg', 'image/png'].includes(file.type)) {
    // criando um nume aleatório
    const randomName = createId();

    // especificando onde será guardado
    const newFile = ref(storage, `${folder}/${randomName}`);

    // mandado o arquivo de fato
    // o upload retorna 2 items 1 deles é o ref que faz referência o arquivo(com nome e url)
    const upload = await uploadBytes(newFile, file);
    // pegando o url da foto (link)
    const photoURL = await getDownloadURL(upload.ref);

    return {
      name: upload.ref.name,
      url: photoURL
    } as Photo;
  } else {
    return new Error('Tipo de arquivo não permitido');
  }
};

export const deletePhoto = async (name: string, folder: string) => {
  // criando referencia ao arquivo a ser deletado
  const imagesFolder = ref(storage, `${folder}/${name}`);

  // deletando o arquivo

  const deleted = await deleteObject(imagesFolder)
    .then(() => {
      return { deleted: true };
    })
    .catch(() => {
      return { deleted: false };
    });

  return deleted;
};
