import React, { useState } from "react";
import { AiOutlineMail } from "react-icons/ai";
import { RiLockPasswordLine } from "react-icons/ri";
import { FormActions, useInfoContext } from "../../contexts/userInfoContext";

import { auth, newPassword, signInEmailPassword } from "../../db/firebase";
import { showErrorFirebase } from "../../helpers/login";
import * as C from "./styles";
import { useNavigate } from "react-router-dom";


function Login() {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showFieldNewPassword, setShowFieldNewPassword] = useState(false);
  const [showEmailSendMessage, setShowEmailSendMessage] = useState(false);

  const { dispatch } = useInfoContext();
  const navigate = useNavigate();

  const handleLogin = async () => {
    setErrorMessage("");

    try {
      const user = await signInEmailPassword(auth, email, password);

      dispatch({ type: FormActions.setInfoUser, payload: user.user });
      console.log(user.user);
      

      navigate("/admin");
    } catch (error) {
      setErrorMessage(showErrorFirebase(error));
      console.log(error);
    }
  };

  const handleSendEmailVerification = async () => {
    setErrorMessage("");
    try {
      await newPassword(auth, email);
      setShowEmailSendMessage(true);
    } catch (error: any) {
      if (error.code === "auth/user-not-found") {
        setErrorMessage("Usuário não encontrado");
      } else {
        setErrorMessage("Erro ao enviar email");
      }
    }
  };

  return (
    <C.Container>
      <C.HeaderText>
        <C.Link onClick={() => navigate("/")}>
          <C.Image src="/images/logo.png" />
        </C.Link>
        <C.Text>Login</C.Text>
      </C.HeaderText>
      {!showFieldNewPassword && (
        <C.Form>
          <C.FormInput>
            <C.ContainerInput>
              <C.SVGInput>
                <AiOutlineMail />
              </C.SVGInput>
              <C.Input
                type="email"
                id="email"
                placeholder="Digite seu e-mail"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setEmail(e.target.value);
                }}
                value={email}
              />
            </C.ContainerInput>
            <C.ContainerInput>
              <C.SVGInput>
                <RiLockPasswordLine />
              </C.SVGInput>
              <C.Input
                type="password"
                id="password"
                placeholder="Senha"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPassword(e.target.value);
                }}
                value={password}
              />
            </C.ContainerInput>
            {errorMessage && <C.ErrorMsg>{errorMessage}</C.ErrorMsg>}
            <C.ButtonRegister onClick={handleLogin}>Entrar</C.ButtonRegister>
            <C.LinkRegister onClick={() => navigate("/register")}>
              Não possui conta? <span>Criar conta</span>
            </C.LinkRegister>
            <C.NoPassword onClick={() => setShowFieldNewPassword(true)}>
              Esqueceu senha <span>clique aqui.</span>
            </C.NoPassword>
          </C.FormInput>
        </C.Form>
      )}
      {showFieldNewPassword && (
        <C.Form>
          <C.FormInput>
            <C.ContainerInput>
              <C.SVGInput>
                <AiOutlineMail />
              </C.SVGInput>
              <C.Input
                type="email"
                id="email"
                placeholder="Digite seu e-mail para recuperar a senha"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setEmail(e.target.value);
                }}
                value={email}
              />
            </C.ContainerInput>
            {errorMessage && <C.ErrorMsg>{errorMessage}</C.ErrorMsg>}
            {showEmailSendMessage && (
              <>
                <C.MsgSendEmail>
                  Email de recuperação de senha enviado
                </C.MsgSendEmail>
                <C.Text>Verifique email na sua caixa de Span</C.Text>
              </>
            )}
            <C.ButtonRegister
              onClick={() => {
                handleSendEmailVerification();
              }}
            >
              Redefinir senha
            </C.ButtonRegister>
            <C.NoPassword onClick={() => setShowFieldNewPassword(false)}>
              Voltar para <span>login</span>
            </C.NoPassword>
          </C.FormInput>
        </C.Form>
      )}
    </C.Container>
  );
}
export default Login;
