import styled from 'styled-components';

export const Container = styled.div<{ visible: boolean }>`
  display: ${(props) => (props.visible ? 'block' : 'none')};
  width: calc(100% - 250px);
  padding: 30px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 5px;
  }
`;

export const Title = styled.h1`
  color: var(--colorFontPrimary);
  font-size: 2.5em;

  @media (max-width: 768px) {
    font-size: 1.5em;
    padding: 10px;
  }
`;

export const ContainerForm = styled.div`
  width: 500px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ContainerFormInput = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const Label = styled.label`
  font-weight: bold;
`;

export const Input = styled.input`
  width: 100%;
  padding: 5px;
  border: 3px solid var(--colorFontPrimary);
  border-radius: 10px;
`;

export const ContainerInputMask = styled.div`
  input {
    width: 500px;
    padding: 5px;
    border: 3px solid var(--colorFontPrimary);
    border-radius: 10px;

    @media (max-width: 768px) {
      width: 100%;
  }
  }
`;

export const ButtonSave = styled.button`
  width: 200px;
  padding: 10px;
  margin-top: 20px;
  cursor: pointer;
  background-color: var(--colorFontPrimary);
  font-weight: bold;
  color: #fff;
  border-radius: 10px;
  border-bottom: 3px solid var(--colorGrey);

`;
