import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';
import { User } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { BsGear } from 'react-icons/bs';
import InputMask from 'react-input-mask';
import { v4 as uuidv4 } from 'uuid';
import { useInfoContext } from '../../../contexts/userInfoContext';

import { createRaffleDatabase } from '../../../db/api/apiServer';
import { hours, raffleMethods, pixMethods } from '../../../helpers/arrays';
import { calculateRate, formatPrice } from '../../../helpers/FunctionsCalc';
import { getDocFirebase, validateFields } from '../../../helpers/raffle';
// import { useUserState } from '../../../recoil/hook/useAddState';
import {
  MenuSelected,
  Photo,
  PixMethod,
  Raffle,
  RaffleMethods,
  WhatsGroupValue
} from '../../../types/Raffle';
import UploadImage from '../UploadImage';
import * as C from './styles';

type Props = {
  visible: boolean;
  userFirebase: User | null;
  setSelected: React.Dispatch<React.SetStateAction<MenuSelected>>;
  updateRaffles: () => Promise<void>;
  // host: string | null;
};

const raffleId = uuidv4();

function Create (props: Props) {

  const { state } = useInfoContext();
  const user = state;

  // (TODO) conferir este trecho de código
  const baseUrl = window.location.origin;

  const { visible, userFirebase, setSelected, updateRaffles } = props;

  const [nameManager, setNameManager] = useState<string>('');
  const [emailManager, setEmailManager] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [valueField, setValueField] = useState<number>();
  const [totalValues, setTotalValues] = useState<number>(0);
  const [numberOfTickets, setNumberOfTickets] = useState<number>();
  const [raffleMethod, setRaffleMethod] = useState<string>(
    RaffleMethods.FEDERAL_LOTTERY
  );
  const [phone, setPhone] = useState<string>('');
  const [date, setDate] = useState<string>('');
  const [hour, setHour] = useState<string>('');
  const [showDate, setShowDate] = useState<boolean>(false);
  const [pixMethod, setPixMethod] = useState<string>('');
  const [pix, setPix] = useState<string>('');
  const [pixName, setPixName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [arrayImages, setArrayImages] = useState<Photo[]>([]);
  const [emojiOpen, setEmojiOpen] = useState<boolean>(false);
  const [uploadRaffle, setUploadRaffle] = useState<boolean>(false);
  const [whatsGroup, setWhatsGroup] = useState<string>(WhatsGroupValue.NO);
  const [positionCursorTextArea, setPositionCursorTextArea] =
    useState<number>(0);
  const [urlWhatsappGroup, setUrlWhatsappGroup] = useState<string>('');

  useEffect(() => {
    if (valueField && numberOfTickets) {
      setTotalValues(valueField * numberOfTickets);
    } else {
      setTotalValues(0);
    }
  }, [numberOfTickets, valueField]);

  useEffect(() => {
    if (user) {
      setNameManager(user.infoUser?.displayName as string);
      setEmailManager(user.infoUser?.email as string);
    }
  }, [user]);

  const handleDate = (date: string): void => {
    const formatDate = date.split('-').reverse().join('-');
    setDate(formatDate);
  };

  const clearFileds = () => {
    setName('');
    setValueField(0);
    setNumberOfTickets(0);
    setRaffleMethod(RaffleMethods.FEDERAL_LOTTERY);
    setPhone('');
    setDate('');
    setHour('');
    setShowDate(false);
    setPixMethod('');
    setPix('');
    setPixName('');
    setDescription('');
    setArrayImages([]);
    setEmojiOpen(false);
    setUploadRaffle(false);
    setWhatsGroup(WhatsGroupValue.NO);
    setUrlWhatsappGroup('');
  };

  const handleSaveRaffle = async () => {
    const formattedPhone = phone.replace(/\D/g, '');
    const raffleDoc = getDocFirebase(raffleId);

    const incorrectFields = validateFields(
      nameManager,
      emailManager,
      name,
      numberOfTickets,
      valueField,
      formattedPhone,
      date,
      pixMethod,
      pix,
      pixName,
      description
    );

    if (whatsGroup === WhatsGroupValue.YES && !urlWhatsappGroup) {
      alert('Você precisa informar o link do grupo do whatsapp');
      return;
    }

    if (incorrectFields) {
      alert(incorrectFields);
      return;
    }

    const newRaffle: Raffle = {
      id: raffleId,
      userId: user.infoUser?.uid as string,
      userName: nameManager,
      userEmail: emailManager,
      userPhone: formattedPhone,
      creationDate: new Date(),
      name,
      description,
      active: true,
      paid: false,
      price: valueField as number,
      quantityTickets: numberOfTickets as number,
      images: arrayImages.length === 0 ? [{ url: '', name: '' }] : arrayImages,
      pixMethod,
      pix,
      pixName,
      method: raffleMethod,
      date: date as string,
      hour,
      winner: '',
      url: `${baseUrl}/rifa/${user.infoUser?.email}/${raffleDoc}`,
      publicationFee: calculateRate(totalValues),
      whatsGroup: whatsGroup === WhatsGroupValue.YES,
      urlWhatsappGroup,
      dateFinish: '',
      tickets: []
    };

    try {
      setUploadRaffle(true);
      const token = (await userFirebase?.getIdToken()) as string;
      const email = user.infoUser?.email as string;

      await createRaffleDatabase(
        email,
        token,
        newRaffle,
        numberOfTickets as number,
        raffleDoc
      );

      clearFileds();
      updateRaffles();
      setSelected(MenuSelected.RAFFLES);
    } catch (error) {
      alert('Problemas ao salvar a rifa \n Tenter novamente mais tarde');
      clearFileds();
      updateRaffles();
      setSelected(MenuSelected.RAFFLES);
    }
  };

  const handleEmojiClick = (emojiData: EmojiClickData) => {
    const text1 = description.slice(0, positionCursorTextArea);
    const text2 = description.slice(positionCursorTextArea);
    setDescription(text1 + emojiData.emoji + text2);
  };

  const handleTextArea = (text: string, positionCursor: number) => {
    setDescription(text);
    setPositionCursorTextArea(positionCursor);
  };

  return (
    <C.Container visible={visible}>
      <C.Title>Criar rifa</C.Title>
      <C.ContainerForm>
        <C.ContainerFormInput>
          <C.Label>Nome do administrador da rifa</C.Label>
          <C.Input
            value={nameManager}
            type="text"
            maxLength={50}
            placeholder={'Nome do ADM da rifa'}
            onChange={(e) => setNameManager(e.target.value)}
          />
        </C.ContainerFormInput>
        {/* <C.ContainerFormInput>
          <C.Label>Email do administrador</C.Label>
          <C.Input
            value={emailManager}
            type="text"
            maxLength={50}
            placeholder={"Email do ADM da rifa"}
            onChange={(e) => setEmailManager(e.target.value)}
          />
        </C.ContainerFormInput> */}
        <C.ContainerFormInput>
          <C.Label>Nome da rifa</C.Label>
          <C.Input
            value={name}
            type="text"
            maxLength={50}
            placeholder={'Nome da sua rifa'}
            onChange={(e) => setName(e.target.value)}
          />
        </C.ContainerFormInput>
        <C.ContainerFormInput>
          <C.Label>Quantidade de Bilhetes</C.Label>
          <C.Input
            placeholder="Quantos bilhetes quer gerar?"
            onWheel={(e) => e.currentTarget.blur()}
            type="number"
            min={0}
            max={100000}
            value={numberOfTickets?.toFixed(0)}
            onChange={(e) => {
              setNumberOfTickets(Math.round(Number(e.target.value)));
            }}
          />
        </C.ContainerFormInput>
        <C.ContainerFormInput>
          <C.Label>Valor de cada Bilhete</C.Label>
          <C.Input
            onWheel={(e) => e.currentTarget.blur()}
            placeholder="Quanto custa cada bilhete?"
            type="number"
            value={valueField}
            onChange={(e) => {
              setValueField(Number(e.target.value));
            }}
          />
          <C.ContainerInfo>
            {totalValues > 0 && (
              <C.Info>
                Valor da taxa:{' '}
                <span>{formatPrice(calculateRate(totalValues))}</span>
              </C.Info>
            )}
            {totalValues > 0 && (
              <C.Info>
                Valor arrecadado: <span>{formatPrice(totalValues)}</span>
              </C.Info>
            )}
          </C.ContainerInfo>
        </C.ContainerFormInput>
        <C.ContainerFormInput>
          <C.Label>Onde será o sorteio?</C.Label>
          <C.Select
            value={raffleMethod}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setRaffleMethod(e.target.value);
            }}
          >
            {raffleMethods.map((method) => (
              <C.Option value={method} key={method}>
                {method}
              </C.Option>
            ))}
          </C.Select>
        </C.ContainerFormInput>
        <C.ContainerFormInput>
          <C.Label>Telefone de suporte da rifa</C.Label>
          <C.ContainerInputMask>
            <InputMask
              mask="(99) 99999-9999"
              placeholder="Telefone com DDD"
              value={phone}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setPhone(e.target.value);
              }}
            />
          </C.ContainerInputMask>
        </C.ContainerFormInput>
        <C.ContainerFormInput>
          <C.Label>
            Pretende criar um Grupo no WhatsAapp para participantes?
          </C.Label>
          <C.Select
            value={whatsGroup}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setWhatsGroup(e.target.value);
            }}
          >
            {' '}
            <C.Option value={WhatsGroupValue.NO} key={WhatsGroupValue.NO}>
              NÃO
            </C.Option>
            <C.Option value={WhatsGroupValue.YES} key={WhatsGroupValue.YES}>
              SIM
            </C.Option>
          </C.Select>
        </C.ContainerFormInput>
        {whatsGroup === WhatsGroupValue.YES && (
          <C.ContainerFormInput>
            <C.Label>Cole aqui o link do grupo da rifa</C.Label>
            <C.Text>
              * <span>Dica</span>: Crie um grupo, após criado abra o grupo
              clique em cima do <span>nome do grupo</span> {'>'} clique{' '}
              <span>Convidar via link</span> {'>'} clique{' '}
              <span>Copiar link</span> e cole aqui.
              <br />
              Respeite os caracteres maiúsculos e minúsculos.
            </C.Text>
            <C.Text>
              <span>Exemplo:</span>{' '}
              https://chat.whatsapp.com/BehTTeozJA3CwGbQuj7Krs
            </C.Text>
            <C.Input
              value={urlWhatsappGroup}
              type="text"
              maxLength={60}
              placeholder={'Link do grupo da rifa'}
              onChange={(e) => setUrlWhatsappGroup(e.target.value)}
            />
            <C.Gift src="/images/group.gif"></C.Gift>
          </C.ContainerFormInput>
        )}
        <C.ContainerFormInput>
          <C.Label>Data prevista do sorteio</C.Label>
          <C.Text>* Escolha apenas uma opção.</C.Text>
          <C.ContainerCheckboxDate>
            <C.InputCheckbox
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  setDate('Ao encerrar os bilhetes');
                } else {
                  setDate('Informar data');
                }
              }}
            />
            <C.LabelCheckbox>Ao encerrar os bilhetes</C.LabelCheckbox>
            <C.Text>ou</C.Text>
            <C.ButtonDate onClick={() => setShowDate(!showDate)}>
              Informar data
            </C.ButtonDate>
          </C.ContainerCheckboxDate>
          {showDate && (
            <C.Input type="date" onChange={(e) => handleDate(e.target.value)} />
          )}
          <C.Text>
            Sorteio: <span>{date}</span>
          </C.Text>
        </C.ContainerFormInput>
        <C.ContainerFormInput>
          <C.Label>Hora do sorteio? *opcional</C.Label>
          <C.Select
            value={hour}
            onChange={(e) => {
              setHour(e.target.value);
            }}
          >
            <C.Option></C.Option>
            {hours.map((hour) => (
              <C.Option value={hour} key={hour}>
                {hour}
              </C.Option>
            ))}
          </C.Select>
        </C.ContainerFormInput>
        <C.ContainerPayment>
          <C.TitlePayment>Dados do seu PIX</C.TitlePayment>
          <C.ContainerFormInput>
            <C.Label>Tipo do PIX</C.Label>
            <C.Select
              value={pixMethod}
              onChange={(e) => {
                setPixMethod(e.target.value);
              }}
            >
              <C.Option></C.Option>
              {pixMethods.map((method) => (
                <C.Option value={method} key={method}>
                  {method}
                </C.Option>
              ))}
            </C.Select>
          </C.ContainerFormInput>
          {pixMethod === PixMethod.CPF_CNPJ || pixMethod === PixMethod.PHONE
            ? (
            <C.ContainerFormInput>
              <C.Label>PIX</C.Label>
              <C.Input
                value={pix}
                type="number"
                placeholder={'Digite somente os números'}
                onChange={(e) => setPix(e.target.value)}
              />
              <C.TextWarning>
                Não Digite: <span>( ) / - . , </span>
              </C.TextWarning>
            </C.ContainerFormInput>
              )
            : (
            <C.ContainerFormInput>
              <C.Label>PIX</C.Label>
              <C.Input
                value={pix}
                type="text"
                maxLength={50}
                placeholder={'Seu PIX'}
                onChange={(e) => setPix(e.target.value)}
              />
            </C.ContainerFormInput>
              )}
          <C.ContainerFormInput>
            <C.Label>Nome da conta PIX</C.Label>
            <C.Input
              value={pixName}
              type="text"
              maxLength={50}
              placeholder={'Nome que aparecerá no PIX'}
              onChange={(e) => setPixName(e.target.value)}
            />
          </C.ContainerFormInput>
        </C.ContainerPayment>
        <C.ContainerFormInput>
          <C.Label>Descrição da rifa</C.Label>
          <C.InputTextArea
            rows={12}
            placeholder="Descreva a sua rifa, o que será sorteado, como será o sorteio, regras ,etc... , tudo que o comprador precisa saber, sinta-se a vontade para usar os emoji abaixo."
            value={description}
            onChange={(e) =>
              handleTextArea(e.target.value, e.currentTarget.selectionStart)
            }
            onClick={(e) =>
              setPositionCursorTextArea(e.currentTarget.selectionStart)
            }
          />
          <C.EmojiButton onClick={() => setEmojiOpen(!emojiOpen)}>
            {!emojiOpen ? 'Abrir emoji' : 'Fechar Emoji'} 😀
          </C.EmojiButton>
          <C.EmojiArea visible={emojiOpen}>
            <EmojiPicker onEmojiClick={handleEmojiClick} searchDisabled />
          </C.EmojiArea>
        </C.ContainerFormInput>
        <UploadImage
          arrayImages={arrayImages}
          setArrayImages={setArrayImages}
          raffleId={raffleId}
        />
        {uploadRaffle && (
          <C.ContainerLoading>
            <C.BodyLoading>
              <C.Loading1>
                <BsGear />
              </C.Loading1>
              <C.LoadingText>Aguarde alguns minutos</C.LoadingText>
              <C.LoadingText>Estamos construindo a sua rifa</C.LoadingText>
              <C.LoadingText>
                Ao finalizar você será redirecionado para a rifa.
              </C.LoadingText>
            </C.BodyLoading>
          </C.ContainerLoading>
        )}
        {!uploadRaffle && (
          <C.ButtonSave onClick={handleSaveRaffle}>Criar rifa</C.ButtonSave>
        )}
      </C.ContainerForm>
    </C.Container>
  );
}

export default Create;
