import { initializeApp } from 'firebase/app';
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  sendPasswordResetEmail
} from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyCBHzuAa9P22huvtwZM6diz4hNtQBrTUXA',
  authDomain: 'raffle-de0f8.firebaseapp.com',
  projectId: 'raffle-de0f8',
  storageBucket: 'raffle-de0f8.appspot.com',
  messagingSenderId: '1006747271533',
  appId: '1:1006747271533:web:99853be279bc93acf45a64',
  measurementId: 'G-D1782Y684B'
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const createUser = createUserWithEmailAndPassword;
export const signInEmailPassword = signInWithEmailAndPassword;
export const newPassword = sendPasswordResetEmail;

// (TODO) Darlan - Envio de email de verificação não está funcionando.
export const verifyEmail = sendEmailVerification;
export const storage = getStorage(app);
