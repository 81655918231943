
import React from 'react';
import { GiFrozenArrow } from 'react-icons/gi';
import { useNavigate } from 'react-router-dom';

import * as C from './styles';

function Footer () {

  const navigate = useNavigate();
  
  return (
    <C.Container>

      <C.Advertising onClick={()=> navigate('/')}>Gostou? clique aqui e crie a sua rifa</C.Advertising>

      <C.LinkSoftware
        href="https://www.facebook.com/darlan.aguiar.165"
        target="_blank"
      >
        {' '}
        Dr.<span>Gelo</span> <GiFrozenArrow /> Software
      </C.LinkSoftware>
    </C.Container>
  );
}

export default Footer;
