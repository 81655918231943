import React from 'react';
import { BsWhatsapp } from 'react-icons/bs';
import { IoTicketOutline } from 'react-icons/io5';

import * as C from './styles';

function WhatsappContact () {
  const apiWhatsappLojaDaRifa =
    'https://api.whatsapp.com/send?phone=5567999519176&text=Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20sobre%20as%20rifas%20da%20LojadaRifa';

  return (
    <C.Container href={apiWhatsappLojaDaRifa} target="_blank">
      <BsWhatsapp />
      <C.TextContact>
        <span className='loja'>Loja</span><C.DarkGreen>da</C.DarkGreen>Rifa
        <IoTicketOutline />
      </C.TextContact>
    </C.Container>
  );
}

export default WhatsappContact;
