import React, { useState } from 'react';
import InputMask from 'react-input-mask';

import * as C from './styles';

type Props = {
  visible: boolean;
};

function Config (props: Props) {
  const { visible } = props;

  const [name, setName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');

  const handleEditInfo = () => {
    // (TODO) Darlan: implement editing information in the database
    const formattedPhone = phone.replace(/\D/g, '');

    if (formattedPhone.length < 11 || name === '') {
      alert('Digite um telefone celular válido!');
    }
  };

  return (
    <C.Container visible={visible}>
      <C.Title>Configurações</C.Title>
      <C.ContainerForm>
        <C.ContainerFormInput>
          <C.Label>Alterar meu nome</C.Label>
          <C.Input
            value={name}
            type="text"
            maxLength={50}
            placeholder={'Seu novo nome'}
            onChange={(e) => setName(e.target.value)}
          />
        </C.ContainerFormInput>
        <C.ContainerFormInput>
          <C.Label>Alterar meu telefone</C.Label>
          <C.ContainerInputMask>
            <InputMask
              mask="(99) 99999-9999"
              placeholder="Telefone com DDD"
              value={phone}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setPhone(e.target.value);
              }}
            />
          </C.ContainerInputMask>
          <C.ButtonSave onClick={handleEditInfo}>Alterar</C.ButtonSave>
        </C.ContainerFormInput>
      </C.ContainerForm>
    </C.Container>
  );
}

export default Config;
