import React from 'react';
import { IoTicketOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

import * as C from './styles';

function Header () {
  const navigate = useNavigate();
  return (
    <C.Header>
      <C.ContainerLogo>
        Loja<span className='da'>da</span><span className={'rifa'}>Rifa</span>
        <IoTicketOutline />
      </C.ContainerLogo>
      <C.ContainerMenu>
        <C.Login onClick={()=> navigate('/login')}>Login</C.Login>
        <C.Register onClick={()=> navigate('/register')}>
          Criar <span>conta</span>
        </C.Register>
      </C.ContainerMenu>
    </C.Header>
  );
}

export default Header;
